import React, { useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { useEffect } from 'react';
import api from '../../../services/api';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Responsavel from '../../../layouts/Responsavel';
import { useAuth } from '../../../context/auth';
import { FaCalendarAlt, FaFileMedicalAlt } from 'react-icons/fa';
import { } from 'react-icons/io';
import { MdOutlinePendingActions } from 'react-icons/md';
import { IoOpenOutline } from 'react-icons/io5';
import { ScaleLoader } from 'react-spinners';
import { BsSearch } from 'react-icons/bs';
import bgImage from 'assets/background-image.jpeg';
import Card from 'components/Card';
import useIcons from 'context/icons';
const override = `
    display: block;
    margin-top: 8px;
    margin-left: 4px;
    border-color: orange;
`;

export default function ResponsavelDashboard() {
    // Hooks
    const { user } = useAuth();
    const { iconArray } = useIcons();
    const navigate = useNavigate();

    // Estado
    const agendaMode = true;

    // Listas
    const [pacientes, setPacientes] = useState([]);
    const [terapeutas, setTerapeutas] = useState([]);

    // Lembretes
    const [lembretes, setLembretes] = useState('Texto de lembretes.');
    const [editLembrete, setEditLembrete] = useState(false);

    // Estilos
    const labelStyle = {
        padding: '10px 10px 10px 0px'
    };

    const contentStyle = {

        fontWeight: 'bolder'
    };


    //#region FUNCOES
    const getPacientes = (responsavel_id) => {
        try {
            api.get('api/paciente/list/by/responsavel?id=' + responsavel_id).then(({ data }) => {
                console.log(data);
                setPacientes(data);
            });
        } catch (e) {
            console.error('Erro ao obter lista de pacientes - ', e);
        }
    };

    const getTerapeutas = () => {
        let terapeutasList = [];
        pacientes.forEach((paciente) => {
            api.get(`/api/atendimento?with=terapeutum&filter[paciente_id]=${paciente.id}`).then(({ data }) => {
                data.forEach((atendimento) => {
                    const checkDouble = terapeutasList.find(terapeutum => atendimento.terapeutum.nome === terapeutum);
                    if (atendimento.paciente_id === paciente.id && checkDouble === undefined) {
                        terapeutasList.push(atendimento.terapeutum.nome);
                    };
                });
                setTerapeutas(terapeutasList);
            });
        });

    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        getPacientes(user.responsavels[0].id);
        api.get('api/user/' + user.id).then(({ data }) => {
            setLembretes(data.lembretes);
        });
    }, []);

    useEffect(() => {
        getTerapeutas();
    }, [pacientes]);
    //#endregion

    if (agendaMode) {
        return (
            <div
                className='all-center center-horizontal-vertical'
                style={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                    width: '100vw'
                }}
            >
                <div className='row'>
                    <div className='col-12 text-center'>
                        <span>Pesquisando agenda...</span>
                    </div>
                    {(pacientes && pacientes?.length > 0)
                        ? <div className='col-12 d-flex justify-content-center flex-wrap'>
                            {pacientes.map((item, index) =>
                                <div className='m-0 p-0 w-400px-i'>
                                    <div className='card col-12 d-flex flex-column p-4 mt-2'>
                                        <div className='aligin-items-center justify-content-center text-left w-100per' style={{ display: 'flex-grid' }}>
                                            <div className='m-0 mt-5 p-0'>
                                                {/* 
                                                <div className='w-100per pe-2 pb-2 d-flex justify-content-center'>
                                                    <img className='perfil-image border mx-auto' src={item.foto_perfil} alt=''></img>
                                                </div>
                                                 */}
                                                <div className='w-100per d-flex align-items-center justify-content-center'>
                                                    <div className='d-flex align-content-center justify-content-center' style={labelStyle}>
                                                        <span style={contentStyle} className='text-center'>
                                                            {item.identificador} - {item.nome}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='w-100per d-flex flex-col'>
                                                    {/* 
                                                    <button className='btn-padrao mt-2' onClick={() => navigate('/dashboard/responsavel/pacientes/perfil/' + item.id)}>
                                                        Acessar Cadastro <IoOpenOutline className='ms-2' size={22} />
                                                    </button>
                                                     */}
                                                    <button className='btn-padrao mt-2' onClick={() => navigate('/dashboard/responsavel/agenda/paciente/' + item.id)}>
                                                        Acessar Agenda Semanal <FaCalendarAlt className='ms-2' size={22} />
                                                    </button>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapseThree' + index} aria-expanded="false" aria-controls={'collapseThree' + index}>
                                                            Terapias:
                                                        </button>
                                                    </h2>
                                                    <div id={'collapseThree' + index} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <ul key={'lista-de-terapias'}>
                                                                {item.terapia_paciente.map((terapia, index) => {
                                                                    return <li key={index}>{terapia.terapia} - {terapia.especialidade} - {terapia.plano}</li>;
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {terapeutas.length > 0 &&
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapseTwo' + index} aria-expanded="false" aria-controls={'collapseTwo' + index}>
                                                                Terapeuta(s)
                                                            </button>
                                                        </h2>
                                                        <div id={'collapseTwo' + index} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <ul key={'lista-de-terapeutas'}>
                                                                    {terapeutas.map((e, index) => {
                                                                        return <li key={index}>{e}</li>;
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        : <div className='col-12 text-center'>
                            <div className='d-flex flex-row w-150px text-center align-items-center justify-content-center'>
                                <ScaleLoader css={override} size={150} color={'#fd931a'} />
                                <BsSearch className='icone manual-searching' size={30} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    } else {
        return (
            <Responsavel>
                <div className='container'>
                    <div className='col-12 d-grid text-center'>
                        <label className='font-roxo font-16px font-w-700'>Bem vindo</label>
                        <label className='font-roxo font-18px font-w-700'>{user.responsavels[0].nome}</label>
                        <label className='font-roxo font-16px font-w-700'>Hoje é dia {moment.utc().format('DD, MMMM YYYY')}</label>
                    </div>
                    <br></br>
                    <div className='row flex-sm-col-rev justify-content-center'>
                        <div className="col-sm-12 col-lg-4">
                            <hr className='w-100'></hr>
                            <h5 className='text-center font-w-700 font-20px font-roxo'>
                                Pacientes
                            </h5>
                            {pacientes.map((item, index) => {
                                return (
                                    <div className='m-0 p-0'>
                                        <div className='card col-12 d-flex flex-column p-4 mt-2'>
                                            <div className='aligin-items-center justify-content-center text-left w-100per' style={{ display: 'flex-grid' }}>
                                                <div className='m-0 p-0'>
                                                    <div className='w-100per pe-2 pb-2 d-flex justify-content-center'>
                                                        <img className='perfil-image border mx-auto' src={item.foto_perfil} alt=''></img>
                                                    </div>
                                                    <div className='w-100per d-flex align-items-center justify-content-center'>
                                                        <div className='d-flex align-content-center justify-content-center' style={labelStyle}>
                                                            <span style={contentStyle} className='text-center'>
                                                                {item.identificador} - {item.nome}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='w-100per d-flex flex-col'>
                                                        <button className='btn-padrao mt-2' onClick={() => navigate('/dashboard/responsavel/pacientes/perfil/' + item.id)}>
                                                            Acessar Cadastro <IoOpenOutline className='ms-2' size={22} />
                                                        </button>
                                                        <button className='btn-padrao mt-2' onClick={() => navigate('/dashboard/responsavel/agenda/paciente/' + item.id)}>
                                                            Agenda Semanal <FaCalendarAlt className='ms-2' size={22} />
                                                        </button>
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="accordion" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapseThree' + index} aria-expanded="false" aria-controls={'collapseThree' + index}>
                                                                Terapias:
                                                            </button>
                                                        </h2>
                                                        <div id={'collapseThree' + index} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <ul key={'lista-de-terapias'}>
                                                                    {item.terapia_paciente.map((terapia, index) => {
                                                                        return <li key={index}>{terapia.terapia} - {terapia.especialidade} - {terapia.plano}</li>;
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {terapeutas.length > 0 &&
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapseTwo' + index} aria-expanded="false" aria-controls={'collapseTwo' + index}>
                                                                    Terapeuta(s)
                                                                </button>
                                                            </h2>
                                                            <div id={'collapseTwo' + index} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    <ul key={'lista-de-terapeutas'}>
                                                                        {terapeutas.map((e, index) => {
                                                                            return <li key={index}>{e}</li>;
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {false === true &&
                            <>
                                <div className='col-sm-12 col-lg-2 mt-4'></div>
                                <div className='col-sm-12 col-lg-6 d-flex flex-wrap justify-content-lg-end justify-content-sm-center'>
                                    <div className='card p-3 h-fit-content-i'>
                                        <div className='mb-1 mt-2 text-center'>
                                            <span className='font-w-900 font-14px font-roxo'>Acesso Rápido:</span>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <button onClick={() => navigate('/dashboard/responsavel/documentos-pendentes/usuario/' + user.responsavels[0].id)} className={'btn-acesso-rapido'}>
                                                <MdOutlinePendingActions size={30} />
                                                <span>Documentos Pendentes</span>
                                            </button>
                                            <button onClick={() => navigate('/dashboard/responsavel/documentos-terapeuticos')} className={'btn-acesso-rapido'}>
                                                <FaFileMedicalAlt size={30} />
                                                <span>Documentos Terapêuticos</span>
                                            </button>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <button onClick={() => navigate('/dashboard/responsavel/agenda/paciente/')} className={'btn-acesso-rapido'}>
                                                <FaCalendarAlt size={30} />
                                                <span> Agenda </span>
                                            </button>
                                            <button onClick={() => navigate('/dashboard/responsavel/documentos-assinados')} className={'btn-acesso-rapido'}>
                                                <AiOutlineClockCircle size={30} />
                                                <span>Histórico de Documentos</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>}
                    </div>
                </div>
            </Responsavel>
        );
    }
}
