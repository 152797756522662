import React from 'react';
import { CgClose } from 'react-icons/cg';
import { Button, ButtonFinalizar, Content, Controls, Highlight, Overlay } from './styles';
import Topicos from './topicos';
import { useEffect, useRef, useState } from 'react';

//#region TUTORIAL
const Tutorial = ({ steps, onFinish, hasTopicos }) => {
    //#region VARIAVEIS
    const [currentStep, setCurrentStep] = useState(0);
    const [highlightPosition, setHighlightPosition] = useState({});
    const [windowWidth, setWindowWidth] = useState(null);
    const [windowHeight, setWindowHeight] = useState(null);
    const [currentTop, setCurrentTop] = useState('50%');
    const [currentLeft, setCurrentLeft] = useState('50%');
    const contentRef = useRef(null);
    const topicosRef = useRef(null);
    const tutorialRef = useRef(null);
    //#endregion

    //#region FUNCOES
    const criaPosicionamento = () => {
        const x = !steps[currentStep].selector
            ? windowHeight * 0.5
            : highlightPosition.top + highlightPosition.height + 8 + contentRef.current.offsetHeight > windowHeight
                ? windowHeight - highlightPosition.height - contentRef.current.offsetHeight - 30
                : highlightPosition.top + highlightPosition.height + 8;
        const y = !steps[currentStep].selector
            ? windowWidth * 0.5
            : highlightPosition.left + highlightPosition.width + (steps[currentStep]?.width || 350) > windowWidth
                ? windowWidth - (steps[currentStep]?.width || 350)
                : highlightPosition.left;
        setCurrentTop(x);
        setCurrentLeft(y);
        if (topicosRef?.current) {
            const topicosRect = topicosRef.current.getBoundingClientRect();

            const distanceThreshold = 50;
            const distanceX = Math.abs(x - topicosRect.left);
            const distanceY = Math.abs(y - topicosRect.top);

            if (distanceX < distanceThreshold && distanceY < distanceThreshold) {
                const corners = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];
                const randomCorner = corners[Math.floor(Math.random() * corners.length)];
                topicosRef.current.moveTo(randomCorner);
            }
        }
    };
    //#endregion

    //#region HANDLES
    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleFinish = () => {
        onFinish();
        setCurrentStep(0);
    };
    //#endregion

    //#region USE EFFECT
    useEffect(() => {
        const previousStyles = new Map();

        try {
            if (steps[currentStep]) {
                const selectors = Array.isArray(steps[currentStep].selector) ? steps[currentStep].selector : [steps[currentStep].selector];
                if (steps[currentStep]?.jumpOffSelector === undefined && steps[currentStep]?.jumpOffSelector === true) {
                    if (selectors) {
                        setCurrentStep(prev => prev + 1);
                    }
                }
                const mainElement = selectors.map(selector => {
                    if (selector.length > 1) {
                        if (document.querySelector(selector)) {
                            return document.querySelector(selector);
                        }
                    }
                }).find(el => el !== null);

                const sharedStyles = {
                    /*
                    position: 'relative',
                    zIndex: 1001,
                    */
                };

                selectors.forEach(selector => {
                    const element = document.querySelector(selector);
                    if (element) {
                        previousStyles.set(element, {
                            position: element.style.position,
                            zIndex: element.style.zIndex,
                        });
                        Object.assign(element.style, sharedStyles);
                    }
                });

                if (mainElement) {
                    const rect = mainElement.getBoundingClientRect();
                    if (Array.isArray(steps[currentStep].selector)) {
                        setHighlightPosition(selectors.map(selector => {
                            const element = document.querySelector(selector);
                            if (element) {
                                const rect = element.getBoundingClientRect();
                                return {
                                    top: rect.top + window.scrollY - 5,
                                    left: rect.left + window.scrollX - 5,
                                    width: rect.width + 10,
                                    height: rect.height + 10,
                                };
                            }
                        }));
                    } else {
                        setHighlightPosition({
                            top: rect.top + window.scrollY - 5,
                            left: rect.left + window.scrollX - 5,
                            width: rect.width + 10,
                            height: rect.height + 10,
                        });
                    }
                }
            }
        } catch (error) {
            console.error('Error in useEffect:', error);
        }

        return () => {
            if (steps[currentStep]) {
                const selectors = Array.isArray(steps[currentStep].selector) ? steps[currentStep].selector : [steps[currentStep].selector];
                selectors.forEach(selector => {
                    const element = document.querySelector(selector);
                    if (element && previousStyles.has(element)) {
                        const { position, zIndex } = previousStyles.get(element);
                        element.style.position = position;
                        element.style.zIndex = zIndex;
                    }
                });
            }
        };
    }, [currentStep, steps]);

    useEffect(() => {
        if (windowWidth && windowHeight) {
            criaPosicionamento();
        }
    }, [highlightPosition, windowWidth, windowHeight, currentStep]);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            setWindowWidth(screenWidth);
            setWindowHeight(screenHeight);
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    //#endregion

    //#region HTML
    return (
        <Overlay ref={tutorialRef}>
            {steps[currentStep].selector ? (
                typeof steps[currentStep].selector === 'string' ? (
                    <Highlight
                        style={{
                            top: `${highlightPosition.top}px`,
                            left: `${highlightPosition.left}px`,
                            width: `${highlightPosition.width}px`,
                            height: `${highlightPosition.height}px`,
                        }}
                    />
                ) : (
                    steps[currentStep].selector.map((selector, index) => {
                        return (
                            <Highlight
                                key={index}
                                style={{
                                    top: `${highlightPosition[index]?.top}px`,
                                    left: `${highlightPosition[index]?.left}px`,
                                    width: `${highlightPosition[index]?.width}px`,
                                    height: `${highlightPosition[index]?.height}px`,
                                }}
                            />
                        );
                    })
                )
            ) : null}

            <Content
                ref={contentRef}
                style={{
                    width: steps[currentStep]?.width || 350,
                    height: steps[currentStep]?.height || 'auto',
                    transition: 'all .5s ease-in-out',
                    position: 'absolute',
                    top: `${currentTop}px`,
                    left: `${currentLeft}px`,
                    ...!steps[currentStep].selector && { transform: 'translate(-50%, -50%)' },
                }}
            >
                <button
                    style={{
                        position: 'absolute',
                        top: '3px',
                        right: '10px',
                        background: 'transparent',
                        border: 'none',
                        fontSize: '20px',
                        cursor: 'pointer',
                        color: 'red',
                    }}
                    onClick={handleFinish}
                >
                    <CgClose color='red' />
                </button>
                <p className='m-auto mt-0'>{steps.length > 1 ? `Passo ${currentStep + 1} de ${steps.length}` : ''}</p>
                <p className='mt-2'>{steps[currentStep].text}</p>

                <Controls>
                    <Button className='unselectable' onClick={handleBack} disabled={currentStep === 0}>
                        Voltar
                    </Button>

                    {currentStep === steps.length - 1 ? (
                        <ButtonFinalizar className='unselectable' onClick={handleFinish}>
                            Finalizar
                        </ButtonFinalizar>
                    ) : (
                        <Button className='unselectable' onClick={handleNext}>
                            Próximo
                        </Button>
                    )}
                </Controls>
            </Content>
            {hasTopicos &&
                <Topicos ref={topicosRef} steps={steps} toggleSelect={setCurrentStep} selectedIndex={currentStep} />}
        </Overlay>
    );
    //#endregion
};
//#endregion
export default Tutorial;