import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const GraficoEmBarra = ({ data, options, width = '600px', height = '400px' }) => {
    return (
        <div style={{ width, height }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default GraficoEmBarra;