import Button from 'components/Button';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import useIcons from 'context/icons';
import { usePageBase } from 'context/page-base';
import { useSnackbar } from 'context/snackbar';
import useTipoColuna from 'context/tipo-coluna';
import PerfilLinha from 'pages/Tarefas/modais/PerfilLinhaModal';
import React, { useState, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useParams } from 'react-router-dom';
import api from 'services/api';

const TarefasTabela = () => {
    //#region VARIAVEIS
    // HOOKS
    const pageBase = usePageBase({
        title: 'Visualização de Tabela',
        description: 'Visualize os dados da tabela.',
        routes: [
            'dashboard/desenvolvedor/tarefas',
        ],
    });
    const { iconArray } = useIcons();
    const { tabelaId } = useParams();
    const snackbar = useSnackbar();
    const { processaTipoColuna } = useTipoColuna();

    // DADOS DA TABELA
    const [tabela, setTabela] = useState({});
    const [loading, setLoading] = useState(true);
    const [conteudo, setConteudo] = useState([]);
    const [columns, setColumns] = useState([]);
    const [linhaId, setLinhaId] = useState(null);
    //#endregion
    //#region VARIAVEIS
    const fetchTabela = async () => {
        try {
            const tabelaResponse = await api.get(`/api/tabelas/${tabelaId}?with=colunas`);
            setTabela(tabelaResponse.data);
            const conteudoResponse = await api.get(`/api/linhas?filter[tabela_id]=${tabelaId}&with=conteudo_linhas,conteudo_linhas.coluna`);
            setConteudo(conteudoResponse.data);
        } catch (error) {
            console.error('Erro ao buscar tabela:', error);
        } finally {
            setLoading(false);
        }
    };
    //#endregion
    //#region HANDLES
    const handleEditaConteudo = (idValue = '') => {
        if (typeof idValue === 'string') {
            const id = idValue.split(':')[1];
            const conteudo = document.getElementById(idValue).value;
            api.patch(`/api/conteudo-linhas/${id}?id=${id}`, { id: id, conteudo: conteudo })
                .then(() => {
                    snackbar.displayMessage('Conteúdo atualizado com sucesso!', 'success');
                })
                .catch(error => {
                    console.error('Erro ao atualizar conteúdo:', error);
                    snackbar.displayMessage('Erro ao atualizar conteúdo.', 'error');
                });
        }else{
            api.post(`/api/conteudo-linhas`, idValue)
                .then(() => {
                    snackbar.displayMessage('Conteúdo criado com sucesso!', 'success');
                })
                .catch(error => {
                    console.error('Erro ao criar conteúdo:', error);
                    snackbar.displayMessage('Erro ao criar conteúdo.', 'error');
                });
        }
    }
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        fetchTabela();
    }, [tabelaId]);
    useEffect(() => {
        unstable_batchedUpdates(() => {
            if (tabela.colunas) {
                const columns = tabela.colunas.map(coluna => {
                    return ({
                        Header: coluna.nome,
                        accessor: `conteudo_linhas.${coluna.id - 1}.conteudo`,
                        Cell: (cell) => {
                            const conteudo = cell.row.original.conteudo_linhas.find(conteudo => conteudo.coluna_id === coluna.id);
                            const conteudoId = conteudo?.id;
                            const linhaId = cell.row.original.id;
                            const colunaId = cell.row.original.conteudo_linhas.coluna_id;
                            const tipo = processaTipoColuna(coluna.tipo_nome);
                            const value = cell.value;
                            const criaEditaObj = conteudoId ? conteudoId : {
                                linha_id: linhaId,
                                coluna_id: colunaId,
                                conteudo: value,
                            };
                            return (
                                <div className="">
                                    <div className={`input-group mb-3`}>
                                        <input id={`c:${conteudoId}`} type={tipo ? tipo : "text"} className="form-control" aria-label="Conteudo" aria-describedby={`addon:${conteudoId}`} defaultValue={value} />
                                        <span className="input-group-text" id={`addon:${conteudoId}`} onClick={() => handleEditaConteudo(criaEditaObj)}>{iconArray['salvar']}</span>
                                    </div>
                                </div>
                            )
                        },
                    })
                });
                columns.push({
                    Header: 'Ações',
                    accessor: 'id',
                    Cell: ({ value }) => (
                        <div className="d-flex justify-content-center">
                            <Button type="button" onClick={() => setLinhaId(value)} >
                                {iconArray['editar']}
                            </Button>
                        </div>
                    ),
                });
                setColumns(columns);
            }
        });
    }, [tabela]);
    //#endregion
    //#region HTML
    return (
        <div className='px-2'>
            <TableDefaultPagination source={conteudo} columns={columns} tableTitle={tabela.nome} loading={loading} searchBar={true} createButton={
                <PerfilLinha linhaId={linhaId} tabela={tabela} setLinhaId={setLinhaId} />
            } />
        </div>
    );
    //#endregion
};

export default TarefasTabela;