import React, { useCallback } from 'react';

const useRealce = () => {
    /**
     * Manipula a entrada e saída do mouse sobre uma célula para destacar ou remover o destaque.
     *
     * @param {string|string[]} filterId - O ID do filtro ou uma lista de IDs de filtros.
     * @param {boolean} [isEnter=true] - Indica se é um evento de entrada (true) ou saída (false) do mouse.
     */
    const handleRealcaMouseEnterLeaveCell = useCallback((filterId, isEnter = true) => {
        try {
            const filterIds = Array.isArray(filterId) ? filterId : [filterId];
            filterIds.forEach(id => {
                const filterElement = document.getElementById(`filter_${id}`);
                if (filterElement) {
                    if (isEnter) {
                        const rect = filterElement.getBoundingClientRect();
                        const highlightElement = document.createElement('div');
                        highlightElement.id = `highlight_${id}`;
                        highlightElement.style.position = 'absolute';
                        highlightElement.style.border = '6px solid #ffc500';
                        highlightElement.style.borderRadius = '0.5rem';
                        highlightElement.style.backgroundColor = 'rgba(255, 245, 209, 0.2)';
                        highlightElement.style.pointerEvents = 'none';
                        highlightElement.style.zIndex = 1000;
                        highlightElement.style.top = `${rect.top + window.scrollY}px`;
                        highlightElement.style.left = `${rect.left + window.scrollX}px`;
                        highlightElement.style.width = `${rect.width}px`;
                        highlightElement.style.height = `${rect.height + 15}px`;
                        document.body.appendChild(highlightElement);
                    } else {
                        const highlightElement = document.getElementById(`highlight_${id}`);
                        if (highlightElement) {
                            document.body.removeChild(highlightElement);
                        }
                    }
                }
            });
        } catch (error) {
            console.error(error);
        }
    }, []);

    return { handleRealcaMouseEnterLeaveCell };
};

export default useRealce;