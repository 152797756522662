//#region IMPORTAÇÕES
import moment from 'moment';
import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './styles/global';

import InactivityTimeout from 'components/InactivityTimeout/InactivityTimeout';
import Snackbar from 'components/Snackbar';
import AppProvider from 'context';
import { useAuth } from 'context/auth';
import { useSnackbar } from 'context/snackbar';
import RoutesApp from 'Routes';
//#endregion

/**
 * Componente principal da aplicação.
 * 
 * Este componente configura o roteamento da aplicação, gerencia o estado global e define estilos globais.
 * Também lida com o tempo limite de inatividade do usuário e o evento de descarregamento da página.
 * 
 * @component
 * @example
 * return (
 *   <App />
 * )
 */
const App = () => {
	//#region VARIAVEIS
	const snackbar = useSnackbar();
	const { signOut, isAuthenticated } = useAuth();
	//#endregion

	//#region FUNCOES
	/**
	 * Função para realizar o logout do usuário.
	 * Remove os itens de sessão relacionados ao usuário e redireciona para a página inicial.
	 */
	const logOut = () => {
		sessionStorage.removeItem('@Neuro:user');
		sessionStorage.removeItem('@Neuro:access_token');
		sessionStorage.removeItem('@Neuro:docs_pending');
		sessionStorage.removeItem('@Neuro:refresh_token');
		window.location.href = '/';
	};
	//#endregion

	//#region HANDLES
	/**
	 * Função assíncrona que lida com o tempo limite da sessão do usuário.
	 * Chama a função logOut para desconectar o usuário.
	 */
	const handleTimeout = async () => {
		logOut();
	};
	//#endregion

	//#region USE EFFECTS
	useEffect(() => {
		moment().utc();

		const handleBeforeUnload = () => {
			if (isAuthenticated()) {
				logOut();
			}
		};

		const isIPhone = /iPhone/i.test(navigator.userAgent);
		if (!isIPhone) {
			window.addEventListener('beforeunload', handleBeforeUnload);
		}

		return () => {
			if (!isIPhone) {
				window.removeEventListener('beforeunload', handleBeforeUnload);
			}
		};
	}, [isAuthenticated, signOut]);
	//#endregion

	//#region HTML
	return (
		<Router>
			<InactivityTimeout timeoutDuration={1200000} onTimeout={handleTimeout} />
			<AppProvider>
				<RoutesApp />
				<GlobalStyle />
				{snackbar.isDisplayed && <Snackbar duration={snackbar.duration} />}
			</AppProvider>
		</Router>
	);
	//#endregion
};

export default App;