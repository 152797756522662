import Dropdown from 'components/Dropdown';
import moment from 'moment';
import { useCallback } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { ClipLoader } from 'react-spinners';
import { ChangeCase } from 'services/ChangeCase';

const AgendaTerapeutaTbody = ({
    // Estilos
    styles,
    styles_filtred,
    styles_escuro,
    filtered,
    // Data
    diaAtual,
    // Listagens
    horarios,
    terapeutas,
    newAgendamentos,
    agendamentos,
    // Estados
    loading,
    esperaMode,
    // Funcoes
    syncScroll,
    getTooltipClass,
    getLegendaAgendamento,
    // Setters
    setPacienteSelecionadoOpt,
    //Refs
    agendamentoTableBodyRef,
    horaTableBodyRef,
    terapeutaTableHeadRef,
    modalDetalhesAgendamento,
    // Usuario
    usuario,
}) => {
    const geraDropDownAgenda = useCallback((dropdownAgenda, tipo) => {
        return dropdownAgenda.map((a) => {
            //VARS\\
            const legenda = getLegendaAgendamento(a, agendamentos);
            let horaFinalDiferente;
            let horaFinal;
            if (a.data_atendimento_final) {
                if (a.data_atendimento_final.includes('T')) {
                    horaFinalDiferente = String(a.data_atendimento_final).split('T')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                    horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split('T')[1].split('.')[0].split(':') : null;
                } else {
                    horaFinalDiferente = String(a.data_atendimento_final).split(' ')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                    horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split(' ')[1].split('.')[0].split(':') : null;
                }
            }
            horaFinal = horaFinal ? horaFinal[0] + ':' + horaFinal[1] : '00:00';
            const divStyleDropdown = {
                cursor: 'default',
                ...styles.trReserva
            };

            if (a.filtrado === undefined) {
                divStyleDropdown = {
                    ...divStyleDropdown,
                    backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                    ...styles[legenda],
                };
            } else if (a.filtrado === true) {
                divStyleDropdown = {
                    ...divStyleDropdown,
                    backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                    ...styles_filtred[legenda],
                    ...filtered,
                };
            } else if (a.filtrado === false) {
                divStyleDropdown = {
                    ...divStyleDropdown,
                    backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                    ...styles_escuro[legenda],
                };
            }

            // Ajustes específicos para reserva e substituição
            if (tipo === 'substituicao') {
                divStyleDropdown = {
                    ...divStyleDropdown,
                    backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                };
            }

            return (
                <div style={styles.agendamentoExtras} key={a.id}>
                    <Dropdown
                        button={
                            <svg
                                className='p-0 m-0'
                                style={{
                                    ...styles.extraIcon,
                                    ...(tipo === 'reserva'
                                        ? styles.extraIcon.reserva
                                        : (a.tipo_atendimento_id === 7)
                                            ? styles.extraIcon.substituicaoFeito
                                            : styles.extraIcon.substituicao
                                    )
                                }}
                                id={`${tipo === 'reserva' ? 'agendamento-substituicao-icone' : 'agendamento-reserva-icone'}${a.id}`}
                                width="15"
                                height="15"
                                fill="currentColor"
                            >
                                <polygon points="0,0 15,0 0,15" />
                            </svg>
                        }
                        content={
                            <div id={`${tipo === 'reserva' ? 'agendamento-substituicao' : 'agendamento-reserva'}${a.id}`} style={divStyleDropdown}>
                                <div className={horaFinalDiferente ? 'd-flex h-100per flex-wrap p-1' : 'd-flex h-100per align-content-between flex-wrap'}>
                                    <div className='col-12' title={a.paciente_nome}>
                                        {a.paciente_identificador}.
                                    </div>
                                    <div className='col-12'>
                                        {a.terapeuta_nome_curto ? a.terapeuta_nome_curto.substr(0, 10) : 'Sem Terapeuta'}.
                                    </div>
                                    {!horaFinalDiferente &&
                                        <div className='hora-diferente d-inline-flex' title={horaFinal}>
                                            Hora Final: {horaFinal}
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    />
                </div>
            );
        });
    }, [agendamentos, filtered, getLegendaAgendamento, styles, styles_escuro, styles_filtred]);
    const renderizaAgenda = useCallback((horario, index) => {
        const horaInteira = moment.utc(horario.horario);
        const hora = horaInteira.format('HH:mm');
        const idHorario = horario.id;
        const returns = (
            <tr style={styles.tbody.tr} key={index}>
                {terapeutas && terapeutas.map((terapeuta, index2) => {
                    const reservaNoTitulo = [];
                    const substituicaoNoTitulo = [];
                    const disponibilidade = terapeuta?.terapeuta_disponibilidades?.some(d => ((d.horario_atendimento_id === idHorario) || (d.horario === horario.horario)) && (d.dia_semana === diaAtual));
                    let titulo;
                    let agendaContent;
                    let divStyles = styles.tamanhoPadrao;
                    let tooltipInfo = <>
                        Horario: {hora}<br></br>
                        Terapeuta: {terapeuta.nome_curto}
                    </>;
                    const agendamento =
                        (newAgendamentos[terapeuta.id] !== undefined)
                            ? (newAgendamentos[terapeuta.id][idHorario] !== undefined)
                                ? newAgendamentos[terapeuta.id][idHorario]
                                : null
                            : null;
                    if (agendamento !== null && agendamento.length > 0) {
                        const agenda = agendamento.filter((a) => {
                            const reserva = a.reserva === 0;
                            const tipo = a.tipo_atendimento_id !== 5 && a.tipo_atendimento_id !== 7;
                            const espera = esperaMode ? a.tipo_atendimento_id === 8 : a.tipo_atendimento_id !== 8;
                            return reserva && tipo && espera;
                        });
                        if (agenda.length > 0) {
                            agendaContent = agenda.map((a) => {
                                //VARS\\
                                const legenda = getLegendaAgendamento(a, agendamentos);
                                let horaFinalDiferente;
                                let horaFinal;
                                const localEstacao = {
                                    estacao: a.estacao,
                                    sala: a.sala,
                                    id: a.estacao_id,
                                };
                                if (a.data_atendimento_final) {
                                    if (a.data_atendimento_final.includes('T')) {
                                        horaFinalDiferente = String(a.data_atendimento_final).split('T')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                                        horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split('T')[1].split('.')[0].split(':') : null;
                                    } else {
                                        horaFinalDiferente = String(a.data_atendimento_final).split(' ')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                                        horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split(' ')[1].split('.')[0].split(':') : null;
                                    }
                                }
                                horaFinal = horaFinal ? horaFinal[0] + ':' + horaFinal[1] : '00:00';
                                divStyles = {
                                    ...divStyles,
                                    cursor: 'default',
                                    ...styles.trOcupado,
                                };

                                if (a.filtrado === undefined) {
                                    divStyles = {
                                        ...divStyles,
                                        ...styles[legenda],
                                    };
                                } else if (a.filtrado === true) {
                                    divStyles = {
                                        ...divStyles,
                                        ...styles_filtred[legenda],
                                    };
                                } else {
                                    divStyles = {
                                        ...divStyles,
                                        ...styles_escuro[legenda],
                                    };
                                }
                                tooltipInfo = <>
                                    {(legenda === 'conflitoTerapeuta' || legenda === 'conflitoPaciente') &&
                                        <h5>{ChangeCase.toTitleCase(legenda)}</h5>
                                    }
                                    {!disponibilidade && <h6>Horário Indisponível</h6>}
                                    Terapeuta: {a.terapeuta_nome} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${a.terapeuta_id})`}<br></br>
                                    Sala/Estação: {localEstacao.sala} / {localEstacao.estacao} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${localEstacao.id})`}<br></br>
                                    Paciente: {a.paciente_nome} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${a.paciente_id})`}<br></br>
                                    Horário: {hora}  {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${horario.id})`}
                                </>;
                                titulo = <p className='pointer' onClick={() => setPacienteSelecionadoOpt({ value: a.paciente_id, label: a.paciente_nome })}>
                                    {a.paciente_identificador}
                                </p>;
                                return (
                                    <>
                                        <div className='col-12 d-flex justify-content-center align-items-center h-auto-i' id={'agendamento' + a.id}>
                                            <AiFillEye className='pointer h-azul' title='Detalhes do agendamento.' onClick={() => modalDetalhesAgendamento(a)}></AiFillEye>
                                        </div>
                                        <div className='col-12 h-auto-i'>
                                            {a.terapeuta_nome_curto ? a.terapeuta_nome_curto.substr(0, 7) + '.' : '---'}
                                        </div>
                                        {!horaFinalDiferente &&
                                            <div className='hora-diferente d-inline-flex' width={styles.width}>
                                                Hora Final: {horaFinal}
                                            </div>
                                        }
                                    </>
                                );
                            });
                        }
                        const agendaReserva = agendamento.filter((a) => {
                            const reserva = a.reserva === 1;
                            const espera = esperaMode ? a.tipo_atendimento_id === 8 : a.tipo_atendimento_id !== 8;
                            return reserva && espera;
                        });
                        if (agendaReserva.length > 0) {
                            reservaNoTitulo.push(...geraDropDownAgenda(agendaReserva, 'reserva'));
                        }
                        const agendaSubstituicao = !esperaMode ? agendamento.filter((a) => {
                            const substituicao = a.tipo_atendimento_id === 5 || a.tipo_atendimento_id === 7;
                            return substituicao;
                        }) : [];
                        if (agendaSubstituicao.length > 0) {
                            substituicaoNoTitulo.push(...geraDropDownAgenda(agendaSubstituicao, 'substituicao'));
                        }
                    }
                    const tooltipClass = getTooltipClass(index, index2);
                    /* FINAL RETURN */
                    return (
                        <td className={`${agendaContent ? 'tooltip-container ' : ''}`}
                            key={`linha-agendamento-terapeuta${terapeuta.id}-horario${idHorario}`}
                            id={index2 + '-last-' + index}
                            style={{
                                ...styles.trVazio,
                                ...disponibilidade ? styles.trDisponivel : styles.trIndisponivel,
                            }}
                            data-name='espaco-vazio'>
                            <div data-name='espaco-vazio' className={'d-grid '} style={divStyles} id={index2 + '-last-' + index + '-div'}>
                                <div data-name='espaco-vazio' className='w-100per d-flex h-15px m-0 p-0'>
                                    <div style={styles.agendamentoExtras}></div>
                                    <div style={styles.divTitulo}>{titulo}</div>
                                    <div style={styles.agendamentoExtras}></div>
                                </div>
                                {agendaContent}
                            </div>
                            {agendaContent &&
                                <div className={`${tooltipClass} me-4`}>
                                    <div className={'tooltip-text'}>
                                        {tooltipInfo}
                                        <div className="tooltip-arrow"></div>
                                    </div>
                                </div>
                            }
                        </td>
                    );
                })}
            </tr>
        );
        return returns;
    }, [agendamentos, esperaMode, filtered, getLegendaAgendamento, loading, newAgendamentos, setPacienteSelecionadoOpt, styles, styles_escuro, styles_filtred, terapeutas, usuario]);
    return (
        <tbody ref={agendamentoTableBodyRef} style={styles.tbody} onScrollCapture={(e) => { syncScroll(agendamentoTableBodyRef, horaTableBodyRef, terapeutaTableHeadRef); }}>
            {loading === false
                ? horarios.map((horario, index) => renderizaAgenda(horario, index))
                : <ClipLoader size={30} className='m-auto' />
            }
        </tbody>
    );
};

export default AgendaTerapeutaTbody;