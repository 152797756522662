import moment from 'moment';
import { useRef, useState } from 'react';
import api from 'services/api';
import AtendimentoService from 'services/AtendimentoService';
import Swal from 'sweetalert2';
import { useSnackbar } from './snackbar';
import AtendimentoAlteracaoService from 'services/AtendimentoAlteracaoService';

const useControleDiario = () => {
    const [controleDiario, setControleDiario] = useState([]);
    const snackbar = useSnackbar();
    const conferirConflitosModalRef = useRef();

    const fetchControleDiario = async (dataMoment, setOutsideControleDiarioId = () => { }, setTemDiaCarregado = () => { }, setCarregandoControleDiario = () => { }) => {
        try {
            const response = await api.get(`/api/controle-diario?filter[dia]=${dataMoment.format('YYYY-MM-DD')}&with=solicitacao_de_alteracaos,solicitacao_de_alteracaos.horarios_atendimento`);
            let controleDiario = response.data;
            if (controleDiario?.length < 1) {
                setCarregandoControleDiario(false);
                setTemDiaCarregado(false);
                return false;
            }
            controleDiario = controleDiario[controleDiario?.length - 1];
            setControleDiario(controleDiario);
            setOutsideControleDiarioId(controleDiario?.id);
            setTemDiaCarregado(true);
            setCarregandoControleDiario(false);
            return controleDiario;
        } catch (e) {
            console.error(e);
            setCarregandoControleDiario(false);
            return false;
        }
    };

    /**
     * Função para salvar o Controle Diário.
     *
     * @param {boolean} [semTesteConflito=false] - Indica se deve ignorar os testes de conflitos.
     * @param {Array} atendimentosFora - Lista de atendimentos originais.
     * @param {number} controleDiarioId - ID do Controle Diário a ser salvo.
     * @param {number} user_id - ID do usuário que está salvando o Controle Diário.
     * @returns {Promise<boolean>} - Retorna false se houver conflitos que impedem o salvamento, caso contrário, realiza o salvamento.
     */
    const salvarControleDiario = async (semTesteConflito = false, atendimentosFora = null, controleDiarioId, user_id) => {
        if (controleDiario?.salvo === null) {
            try {
                const response = await AtendimentoService.confereValidadeAtendimentos(controleDiario.id, !semTesteConflito);
                const { type, message_title, message, atendimentos, status } = response.data;
                
                if (status === 200) {

                }
                if (status === 400) {
                    Swal.fire(message_title, message, 'error');
                    if (type === 'erro') {
                        return false;
                    }
                }

                atendimentosFora =
                    (atendimentosFora)
                        ? atendimentosFora
                        : (atendimentos)
                            ? atendimentos
                            : await AtendimentoService.getByControleDiarioId(controleDiarioId);
                atendimentosFora = funcaoDataMap(atendimentosFora);
                if (atendimentosFora?.length > 0) {
                    let semBloqueio = true;
                    let semAviso = true;
                    for (let index = 0; index < atendimentosFora.length; index++) {
                        const e = atendimentosFora[index];
                        if (e.modo_criacao === '2' && e.status_atendimento === 0) {
                            semAviso = false;
                            Swal.fire('Pendencias não Resolvidas!', 'Existem atendimentos novos que não foram confirmados e nem negados.', 'error');
                            return false;
                        }
                        if (e.atendimento_alteracaos && String(e.atendimento_alteracaos.status) === '1') {
                            semBloqueio = false;
                            Swal.fire('Pendencias não Resolvidas!', 'Existem solicitações de alteração que não foram resolvidas.', 'error');
                            return false;
                        }
                    }

                    if (controleDiario.solicitacao_de_alteracaos.length > 0) {
                        for (let index = 0; index < controleDiario.solicitacao_de_alteracaos.length; index++) {
                            const e = controleDiario.solicitacao_de_alteracaos[index];

                            if (e.status.toUpperCase() === 'AGUARDANDO') {
                                semBloqueio = false;
                                Swal.fire('Pendencias não Resolvidas!', 'Existem solicitações de alteração que não foram resolvidas.', 'error');
                                return false;
                            }
                        }
                    }

                    if (!semAviso) {
                        if (conferirConflitosModalRef.current) {
                            conferirConflitosModalRef.current.openModal(atendimentosFora);
                            return false;
                        }
                    }
                    if (semBloqueio) {
                        Swal.fire({
                            title: 'Deseja mesmo salvar esse Controle Diário?',
                            text: 'O Controle Diário não podera ser alterado após essa ação.',
                            html: `<div style='width: 100%; display: grid'>
                                    <label for='obs'>Observação (Opcional): </label>
                                    <textarea style='width: 100%; border-radius: 10px; border: .2 solid grey' id='obs' name='obs' rows='5' placeholder='Digite alguma observação para o controle diário...'></textarea>
                            </div>`,
                            showCancelButton: true,
                            showConfirmButton: true,
                            confirmButtonText: 'Salvar',
                            cancelButtonText: 'Cancelar ação',
                            icon: 'question'
                        }).then((e) => {
                            if (e.isConfirmed) {
                                let obs = e?.value?.obs;
                                api.patch('api/controle-diario/' + controleDiarioId, { id: controleDiarioId, salvo: moment.utc(), salvo_por: user_id, obs: obs }).then((response) => {
                                    if (response.status === 400) {
                                        Swal.fire('Não foi possível salvar Controle Diário!', 'Houve um problema ao salvar o Controle Diário, porque algum atendimento não possue Guia válida, atualize a tela (F5) e confira novamente os Erros.', 'error');
                                    }
                                    if (response.status === 200) {
                                        window.location.reload();
                                    }
                                }).catch((e) => {
                                    const errorMessage = e.response?.data?.message || 'Não foi possível salvar o Controle Diário';
                                    Swal.fire(`Não foi possível salvar o Controle Diário(${e.response.status})`, errorMessage, 'error');
                                });
                            }
                        });
                    } else {
                        Swal.fire('Pendencias não Resolvidas!', 'Existem erros que impédem o Controle Diário de ser Salvo.', 'error');
                    }
                } else {
                    Swal.fire('Não foi possível salvar Controle Diário!', 'Não existem atendimentos para serem salvos.', 'error');
                }
            } catch (e) {
                console.error(e);
                return false;
            }
        } else {
            Swal.fire('Controle Diário já foi salvo!', 'Esse controle diário já foi salvo, e não pode ser alterado.', 'error');
        }
    };
    /**
     * Carrega os atendimentos do controle diário para uma data e usuário específicos.
     *
     * @param {string} dataFormatada - A data formatada no formato 'YYYY-MM-DD'.
     * @param {number} user_id - O ID do usuário para o qual os atendimentos serão carregados.
     * @returns {void}
     */
    const carregaControleDiario = async (dataFormatada, user_id) => {
        return Swal.fire({
            title: 'Deseja carregar os atendimentos?',
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            denyButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                return await api.get(`/api/controle-diario/carregar/${dataFormatada}/${user_id}`)
                    .then((response) => {
                        switch (response.status) {
                            case 200:
                                fetchControleDiario(moment.utc(dataFormatada), () => { }, () => { }, () => { });
                                snackbar.displayMessage('Dia carregado com sucesso!', 'success');
                                break;
                            case 400:
                                snackbar.displayMessage('Dia já foi carregado!', 'error');
                                break;
                            case 401:
                                Swal.fire('Existe um dia que não foi salvo', 'Por favor, confira os dias anteriores para salva-lo, e tente novamente!', 'error');
                                break;
                            default:
                                snackbar.displayMessage('Erro ao carregar dia!', 'error');
                                break;
                        }
                    }).catch((error) => {
                        switch (error.response.status) {
                            case 200:
                                snackbar.displayMessage('Dia carregado com sucesso!', 'success');
                                break;
                            case 400:
                                snackbar.displayMessage('Dia já foi carregado!', 'error');
                                break;
                            case 401:
                                Swal.fire('Existe um dia que não foi salvo', 'Por favor, confira os dias anteriores para salva-lo, e tente novamente!', 'error');
                                break;
                            default:
                                snackbar.displayMessage('Erro ao carregar dia!', 'error');
                                break;
                        }
                        console.error(error);
                    });
            }
        });
    }

    /**
     * Compara dois objetos com base no campo `hora_atendimento`.
     *
     * @param {Object} a - O primeiro objeto a ser comparado.
     * @param {Object} b - O segundo objeto a ser comparado.
     * @param {string} a.hora_atendimento - O horário de atendimento do primeiro objeto no formato 'HH:mm:ss'.
     * @param {string} b.hora_atendimento - O horário de atendimento do segundo objeto no formato 'HH:mm:ss'.
     * @returns {number} - Retorna -1 se `a.hora_atendimento` for anterior a `b.hora_atendimento`, 1 se for posterior, e 0 se forem iguais.
     */
    function compareHorario(a, b) {
        // Convertendo os horários para objetos Date para comparar corretamente
        const horarioA = new Date(moment.utc().format('YYYY-MM-DD') + ' ' + a.hora_atendimento);
        const horarioB = new Date(moment.utc().format('YYYY-MM-DD') + ' ' + b.hora_atendimento);

        // Comparando os horários e retornando o resultado da comparação
        if (horarioA < horarioB) {
            return -1;
        }
        if (horarioA > horarioB) {
            return 1;
        }
        return 0;
    }

    /**
     * Processa a resposta da API e mapeia os dados dos atendimentos.
     * 
     * @param {Object} response - A resposta da API contendo os dados dos atendimentos.
     * @param {Array} response.data - Array de objetos de atendimento.
     * 
     * @returns {void}
     * 
     * @description
     * Esta função processa a resposta da API e mapeia os dados dos atendimentos, incluindo a criação de uma lista de terapeutas,
     * a definição de cores e últimas alterações dos atendimentos, e a verificação de conflitos em horários de sala, terapeuta e paciente.
     * Além disso, define diversos estados relacionados aos atendimentos, como a lista de terapeutas, atendimentos normais e originais,
     * e flags de carregamento.
     */
    const funcaoDataMap = (
        response,
        setTerapeutasList = () => { },
        setAtendimentosNormal = () => { },
        setAtendimentosOriginal = () => { },
        setAlgoCarregando = () => { },
        setCarregandoAtendimentos = () => { },
        setQuantidadeDeAtualizacoes = () => { },
        setTemDiaCarregado = () => { }
    ) => {
        const data = response?.data ? response?.data : response;
        let terapeutasListLocal = [];
        data.map(atendimento => {
            const el = atendimento;
            if (terapeutasListLocal.filter((filter) => filter.value === el.terapeuta_id).length === 0) {
                terapeutasListLocal.push({ value: el.terapeuta_id, label: `(${el.id}) ${el.terapeuta_nome}` });
            }
            atendimento.cor = AtendimentoAlteracaoService.getCorStatus(atendimento.atendimento_alteracaos);
            atendimento.ultima_alteracao = AtendimentoAlteracaoService.getUltimaAlteracao(atendimento.atendimento_alteracaos);
            /* CONFLITOS */
            const realizadoOuNulo = el.sugestao_recepcao === null || el.sugestao_recepcao === 1;
            const horario_sala = data.filter((filter) => {
                const filtroRealizadoOuNulo = filter.sugestao_recepcao === null || filter.sugestao_recepcao === 1;
                return (filter.id !== el.id) && filtroRealizadoOuNulo && realizadoOuNulo && filter.estacao_id === el.estacao_id && filter.horarios_atendimento_id === el.horarios_atendimento_id;
            }).length > 0;
            const horario_terapeuta = data.filter((filter) => {
                const filtroRealizadoOuNulo = filter.sugestao_recepcao === null || filter.sugestao_recepcao === 1;
                return (filter.id !== el.id) && filtroRealizadoOuNulo && realizadoOuNulo && (filter.terapeuta_id !== null && filter.terapeuta_id === el.terapeuta_id) && filter.horarios_atendimento_id === el.horarios_atendimento_id;
            }).length > 0;
            const horario_paciente = data.filter((filter) => {
                const filtroRealizadoOuNulo = filter.sugestao_recepcao === null || filter.sugestao_recepcao === 1;
                return (filter.id !== el.id) && filtroRealizadoOuNulo && realizadoOuNulo && filter.paciente_id === el.paciente_id && filter.horarios_atendimento_id === el.horarios_atendimento_id && filter.estacao_id === el.estacao_id;
            }).length > 0;
            const sem_terapeuta = realizadoOuNulo && !el.terapeuta_id;
            const sem_status = realizadoOuNulo && !el.sugestao_recepcao;
            const sem_liberacao = realizadoOuNulo && !el.liberado;
            const realizado_nao_liberado = realizadoOuNulo && el.sugestao_recepcao === 1 && !el.liberado;
            const liberado_realizado_sem_codigo = realizadoOuNulo && !realizado_nao_liberado && !el?.codigo_1;
            const sem_execucao = realizadoOuNulo && !realizado_nao_liberado && !el.executado;
            const sem_guia = el.executado === 'executado' && !el.guia;
            const executado_nao_realizado = el.executado === 'executado' && el.sugestao_recepcao !== 1;
            const substituido = data.filter((filter) =>
                filter.id !== el.id &&
                el.tipo_atendimento_id !== 5 &&
                filter.horarios_atendimento_id === el.horarios_atendimento_id &&
                filter.estacao_id === el.estacao_id &&
                filter.tipo_atendimento_id === 5).length > 0;
            const conflitos = {
                'id': atendimento.id,
                'horario_sala': horario_sala,
                'horario_terapeuta': horario_terapeuta,
                'horario_paciente': horario_paciente,
                'sem_terapeuta': sem_terapeuta,
                'sem_status': sem_status,
                'sem_liberacao': sem_liberacao,
                'realizado_nao_liberado': realizado_nao_liberado,
                'liberado_realizado_sem_codigo': liberado_realizado_sem_codigo,
                'sem_execucao': sem_execucao,
                'sem_guia': sem_guia,
                'executado_nao_realizado': executado_nao_realizado,
            };
            atendimento.substituido = substituido;
            atendimento.conflitos = conflitos;
            return atendimento;
        });
        data.sort(compareHorario);
        //#region SETTERS
        setTerapeutasList(terapeutasListLocal.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        }));
        setAtendimentosNormal(data);
        setAtendimentosOriginal(data);
        setAlgoCarregando(false);
        setCarregandoAtendimentos(false);
        setQuantidadeDeAtualizacoes(0);
        setTemDiaCarregado(true);
        return data;
        //#endregion
    };

    return { controleDiario, fetchControleDiario, salvarControleDiario, carregaControleDiario, funcaoDataMap, setControleDiario, conferirConflitosModalRef };
};

export default useControleDiario;