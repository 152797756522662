import React, { useState } from 'react';
import Terapeuta from '../../../layouts/Terapeuta';
import { AiOutlineUser } from 'react-icons/ai';
import { useEffect } from 'react';
import api from '../../../services/api';
import { ContagemPendentes, Container, ContainerSub, FormField } from './styles';
import ModalInformacoesPaciente from './Modais/ModalInformaçãoPaciente';
import PacienteService from '../../../services/PacienteService';
import FiltroPaciente from '../../../components/Filter/FiltroPacientes/FiltroPaciente';

function TerapeutaPacientes() {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState();
    const [pacientes, setPacientes] = useState([]);
    const [loadingDadosPacientes, setLoadingDadosPacientes] = useState(false);
    const [pacienteOriginal, setPacienteOriginal] = useState([]);
    const [pacienteIdentList, setPacienteIdentList] = useState([]);
    const terapeuta = JSON.parse(sessionStorage.getItem('@Neuro:user'))?.terapeuta[0];

    const [filterValue, setFilter] = useState([]);
    /* eslint-disable-next-line no-unused-vars */
    const getPacientes = async (terapeuta_id) => {
        try {
            let pacienteOptions = await PacienteService.getPacienteByTerapeuta(terapeuta_id, true);
            setPacientes(pacienteOptions);
            setFilter(pacienteOptions);
        } catch (e) {
            console.error('Erro ao obter lista de pacientes - ', e);
        }
    };

    function handleInfo(itemPaciente) {
        setShowModal(true);
        setModalData(itemPaciente);
    }

    useEffect(() => {
        //getPacientes(terapeuta.id)
        setLoadingDadosPacientes(true);
        async function carregaPacientes() {
            api.get(`api/paciente/list/by/terapeuta/${terapeuta.id}?id=${terapeuta.id}`).then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                setPacienteOriginal(data);
                setFilter(data);
            });
            setLoadingDadosPacientes(false);
        }
        carregaPacientes();
    }, []);

    useEffect(() => {
        let idList = [];
        pacienteOriginal.forEach((data) => {
            if (data.status !== 'Inativo') {
                if (data.identificador !== null) {
                    idList.push(data.identificador);
                };
            };
        });
        setPacienteIdentList(idList);
    }, [pacienteOriginal, loadingDadosPacientes]);

    return (
        <>
            <React.Fragment>
                <Container>
                    <ContainerSub>
                        <div className='col-12'>
                            <FiltroPaciente resultRef={setFilter} list={pacientes} filtro_responsavel={false}></FiltroPaciente>
                        </div>
                        <FormField>
                            <div className='d-flex flex-wrap w-75 justify-arround'>
                                {filterValue !== null
                                    && filterValue.map((item, index) => {
                                        return (
                                            <div className='div-card-paciente' key={'prontu' + index} style={{ width: '290px', height: '329px', borderRadius: '10px', boxShadow: '0 0 10px 0px silver' }}>
                                                {item?.plano_terapeutico_pendente?.length > 0 &&
                                                    <ContagemPendentes title='Plano Terapêutico vencido, ou não existente.'>{item.plano_terapeutico_pendente.length}</ContagemPendentes>
                                                }
                                                <AiOutlineUser color='silver' size={60} className='align-self-center' />
                                                <span className='align-self-center mt-3 mb-3 titulo'> {item.nome}</span>
                                                <span>Indentificador: <span className='text-secondary'>{item.identificador}</span></span>
                                                <span>Idade: <span className='text-secondary'>{item.idade}</span></span>
                                                <span>Diagnósticos: <span className='text-secondary'>{item.diagnostico1_nome} - {item.diagnostico2_nome}</span></span>
                                                <span>Plano de saúde: <span className='text-secondary'>{item.plano_saude_nome}</span></span>
                                                <button className='mt-2 w-50 align-self-center btn-padrao' onClick={() => handleInfo(item)}>Informações</button>
                                            </div>
                                        );
                                    })}
                            </div>
                        </FormField>
                    </ContainerSub>
                </Container>
                {showModal && <ModalInformacoesPaciente onClose={() => setShowModal(false)} paciente={modalData} pacienteIdentList={pacienteIdentList} />}
            </React.Fragment>
        </>
    );
}

export default TerapeutaPacientes;