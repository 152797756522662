//#region IMPROTAÇÕES
import { Form } from '@unform/web';
import LiveBadge from 'components/LiveBadge';
import ModalTitulo from 'components/ModalTitulo';
import { useSnackbar } from 'context/snackbar';
import moment from 'moment';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import ReactModal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import api from 'services/api';
import Swal from 'sweetalert2';
import { SimpleInput } from '../../Input';
import Select from '../../Select';
import './styles.css';
import useStatusAtendimentosData from 'context/status-atendimento';
import Button from 'components/Button';
import useIcons from 'context/icons';
//#endregion

/**
 * Componente de Modal para Controle de Execução.
 *
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {Object} props.controleDiarioSocket - Socket de controle diário.
 * @param {Object} props.user - Objeto do usuário.
 * @param {Function} props.onClose - Função chamada ao fechar o modal.
 * @param {Function} props.onOpen - Função chamada ao abrir o modal.
 * @param {boolean} props.modoIncorporado - Indica se o modal está em modo incorporado.
 * @param {boolean} props.isCheckIn - Indica se o modal é de check-in.
 * @param {Object} ref - Referência do componente.
 *
 * @returns {JSX.Element} - Elemento JSX do modal de controle de execução.
 *
 * @example
 * <ControleDeExecucaoModal
 *   controleDiarioSocket={socket}
 *   user={user}
 *   onClose={handleClose}
 *   onOpen={handleOpen}
 *   modoIncorporado={false}
 *   isCheckIn={false}
 *   ref={modalRef}
 * />
 */
const ControleDeExecucaoModal = forwardRef(({
    controleDiarioSocket = null,
    user,
    onClose = () => { },
    onOpen = () => { },
    modoIncorporado = false,
    isCheckIn = false
}, ref) => {
    //#region VARIÁVEIS
    // Dados da página
    const title = 'Controle de Execução';
    // Hooks
    const snackbar = useSnackbar();
    const { statusAtendimentosOptions, fetchStatusAtendimentosData } = useStatusAtendimentosData();
    const { iconArray } = useIcons();

    // Estados
    const [isOpen, setIsOpen] = useState(false);
    const [presenca, setPresenca] = useState([]);

    // Listas
    const [guiasPaciente, setGuiasPaciente] = useState([]);
    const [atendimentosFiltrados, setAtendimentosFiltrados] = useState(null);
    const [filtredStatusAtendimentoOptions, setFiltredStatusAtendimentoOptions] = useState(null);
    const executadoOptions = {
        plano: [
            { value: '', label: <span className='font-cinza font-lighter font-small'>(Vazio)</span> },
            { value: 'pendente', label: 'Pendente' },
            { value: 'executado', label: 'Executado' },
            { value: 'executado-sce', label: 'Executado SCE' }
        ],
        particular: [
            { value: '', label: <span className='font-cinza font-lighter font-small'>(Vazio)</span> },
            { value: 'pendente', label: 'Pendente' },
            { value: 'pago', label: 'Pago' }
        ]
    };

    const opcoesLiberado = [
        { value: '', label: <span>(Vazio)</span> },
        { value: '0', label: '❌' },
        { value: '1', label: '✅' }
    ];

    // Estados de carregamento
    const [loadingGuias, setLoadingGuias] = useState(false);

    // Dados de formulário
    const [planoSaudeSelecionadoOpt, setPlanoSaudeSelecionadoOpt] = useState([]);
    const [executadoSelecionadoOpt, setExecutadoSelecionadoOpt] = useState(null);
    const [paciente_id, setPacienteId] = useState();
    const [controleDiarioId, setControleDiarioId] = useState();
    const [carterinhas, setCarterinhas] = useState([]);
    const [nome, setNome] = useState('');

    // Limites
    const [limitesPorTerapia, setLimitesPorTerapia] = useState([]);
    const [limiteAtingido, setLimiteAtingido] = useState(false);

    // Socket
    const socketRef = useRef(null);
    const [socketState, setSocketState] = useState(null);

    // Estilos
    const customStyles = {
        overlay: modoIncorporado ? {
            position: 'relative',
            backgroundColor: 'transparent',
            margin: '0',
            padding: '0',
            ...isCheckIn ? {
                display: 'flex',
                justifyContent: 'end',
            } : {}
        } : {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '999',
        },
        content: modoIncorporado ? {
            position: 'relative',
            height: 'fit-content',
            backgroundColor: '#F5F7FA',
            inset: '0',
            ...isCheckIn ? {
                width: window.innerWidth - 50,
            } : {
                width: '100%',
            }
        } : {
            position: 'absolute',
            zIndex: '1000',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: '70vh',
            width: '95vw',
            marginRight: '-50%',
            padding: '0',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#F5F7FA'
        },
    };
    const [newCustomStyles, setNewCustomStyles] = useState({
        overlay: { ...customStyles.overlay },
        content: {
            ...customStyles.content,
            ...presenca.length > 1 ?
                {
                    boxShadow: '0px 0px 20px #fff000',
                    border: '2px #fff000 solid'
                }
                : {}
        }
    });
    const codigosInputStyle = {
        width: '80%',
        margin: 'auto',
        borderBottom: '1px solid rgba(0,0,0,0.2)'
    };

    //#endregion
    //#region FUNCOES
    const fetchGuiasPaciente = useCallback(async (pacienteId) => {
        const id = pacienteId || paciente_id;
        await api.get(`api/guia?filter[paciente_id]=${id}&filter[status]=Ativo`).then(({ data }) => {
            setGuiasPaciente(data);
            setLoadingGuias(false);
        });
    }, []);
    //#endregion
    //#region FUNCOES de MODAL
    /**
     * Abre um modal para exibir informações de atendimento e carteirinhas faltantes de um paciente.
     *
     * @function
     * @async
     * @param {number} controle_diario_id - ID do controle diário.
     * @param {number} paciente_id - ID do paciente.
     * @returns {Promise<void>}
     *
     * @example
     * openModal(123, 456);
     *
     * @description
     * Esta função faz duas requisições assíncronas para obter dados de carteirinhas faltantes e atendimentos
     * de um paciente específico. Os dados são processados e armazenados em estados locais para serem exibidos
     * em um modal. A função também lida com erros de requisição e ordena os atendimentos por hora.
     */
    const openModal = useCallback(async (controle_diario_id, paciente_id) => {
        let atendimentos = [];
        let listaSelecionados = [];
        let listaSelecionadosExec = [];
        setControleDiarioId(controle_diario_id);
        setPacienteId(paciente_id);
        await api.get(`api/paciente/getCarterinhasFaltantes/${paciente_id}`)
            .then(({ data }) => {
                const { carterinhas, pendentes } = data;
                let carterinhasList = [];
                pendentes?.forEach((item) => {
                    carterinhasList.push({
                        id: item.id,
                        created_at: false,
                        plano_saude: { nome_reduzido: item.plano },
                        ultima_conferencia: null
                    });
                });

                carterinhas?.forEach((item) => {
                    if (typeof item === 'object') {
                        item.forEach((subItem) => {
                            carterinhasList.push(subItem);
                        });
                    } else {
                        carterinhasList.push(item);
                    }
                });

                setCarterinhas(carterinhasList);
            })
            .catch((error) => console.error(error));
        fetchGuiasPaciente(paciente_id);
        const url = `api/atendimento/getByControleDiario/${controle_diario_id}?listas=solicitacao_de_alteracaos,guia,paciente.terapias_paciente&extraSelect=terapia_paciente.plano_saude.!plano_saude_terapia.codigo_plano,terapia_paciente.plano_saude.nome_reduzido,terapia_paciente.plano_saude.limite_diario_terapias&user_acesso_id=${user.id}&paciente_id=${paciente_id}`;
        await api.get(url)
            .then(({ data }) => {
                try {
                    let limitesPorTerapiaLocal = [];
                    data.sort((a, b) => {
                        return a.hora_atendimento.localeCompare(b.hora_atendimento);
                    });
                    data.forEach(async (a) => {
                        let paciente_id_teste = a.paciente_id;
                        if (paciente_id_teste === paciente_id) {
                            setNome(a.paciente_nome);
                            let planos = [];
                            let atendimento = a;
                            atendimento.terapias_paciente.forEach((e) => {
                                if (e.paciente_id === atendimento.paciente_id) {
                                    if (e.terapia_id === atendimento.terapia_id && e.especialidade_id === atendimento.especialidade_id) {
                                        planos.push({
                                            value: e.id,
                                            label: e.nome_reduzido,
                                            plano_saude_id: e.plano_saude_id,
                                            codigo: e.codigo_plano,
                                            terapia_id: e.terapia_id,
                                            especialidade_id: e.especialidade_id,
                                            limite_diario_terapias: e.limite_diario_terapias,
                                        });
                                        if (limitesPorTerapiaLocal.filter(filter => filter.value === e.plano_saude_id).length === 0) {
                                            limitesPorTerapiaLocal.push({
                                                value: e.plano_saude_id,
                                                label: e.nome_reduzido,
                                                limite_diario_terapias: e.limite_diario_terapias
                                            });
                                        }
                                    }
                                }
                            });
                            atendimentos.push({ ...atendimento, lista_planos: planos });
                            let planoSelecionadoOpt = planos.filter((e) => {
                                return Number(e.value) === Number(atendimento.terapia_paciente_id);
                            });
                            listaSelecionados[atendimentos.length - 1] = planoSelecionadoOpt;
                            switch (atendimento.executado) {
                                case 'pago':
                                    listaSelecionadosExec[atendimentos.length - 1] = { value: 'pago', label: 'Pago' };
                                    break;
                                case 'executado':
                                    listaSelecionadosExec[atendimentos.length - 1] = { value: 'executado', label: 'Executado' };
                                    break;
                                case 'pendente':
                                    listaSelecionadosExec[atendimentos.length - 1] = { value: 'pendente', label: 'Pendente' };
                                    break;
                                default:
                                    listaSelecionadosExec[atendimentos.length - 1] = null;
                                    break;
                            }
                        }
                    });
                    setLimitesPorTerapia(limitesPorTerapiaLocal);
                } catch (e) {
                    console.error(e);
                }
            });
        setPlanoSaudeSelecionadoOpt(listaSelecionados);
        setExecutadoSelecionadoOpt(listaSelecionadosExec);
        setAtendimentosFiltrados(atendimentos);
        setIsOpen(true);
        setSocketState(false);
        onOpen();
    }, []);
    /**
     * Fecha o modal de check-in.
     *
     * @param {boolean} isSalvando - Indica se as alterações estão sendo salvas.
     * @param {Object} socketLocal - Instância do socket local.
     * @param {number} controleDiarioId - ID do controle diário.
     * @returns {Promise<void>} - Uma promessa que resolve quando o modal é fechado.
     */
    const closeModal = useCallback(async (isSalvando, socketLocal, controleDiarioId) => {
        let passa = false;
        if (!isSalvando) {
            await Swal.fire({
                title: 'Deseja mesmo saír sem salvar nada?',
                html: 'Se você sair agora, qualquer alteração feita não será salva, caso queira salvar, aperte em Salvar!',
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonAriaLabel: 'Sair sem salvar!',
                cancelButtonAriaLabel: 'Voltar'
            }).then((response) => {
                if (response.isConfirmed) {
                    passa = true;
                }
            });
        }

        if (isSalvando || passa) {
            setIsOpen(false);
            socketLocal.close();
            onClose(controleDiarioSocket, controleDiarioId);
        }
    }, []);
    const afterOpenModal = () => { };
    useImperativeHandle(ref, () => ({
        openModal,
        closeModal,
        isOpen
    }), [isOpen]);
    //#endregion
    //#region HANDLES
    /**
     * Manipula a mudança do estado "liberado" de um atendimento filtrado.
     *
     * @param {Object} e - O evento que contém o novo valor.
     * @param {number} index - O índice do atendimento a ser atualizado.
     */
    const handleChangeLiberado = useCallback((e, index) => {
        const newAtendimentos = [...atendimentosFiltrados];
        newAtendimentos[index].liberado = String(e.value);
        setAtendimentosFiltrados(newAtendimentos);
    }, [atendimentosFiltrados]);
    const handleChangeStatusAtendimento = useCallback((e, index) => {
        const newAtendimentos = [...atendimentosFiltrados];
        newAtendimentos[index].sugestao_recepcao = String(e.value);
        setAtendimentosFiltrados(newAtendimentos);
    }, [atendimentosFiltrados]);
    /**
     * Manipula a mudança do estado "assinado" de um atendimento específico.
     *
     * @param {Object} e - O evento de mudança do checkbox.
     * @param {number} index - O índice do atendimento no array de atendimentos filtrados.
     */
    const handleChangeAssinado = useCallback((e, index) => {
        const newAtendimentos = [...atendimentosFiltrados];
        newAtendimentos[index].assinado = e.target.checked;
        setAtendimentosFiltrados(newAtendimentos);
    }, [atendimentosFiltrados]);
    /**
     * Função de callback para lidar com a mudança do plano de saúde selecionado.
     *
     * @param {Object} e - O evento ou objeto contendo a nova seleção do plano de saúde.
     * @param {number} index - O índice do plano de saúde selecionado na lista.
     *
     * Esta função atualiza o estado do plano de saúde selecionado e do executado selecionado.
     * Se o plano de saúde selecionado for "particular", os valores dos códigos são definidos como 0.
     * Caso contrário, os valores dos códigos são definidos como null.
     */
    const handleChangePlanoSelecionado = useCallback((e, index) => {
        let newPlanoSelect = [...planoSaudeSelecionadoOpt];
        newPlanoSelect[index] = [e];
        setPlanoSaudeSelecionadoOpt(newPlanoSelect);

        let newExecuteSelect = [...executadoSelecionadoOpt];
        newExecuteSelect[index] = null;
        setExecutadoSelecionadoOpt(newExecuteSelect);

        let cod1 = document.getElementById('codigo1' + index);
        let cod2 = document.getElementById('codigo2' + index);
        if (e?.label?.toLowerCase() === 'particular') {
            cod1.value = 0;
            cod2.value = 0;
            handleChangeCodigo(index);
        } else {
            cod1.value = null;
            cod2.value = null;
        }
    }, [planoSaudeSelecionadoOpt, executadoSelecionadoOpt]);
    /**
     * Manipula a mudança de seleção de um item executado.
     *
     * @param {Object} e - O evento de mudança.
     * @param {number} index - O índice do item na lista de opções selecionadas.
     */
    const handleChangeExecutado = useCallback((e, index) => {
        const newSelect = [...executadoSelecionadoOpt];
        newSelect[index] = e;
        setExecutadoSelecionadoOpt(newSelect);
    }, [executadoSelecionadoOpt]);
    /**
     * Manipula a visibilidade dos elementos select 'liberado' e 'executado' com base no valor do input 'codigo1'.
     * 
     * @param {number} index - O índice do elemento a ser manipulado.
     */
    const handleChangeCodigo = useCallback((index) => {
        const cod1 = document.getElementById('codigo1' + index);
        const selectLiberado = document.getElementById('liberado' + index);
        const selectExecutado = document.getElementById('executado' + index);
        if (cod1.value) {
            selectLiberado.classList.remove('some');
            selectExecutado.classList.remove('some');
        } else {
            selectLiberado.classList.add('some');
            selectExecutado.classList.add('some');

            let newSelectExecutado = [...executadoSelecionadoOpt];
            newSelectExecutado[index] = null;
            setExecutadoSelecionadoOpt(newSelectExecutado);
        }
    }, [executadoSelecionadoOpt]);
    /**
     * Função que verifica se a quantidade de terapias diárias permitidas por plano de saúde foi atingida.
     * 
     * Percorre os limites definidos por terapia e verifica se a quantidade de atendimentos liberados
     * para cada plano de saúde excede o limite diário permitido. Caso o limite seja atingido, exibe
     * um alerta utilizando a biblioteca Swal e atualiza o estado `limiteAtingido`.
     * 
     * @callback handleTestaQuantidade
     * @returns {void}
     */
    const handleTestaQuantidade = useCallback(() => {
        let atingido = false;
        limitesPorTerapia.forEach((limite) => {
            let quantidade = 0;
            let nome;
            atendimentosFiltrados.forEach((atendimento, index) => {
                const cod1 = document.getElementById('codigo1' + index);
                if (atendimento.liberado && cod1.value) {
                    if (Number(limite.value) === Number(planoSaudeSelecionadoOpt[index][0].plano_saude_id)) {
                        quantidade = quantidade + 1;
                        nome = planoSaudeSelecionadoOpt[index][0].label;
                    }
                }
            });
            if (limite.limite_diario_terapias !== null && limite.limite_diario_terapias !== 0 && quantidade > limite.limite_diario_terapias) {
                Swal.fire('Limite Atingido!', `O limite de terapias diárias do plano de saúde: ${nome} foi atingido. Limite: ${limite.limite_diario_terapias} Quantidade: ${quantidade}`, 'error');
                atingido = true;
            }
        });
        setLimiteAtingido(atingido);
    }, [limitesPorTerapia, atendimentosFiltrados, planoSaudeSelecionadoOpt]);
    /**
     * Função para lidar com o envio de dados do formulário.
     * 
     * @param {Object} data - Dados do formulário a serem enviados.
     * 
     * @returns {Promise<void>} - Retorna uma Promise que resolve quando o processo de envio é concluído.
     * 
     * @throws {Error} - Lança um erro se ocorrer algum problema durante o envio dos dados.
     * 
     * @async
     * 
     * @callback handleSubmit
     * 
     * @param {boolean} limiteAtingido - Indica se o limite de atendimentos diário foi atingido.
     * @param {Array} carterinhas - Lista de carteirinhas do paciente.
     * @param {Array} atendimentosFiltrados - Lista de atendimentos filtrados.
     * @param {Array} planoSaudeSelecionadoOpt - Opções de plano de saúde selecionadas.
     * @param {Array} executadoSelecionadoOpt - Opções de execução selecionadas.
     * @param {number} paciente_id - ID do paciente.
     * @param {Object} snackbar - Objeto para exibir mensagens de feedback.
     * @param {Function} closeModal - Função para fechar o modal.
     * @param {number} controleDiarioId - ID de controle diário.
     */
    const handleSubmit = useCallback(async (data) => {
        if (limiteAtingido) {
            return Swal.fire('Limite Atingido!', 'O limite de atendimentos diário de algum dos planos utilizados foi atingido!', 'error');
        }
        Swal.fire({
            title: 'Deseja mesmo salvar os dados inseridos?',
            html: 'Você confirma a procedência dos dados inseridos, e deseja salva-los?',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonAriaLabel: 'Sair sem salvar!',
            cancelButtonAriaLabel: 'Voltar'
        }).then(async (response) => {
            if (response.isConfirmed) {
                try {
                    carterinhas.forEach((item) => {
                        const alteredItem = data['numero_carterinha' + item.id];
                        if (data[`numero_carterinha${item.id}new`]) {
                            if (data[`numero_carterinha${item.id}new`]) {
                                let newData = {
                                    plano_saude_id: item.id,
                                    paciente_id: paciente_id,
                                    ultima_conferencia: moment().format('YYYY-MM-DD HH:mm:ss'),
                                    numero_carterinha: data[`numero_carterinha${item.id}new`]
                                };
                                if (item.numero_carterinha !== alteredItem) {
                                    newData = {
                                        ...newData,
                                        numero_carterinha: alteredItem
                                    };
                                }
                                api.post('api/carterinha-plano-paciente', newData)
                                    .catch(error => console.error(error));
                            }
                        } else {
                            let newData = {
                                ...item,
                                ultima_conferencia: moment().format('YYYY-MM-DD HH:mm:ss')
                            };
                            if (item.numero_carterinha !== alteredItem) {
                                newData = {
                                    ...newData,
                                    numero_carterinha: alteredItem
                                };
                            }
                            api.patch(`api/carterinha-plano-paciente/${item.id}?id=${item.id}`, newData)
                                .catch(error => console.error(error));
                        }
                    });
                    let quantidadePorTerapiaPaciente = [];
                    const atendimentos = atendimentosFiltrados.map((el, index) => {
                        const planoSelecionado = planoSaudeSelecionadoOpt[index][0];
                        const atendimento = {
                            terapia_paciente_id: planoSelecionado.value,
                            codigo_1: data[`codigo1${index}`] ? data[`codigo1${index}`] : '',
                            codigo_2: data[`codigo2${index}`] ? data[`codigo2${index}`] : '',
                            executado: executadoSelecionadoOpt[index] !== null ? executadoSelecionadoOpt[index].value : null,
                            liberado: (data[`codigo1${index}`] || el.codigo_1) ? el.liberado : '0',
                            assinado: el.assinado,
                            sugestao_recepcao: el.sugestao_recepcao,
                            id: el.id,
                        };

                        if (atendimento?.executado?.includes('executado') || atendimento?.executado?.includes('pendente') || atendimento?.executado?.includes('pago')) {
                            const terapiaPacienteId = atendimento.terapia_paciente_id;
                            if (!quantidadePorTerapiaPaciente[terapiaPacienteId]) {
                                quantidadePorTerapiaPaciente[terapiaPacienteId] = {
                                    quantidade: 0,
                                    valido: false,
                                    label: el.terapia
                                };
                            }
                            quantidadePorTerapiaPaciente[terapiaPacienteId].quantidade++;
                        }

                        return atendimento;
                    });
                    if (!isCheckIn) {
                        if (guiasPaciente.length > 0) {
                            guiasPaciente.forEach((guia) => {
                                const terapiaPacienteId = guia.terapia_paciente_id;
                                const disponivel = Number(guia.quantidade_liberada) - Number(guia.quantidade_feita);

                                if (quantidadePorTerapiaPaciente[terapiaPacienteId].quantidade > disponivel) {
                                    Swal.fire('Erro!', `A quantidade de atendimentos para a terapia ${quantidadePorTerapiaPaciente[terapiaPacienteId].label} excede o limite disponível.`, 'error');
                                    throw new Error('Quantidade de atendimentos excede o limite disponível.');
                                } else {
                                    quantidadePorTerapiaPaciente[terapiaPacienteId].valido = true;
                                }
                            });
                        } else {
                            Swal.fire('Erro!', `Não existem guias cadastradas para esse paciente.`, 'error');
                            throw new Error('Não existem guias cadastradas para esse paciente.');
                        }
                        quantidadePorTerapiaPaciente.forEach((terapia) => {
                            if (!terapia.valido) {
                                Swal.fire('Erro!', `A quantidade de atendimentos para a terapia: ${terapia.label} excede o limite disponível.`, 'error');
                                throw new Error('Quantidade de atendimentos excede o limite disponível.');
                            }
                        });
                    }

                    await api.put('api/atendimento/controle/execucao', { atendimentos }).then(() => {
                        snackbar.displayMessage('Controle de execução salvo com sucesso.', 'success');
                    }).catch((error) => {
                        snackbar.displayMessage('Erro ao salvar controle de execução.', 'error');
                        console.error(error);
                    });
                    closeModal(true, socketRef.current, controleDiarioId);
                } catch (error) {
                    console.error(error);
                }
            }
        });
    }, [limiteAtingido, carterinhas, atendimentosFiltrados, planoSaudeSelecionadoOpt, executadoSelecionadoOpt, paciente_id, snackbar, closeModal, controleDiarioId, guiasPaciente]);
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        if (isOpen) {
            if (paciente_id && !socketRef.current) {
                let urlWS;
                const params = `?user_id=${user.id}&username=${user.name}&paciente_id=${paciente_id}&controle_diario_id=${controleDiarioId}`;
                if (window.location.hostname === 'localhost') {
                    urlWS = `ws://localhost:6001/ws/controle_execucao${params}`;
                } else {
                    urlWS = `wss://sistema.neurointensiva.com/ws/controle_execucao${params}`;
                }

                socketRef.current = new WebSocket(urlWS);

                const handleNovaPresenca = (pessoa) => {
                    setPresenca(prevPresenca => {
                        const updatedPresenca = [...prevPresenca];
                        const index = updatedPresenca.findIndex(p => p.id === pessoa.id);
                        if (index !== -1) {
                            updatedPresenca[index] = pessoa;
                        } else {
                            updatedPresenca.push(pessoa);
                        }
                        return updatedPresenca;
                    });
                };

                const handleUpdatePresencaList = data => {
                    setPresenca(data.connections ? data.connections : []);
                };

                const handleRemovePresenca = (id) => {
                    setPresenca(prevPresenca =>
                        prevPresenca.filter(pessoa => pessoa.id !== id)
                    );
                };

                socketRef.current.addEventListener('open', () => {
                    setSocketState(true);
                });

                socketRef.current.addEventListener('close', () => {
                    setSocketState(false);
                    socketRef.current = null;
                });

                socketRef.current.addEventListener('message', (event) => {
                    try {
                        if (event.data) {
                            const data = JSON.parse(event.data);
                            if (data) {
                                if (data.action === 'new_connection') {
                                    handleNovaPresenca(data);
                                } else if (data.action === 'update_connections_list') {
                                    handleUpdatePresencaList(data);
                                } else if (data.action === 'disconnect') {
                                    handleRemovePresenca(data.id);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('Erro ao parsear a mensagem: ', error);
                    }
                });

                socketRef.current.addEventListener('error', (event) => {
                    console.error('Erro: ', event);
                });
            }
        }

        // Clean-up function to close the WebSocket connection
        return () => {
            if (socketRef.current) {
                socketRef.current.close();
                socketRef.current = null;
            }
        };
    }, [isOpen, paciente_id, user.id, user.name]);
    useEffect(() => {
        setNewCustomStyles({
            overlay: { ...customStyles.overlay },
            content: {
                ...customStyles.content,
                ...presenca.length > 1 ?
                    {
                        boxShadow: '0px 0px 20px #fff000',
                        border: '2px #fff000 solid'
                    }
                    : {}
            }
        });
    }, [presenca]);
    useEffect(() => {
        if (statusAtendimentosOptions) {
            setFiltredStatusAtendimentoOptions(statusAtendimentosOptions.filter(filter => filter.value !== 1));
        }
    }, [statusAtendimentosOptions]);
    useEffect(() => {// ON LOAD
        fetchStatusAtendimentosData();
    }, []);
    //#endregion
    return (
        <ReactModal style={newCustomStyles} onRequestClose={() => closeModal(false, socketRef.current, controleDiarioId)} afterOpenModal={afterOpenModal} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} appElement={document.getElementById('root')} isOpen={isOpen} transparent>
            {modoIncorporado
                ? <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex justify-content-start align-items-center'>
                        <ClipLoader size={22} color={'#000'} loading={false} />
                        <div className='ps-4 pt-4'>
                            <LiveBadge sincronizado={socketState} texto={false} posicao={'relativa'} />
                        </div>
                    </div>
                    <ModalTitulo> {nome}</ModalTitulo>
                    <label className='btn-outline text-right pointer' onClick={() => closeModal(false, socketRef.current, controleDiarioId)}>
                        <CgClose size={22} color='red' />
                    </label>
                </div>
                : <nav className='navbar sticky-top bg-cinza-claro shadow m-0'>
                    <div className='container-fluid d-flex align-items-center justify-content-between'>
                        <div className='col-4 d-flex flex-row'>
                            <ClipLoader size={22} color={'#000'} loading={false} />
                            <div className='ps-4 pt-4'>
                                <LiveBadge sincronizado={socketState} texto={false} posicao={'relativa'} />
                            </div>
                            <Button className='w-content-i h-content-i' onClick={() => { setLoadingGuias(true); fetchGuiasPaciente(paciente_id); }}>
                                {iconArray['atualizar']} Guias
                            </Button>
                        </div>
                        <ModalTitulo className='col-4 text-center'>{title}<br /> {nome}</ModalTitulo>
                        <label className={`btn-outline col-4 text-right pointer${loadingGuias ? ' rodando' : ''}`} onClick={() => closeModal(false, socketRef.current, controleDiarioId)}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
            }
            <div className='m-0 p-0'>
                <Form onSubmit={handleSubmit} className='d-flex flex-col justify-content-between max-h-100per-80px h-100per-80px'>
                    <table className='table-round'>
                        <thead key={'thead-ce'}>
                            <tr key={'cabecalho-ce'}>
                                {isCheckIn
                                    ? <>
                                        <th key={'col-1-ce'} className='plano_saude'>Plano de Saúde</th>
                                        <th key={'col-2-ce'} className='horario'>Horário</th>
                                        <th key={'col-3-ce'} className='terapeuta'>Terapeuta</th>
                                        <th key={'col-4-ce'} className='terapia'>Terapia</th>
                                        <th key={'col-5-ce'} className='especialidade'>Especialidade</th>
                                        <th key={'col-6-ce'} className='codigo'>Código 1</th>
                                        <th key={'col-7-ce'} className='codigo'>Código 2</th>
                                        <th key={'col-8-ce'} className='codigo'>Código Terapia</th>
                                        <th key={'col-9-ce'} className='liberado'>Liberado</th>
                                        <th key={'col-11-ce'} className='status_atendimento'>Status Atendimento</th>
                                        <th key={'col-12-ce'} className='assinado'>Assinado</th>
                                    </>
                                    : <>
                                        <th key={'col-1-ce'} className='plano_saude'>Plano de Saúde</th>
                                        <th key={'col-2-ce'} className='horario'>Horário</th>
                                        <th key={'col-3-ce'} className='terapeuta'>Terapeuta</th>
                                        <th key={'col-4-ce'} className='terapia'>Terapia</th>
                                        <th key={'col-5-ce'} className='especialidade'>Especialidade</th>
                                        <th key={'col-6-ce'} className='codigo'>Código 1</th>
                                        <th key={'col-7-ce'} className='codigo'>Código 2</th>
                                        <th key={'col-8-ce'} className='codigo'>Código Terapia</th>
                                        <th key={'col-10-ce'} className='executado'>Executado</th>
                                        <th key={'col-12-ce'} className='assinado'>Assinado</th>
                                        <th key={'col-11-ce'} className='status_atendimento'>Status Atendimento</th>
                                        <th key={'col-9-ce'} className='liberado'>Liberado</th>
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody key={'tbody-ce'}>
                            {atendimentosFiltrados && atendimentosFiltrados.map((el, index) => {
                                let executadoOptionsLocal;
                                try {
                                    executadoOptionsLocal = planoSaudeSelecionadoOpt[index][0]?.label?.toLowerCase() === 'particular' ? executadoOptions?.particular : executadoOptions?.plano;
                                } catch (error) {
                                    console.error(error);
                                }
                                if (isCheckIn) {
                                    return (
                                        <tr key={'row-' + index + '-ce'} className='table-check-in-linha'>
                                            <td key={'row-' + index + '-ce-col-1'} className='plano_saude'>
                                                {el.terapeuta_nome ?
                                                    <Select
                                                        id={'plano' + index}
                                                        name={'plano' + index}
                                                        options={el.lista_planos}
                                                        defaultValue={planoSaudeSelecionadoOpt[index]}
                                                        value={planoSaudeSelecionadoOpt[index]}
                                                        onChange={(e) => handleChangePlanoSelecionado(e, index)}
                                                        className='select'
                                                    />
                                                    : <span>Sem terapeuta...</span>
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-2'} className='horario'>
                                                <div className='hora'>
                                                    {el.hora_atendimento.slice(0, -3)}
                                                </div>
                                            </td>
                                            <td key={'row-' + index + '-ce-col-3'} className='terapeuta'>{el.terapeuta_nome}</td>
                                            <td key={'row-' + index + '-ce-col-4'} className='terapia'>{el.terapia}</td>
                                            <td key={'row-' + index + '-ce-col-5'} className='especialidade'>{el.especialidade}</td>
                                            <td key={'row-' + index + '-ce-col-6'} className='codigo p-0 text-center'>
                                                {el.terapeuta_nome ?
                                                    <SimpleInput
                                                        id={'codigo1' + index}
                                                        name={'codigo1' + index}
                                                        className='m-0'
                                                        onChange={() => handleChangeCodigo(index)}
                                                        onBlur={() => handleTestaQuantidade()}
                                                        onKeyUp={(e) => e.target.value = e.target.value.replace(' ', '')}
                                                        defaultValue={el.codigo_1}
                                                        placeholder={el.nome_codigo_1 || 'Código 1'}
                                                        style={codigosInputStyle}
                                                    />
                                                    : <span>Sem terapeuta...</span>
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-7'} className='codigo p-2 text-center'>
                                                {el.terapeuta_nome ?
                                                    <SimpleInput id={'codigo2' + index} name={'codigo2' + index} className='m-0' onChange={() => handleChangeCodigo(index)}
                                                        onKeyUp={(e) => e.target.value = e.target.value.replace(' ', '')}
                                                        defaultValue={el.codigo_2} placeholder={el.nome_codigo_2 || 'Código 2'}
                                                        style={codigosInputStyle} />
                                                    : <span>Sem terapeuta...</span>
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-8'} className='codigo p-2 text-center' id={'codigo' + index}>
                                                {planoSaudeSelecionadoOpt[index] && planoSaudeSelecionadoOpt[index][0]?.codigo}
                                            </td>
                                            <td key={'row-' + index + '-ce-col-9'} className='liberado'>
                                                {el.terapeuta_nome
                                                    ? <Select
                                                        id={'liberado' + index}
                                                        name={'liberado' + index}
                                                        options={opcoesLiberado}
                                                        defaultValue={opcoesLiberado.find(option => String(option.value) === String(el.liberado))}
                                                        onChange={(e) => { handleChangeLiberado(e, index); handleTestaQuantidade(); }}
                                                        className={!(el.codigo_1) && 'some'}
                                                    />
                                                    : <>❌</>
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-11'} className='status_atendimento'>
                                                <Select
                                                    id={'status_atendimento' + index}
                                                    name={'status_atendimento' + index}
                                                    options={[{ value: '', label: <span>(Vazio)</span> }, ...filtredStatusAtendimentoOptions]}
                                                    defaultValue={filtredStatusAtendimentoOptions.find(option => Number(option.value) === Number(el.sugestao_recepcao))}
                                                    onChange={(e) => { handleChangeStatusAtendimento(e, index); }}
                                                />
                                            </td>
                                            <td key={'row-' + index + '-ce-col-12'} className='assinado m-0'>
                                                {el?.exige_lista_presenca ?
                                                    <label className='check-label' >
                                                        <SimpleInput type='checkbox' name={'assinado' + index} id={'assinado' + index} onChange={(e) => handleChangeAssinado(e, index)} checked={el.assinado} />
                                                        <span className='checkmark'></span>
                                                    </label>
                                                    : ''
                                                }
                                            </td>
                                        </tr>
                                    );
                                } else {
                                    return (
                                        <tr key={'row-' + index + '-ce'} className='table-check-in-linha'>
                                            <td key={'row-' + index + '-ce-col-1'} className='plano_saude'>
                                                {el.terapeuta_nome
                                                    ? <Select
                                                        id={'plano' + index}
                                                        name={'plano' + index}
                                                        options={el.lista_planos}
                                                        defaultValue={planoSaudeSelecionadoOpt[index]}
                                                        value={planoSaudeSelecionadoOpt[index]}
                                                        onChange={(e) => handleChangePlanoSelecionado(e, index)}
                                                        className='select'
                                                    />
                                                    : <span>Sem terapeuta...</span>
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-2'} className='horario'>
                                                <div className='hora'>
                                                    {el.hora_atendimento.slice(0, -3)}
                                                </div>
                                            </td>
                                            <td key={'row-' + index + '-ce-col-3'} className='terapeuta'>{el.terapeuta_nome}</td>
                                            <td key={'row-' + index + '-ce-col-4'} className='terapia'>{el.terapia}</td>
                                            <td key={'row-' + index + '-ce-col-5'} className='especialidade'>{el.especialidade}</td>
                                            <td key={'row-' + index + '-ce-col-6'} className='codigo p-0 text-center'>
                                                {el.terapeuta_nome
                                                    ? <SimpleInput
                                                        id={'codigo1' + index}
                                                        name={'codigo1' + index}
                                                        className='m-0'
                                                        onChange={() => handleChangeCodigo(index)}
                                                        onBlur={() => handleTestaQuantidade()}
                                                        onKeyUp={(e) => e.target.value = e.target.value.replace(' ', '')}
                                                        defaultValue={el.codigo_1}
                                                        placeholder={el.nome_codigo_1 || 'Código 1'}
                                                        style={codigosInputStyle}
                                                    />
                                                    : <span>Sem terapeuta...</span>
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-7'} className='codigo p-2 text-center'>
                                                {el.terapeuta_nome
                                                    ? <SimpleInput id={'codigo2' + index} name={'codigo2' + index} className='m-0' onChange={() => handleChangeCodigo(index)}
                                                        onKeyUp={(e) => e.target.value = e.target.value.replace(' ', '')}
                                                        defaultValue={el.codigo_2} placeholder={el.nome_codigo_2 || 'Código 2'}
                                                        style={codigosInputStyle} />
                                                    : <span>Sem terapeuta...</span>
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-8'} className='codigo p-2 text-center' id={'codigo' + index}>
                                                {planoSaudeSelecionadoOpt[index] && planoSaudeSelecionadoOpt[index][0]?.codigo}
                                            </td>
                                            <td key={'row-' + index + '-ce-col-10'} className='executado m-0'>
                                                {el.terapeuta_nome
                                                    ? <Select
                                                        id={'executado' + index}
                                                        name={'executado' + index}
                                                        options={executadoOptionsLocal}
                                                        defaultValue={executadoSelecionadoOpt[index]}
                                                        value={executadoSelecionadoOpt[index]}
                                                        className={
                                                            (executadoSelecionadoOpt[index] !== null)
                                                                ? (executadoSelecionadoOpt[index]?.value === 'pendente')
                                                                    ? 'select-exec select-pendente'
                                                                    : executadoSelecionadoOpt[index]?.value === ''
                                                                        ? 'select-exec'
                                                                        : 'select-exec select-executado'
                                                                : (el.codigo_1)
                                                                    ? 'select-exec'
                                                                    : 'some'
                                                        }
                                                        onChange={(e) => handleChangeExecutado(e, index)}
                                                    />
                                                    : <span>Sem terapeuta...</span>
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-12'} className='assinado m-0'>
                                                {el?.exige_lista_presenca
                                                    ? <label className='check-label'>
                                                        <SimpleInput type='checkbox' name={'assinado' + index} id={'assinado' + index} onChange={(e) => handleChangeAssinado(e, index)} checked={el.assinado} />
                                                        <span className='checkmark'></span>
                                                    </label>
                                                    : ''
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-11'} className='status_atendimento'>
                                                {statusAtendimentosOptions.find(option => Number(option.value) === Number(el.sugestao_recepcao))?.label}
                                            </td>
                                            <td key={'row-' + index + '-ce-col-9'} className='liberado'>
                                                {opcoesLiberado.find(option => String(option.value) === String(el.liberado))?.label}
                                            </td>
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    </table>
                    <div className='row m-0 p-0'>
                        <div className='col-lg-8 col-md-12'>
                            <ModalTitulo className='col-4 text-center'>Conferir Carterinhas dos Planos</ModalTitulo>
                            <table className='table-folha-horarios'>
                                <thead>
                                    <tr>
                                        <th style={{ maxHeight: '15px', padding: '5px 10px', width: '200px' }}>Nº</th>
                                        <th style={{ maxHeight: '15px', padding: '5px 10px' }}>Plano</th>
                                        <th style={{ maxHeight: '15px', padding: '5px 10px' }}>Criado</th>
                                        <th style={{ maxHeight: '15px', padding: '5px 10px' }}>Ultima Edição</th>
                                        <th style={{ maxHeight: '15px', padding: '5px 10px' }}>Verificado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {carterinhas && carterinhas.map((item, index) => {
                                        return (
                                            <tr key={`carterinha-${index}`} className='table-check-in-linha'>
                                                <td style={{ width: '200px' }}>
                                                    <SimpleInput id={`numero_carterinha${item.id}${item.created_at === false ? 'new' : ''}`} containerStyle={{ width: '200px' }} style={{ width: '200px' }} name={`numero_carterinha${item.id}${item.created_at === false ? 'new' : ''}`} className='m-0' defaultValue={item.numero_carterinha} placeholder='Digite o número da carterinha.' required />
                                                </td>
                                                <td>{item?.plano_saude?.nome_reduzido}</td>
                                                <td>{item.created_at ? moment.utc(item.created_at).format('DD/MM/YYYY HH:mm') : 'Sem Carterinha'}</td>
                                                <td>{item.created_at ? moment.utc(item.updated_at).format('DD/MM/YYYY HH:mm') : 'Sem Carterinha'}</td>
                                                <td>
                                                    <div className='form-check d-inline-flex ms-4'>
                                                        <SimpleInput type='checkbox' className={'form-check-input'} name={`conferido_carterinha_${item.id}${item.created_at === false && '_new'}`} id={`conferido_carterinha_${item.id}${item.created_at === false && '_new'}`} defaultChecked={item.ultima_conferencia ? moment.utc(item.ultima_conferencia).isSame(moment(), 'day') : false} required />
                                                        <label className='form-check-label ms-3' htmlFor={`conferido_carterinha_${item.id}${item.created_at === false && '_new'}`}>
                                                            Conferido
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='col-lg-4 col-md-12 d-flex justify-content-end align-items-end pe-4'>
                            <button className='btn-padrao btn-executar' type='submit'>
                                Salvar
                                <svg className='bi bi-floppy-fill ms-2' xmlns='http://www.w3.org/2000/svg' width='25' height='25' fill='currentColor' viewBox='0 0 16 16'>
                                    <path d='M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z' />
                                    <path d='M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z' />
                                </svg>
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </ReactModal>
    );
});

ControleDeExecucaoModal.displayName = 'ControleDeExecucaoModal';

export default ControleDeExecucaoModal;