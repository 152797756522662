import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0;

@media only screen and (min-width: 860px) {
    margin: 0 auto;
  }
`;

export const ContainerSub = styled.div`
    @media only screen and (max-width: 859px) {
        display: grid;
        padding: 0px 2em; 
        grid-template-columns: 70% 30%;
        grid-template-areas: "header header" "mostradores mostradores" "recados acesso";
    }

    @media only screen and (min-width: 860px) {
        display: grid;
        padding: 0px 2em; 
        grid-gap: 20px;
        grid-template-columns: 70% 30%;
        grid-template-areas: "header header" "mostradores mostradores" "recados acesso";
    }
`;
export const Header = styled.div` 
    padding: 17px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    label:first-child {
        color: #4906AB;
        font-size: 22px;
        font-weight: 800;
    }
    label:last-child {
        color: #4906AB;
        font-size: 20px;
    }
`;

export const GridHeader = styled.div` 
    grid-area: header;
    margin: 0;
    padding: 0;
`;

export const GridAcesso = styled.div` 
    grid-area: acesso; 
    margin: 0;
    padding: 0;
    height: fit-content;
`;

export const GridRecados = styled.div` 
    grid-area: recados;
    margin: 0;
    padding: 0;
`;
export const AcessoRapido = styled.div` 
    height: 300px;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    width: 50%;
    box-shadow: 0 0 10px 0px silver;
    background-color: white;
    border-radius: 20px;
    margin: 10px;
`;

export const BotaoAcessoRapido = styled.button`
    height: 80px;
    background-color: #6A3BAF;
    cursor: pointer;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: white;
    margin: 5px;
    width: 110px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    span {
        margin-top: 5px;
        font-size: 14px;
        cursor: pointer;
    }
`;

export const LinhaAcessoRapido = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const HeaderAcessoRapido = styled.div`
    margin-bottom: 15px;
    margin-top: 0px;
    text-align: center;
    font-weight: 800;
    font-size: 18px;
    color: #4906AB;
`;