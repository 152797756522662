import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableDefaultPagination } from '../../../../components/TableDefaultPagination';
import { BsEye, BsEyeglasses, BsPencil } from 'react-icons/bs';
import AgendaPacienteModal from '../../../../components/Modais/Pacientes/AgendaModal';
import ContatosModal from '../../../../components/Modais/Pacientes/ContatosModal';
import { FaRegAddressBook } from 'react-icons/fa';
import { AiOutlineCalendar } from 'react-icons/ai';
import DadosBasicosModal from '../../../../components/Modais/Pacientes/DadosBasicosModal';
import DadosComplementaresModal from '../../../../components/Modais/Pacientes/DadosComplementaresModal';
import ProntuarioModal from '../../../../components/Modais/Pacientes/ProntuarioModal';

export function TablePaciente({ source, pacienteIdentList, permissoes, user, createButton }) {
    //#region VARIAVEIS
    const navigate = useNavigate();
    const agendaModalRef = useRef();
    const contatosModalRef = useRef();
    const dadosBasicosModalRef = useRef();
    const dadosComplementaresModalRef = useRef();
    const prontuarioModalRef = useRef();
    const [selectedPacienteId, setSelectedPacienteId] = useState(null);
    //#endregion
    //#region HANDLES
    const handleOpenProntuarioModal = function (id) {
        if (prontuarioModalRef.current) {
            prontuarioModalRef.current.openModal(id);
        }
    };
    const handleOpenContatosModal = function (id) {
        if (contatosModalRef.current) {
            contatosModalRef.current.openModal(id);
        }
    };
    const handleOpenModalAgenda = function (id) {
        setSelectedPacienteId(id);
        if (agendaModalRef.current) {
            agendaModalRef.current.openModal(id);
        }
    };
    const handleOpenDadosBaiscos = function (id) {
        setSelectedPacienteId(id);
        if (dadosBasicosModalRef.current) {
            dadosBasicosModalRef.current.openModal(id);
        }
    };
    const handleOpenDadosComplementares = function (id) {
        setSelectedPacienteId(id);
        if (dadosComplementaresModalRef.current) {
            dadosComplementaresModalRef.current.openModal(id);
        }
    };
    //#endregion
    //#region COLUNAS DA TABELA
    const columns = useMemo(
        () => [
            {
                Header: 'Identificador:',
                accessor: 'identificador',
                filterable: true,
            },
            {
                Header: 'Nome:',
                accessor: 'nome',
                filterable: true,
            },
            {
                Header: 'Responsável:',
                accessor: 'responsaveis',
                filterAccessor: 'responsavel',
                filterValue: 'nome',
                filterLabel: 'nome',
                Cell: ({ cell }) => {
                    const paciente_id = cell?.row?.original?.id;
                    const responsaveis = cell?.row?.original?.responsavel;
                    if (responsaveis?.length > 0) {
                        return responsaveis?.map((responsavel, index) => {
                            return (
                                <div key={`pac${paciente_id}|res${index}`}>
                                    {(responsavel.nome && responsavel.nome.length && responsavel.nome.length > 20) ? `${responsavel.nome.substring(0, 20)}...` : responsavel.nome}
                                </div>
                            );
                        });
                    } else {
                        return 'Sem responsável';
                    }
                },
                filterable: false,
            },
            {
                Header: 'Status:',
                accessor: 'status',
                filterable: true,
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                Cell: ({ cell }) => (
                    (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}>
                        {
                            permissoes['edição'] &&
                            <button className="btn-icone" onClick={() => navigate(`/dashboard/${user.tipo_user.toLowerCase()}/pacientes/perfil/` + cell.row.original.id, { state: { pacienteIdentList } })}>
                                <BsPencil></BsPencil>
                            </button>
                        }
                        {
                            permissoes['dados_básicos'] &&
                            <button className="btn-icone" title='Visualizar dados básicos.' onClick={() => handleOpenDadosBaiscos(cell.row.original.id)}>
                                <BsEye size={22} />
                            </button>
                        }
                        {
                            permissoes['agenda'] &&
                            <button className="btn-icone" title='Acessar agenda.' onClick={() => handleOpenModalAgenda(cell.row.original.id)}>
                                <AiOutlineCalendar size={22} />
                            </button>
                        }
                        {
                            permissoes['dados_complementares'] &&
                            <button className="btn-icone" title='Visualizar dados complementares.' onClick={() => handleOpenDadosComplementares(cell.row.original.id)}>
                                <BsEyeglasses size={22} />
                            </button>
                        }
                        {
                            permissoes['contatos'] &&
                            <button className="btn-icone" title='Visualizar lista de contatos.' onClick={() => handleOpenContatosModal(cell.row.original.id)}>
                                <FaRegAddressBook size={22} />
                            </button>
                        }
                        {
                            permissoes['prontuário'] &&
                            <button className="btn-icone" title='Visualizar prontuário.' onClick={() => handleOpenProntuarioModal(cell.row.original.id)}>
                                Prontuário
                            </button>
                        }
                    </div>
                    )
                )
            }
        ], [source]
    );
    //#endregion
    return (
        <>
            <TableDefaultPagination columns={columns} source={source} searchBar={true} createButton={createButton} />
            <AgendaPacienteModal ref={agendaModalRef} paciente_id={selectedPacienteId} />
            <ContatosModal ref={contatosModalRef} paciente_id={selectedPacienteId} />
            <DadosBasicosModal ref={dadosBasicosModalRef} paciente_id={selectedPacienteId} />
            <DadosComplementaresModal ref={dadosComplementaresModalRef} paciente_id={selectedPacienteId} />
            <ProntuarioModal ref={prontuarioModalRef} paciente_id={selectedPacienteId} />
        </>
    );
}