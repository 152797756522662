export const CustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.innerWidth <= 700 ? '100%' : '60vw',
        height: '70vh',
        zIndex: '1200',
        paddingTop: 0
    },
};