//#region IMPORTAÇÕES
import * as React from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { CelulaCabecalho, LinhaTabela } from './styles';
import VirtualizedTable from 'components/Virtualized/virtualized-table';
//#endregion

const VirtualizedTableDefault = ({
    rowHeight,
    rows,
    containerHeight,
    prefix_id,
    headerGroups,
    prepareRow,
    hoverLineMark,
}) => {
    //#region VARIAVEIS
    // Estados para posição de rolagem e linhas visíveis
    const [scrollTop, setScrollTop] = React.useState(0);
    const containerRef = React.useRef(null);

    const totalHeight = rows.length * rowHeight;
    const visibleRowCount = Math.ceil(containerHeight / rowHeight) + 1;

    // Calcule o índice das linhas visíveis baseado na rolagem atual
    const startIndex = Math.max(0, Math.floor(scrollTop / rowHeight));
    const endIndex = Math.min(rows.length - 1, startIndex + visibleRowCount);
    //#endregion

    //#region HANDLES
    /**
     * Manipulador de evento de rolagem que atualiza o estado `scrollTop` com a posição de rolagem atual do contêiner.
     * 
     * @function handleScroll
     * @returns {void}
     */
    const handleScroll = React.useCallback(() => {
        setScrollTop(containerRef.current.scrollTop);
    }, []);
    //#endregion

    //#region USE EFFECTS
    React.useEffect(() => {
        const ref = containerRef.current;
        if (ref) {
            ref.addEventListener("scroll", handleScroll);
            return () => ref.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);
    //#endregion

    return (
        <VirtualizedTable
            tableHeadClassName="TableListaEspera w-100"
            tableFootClassName="TableListaEsperaBody"
            rows={rows}
            rowMap={({ row, key, styles }) => {
                prepareRow(row);
                return (
                    <tr key={key} style={styles} className={hoverLineMark ? 'line-hover' : ''}>
                        {row.cells.map((cell, index) => (
                            <LinhaTabela key={index} id={`${prefix_id}-col-${key}-row-${index}`}
                                style={{
                                    height: '14px',
                                    textAlign: 'center',
                                }}>
                                {cell.render('Cell')}
                            </LinhaTabela>
                        ))}
                    </tr>
                );
            }}
            rowHeight={rowHeight}
            containerHeight={containerHeight}
            tableHeader={headerGroups.map(headerGroup => (
                <tr>
                    {headerGroup.headers.map((column, index) => (
                        <CelulaCabecalho key={index} id={`${prefix_id}-col-${index}`}>
                            <div className='position-relative d-flex justify-content-between align-items-center'>
                                <span>
                                    {column.render('Header')}
                                </span>
                            </div>
                        </CelulaCabecalho>
                    ))}
                </tr>
            ))}
            tableFoot={null}
        />
    );
}

export default VirtualizedTableDefault;
