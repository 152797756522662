
import React, { useState, useEffect, useCallback } from 'react';
import { AiFillCaretDown, AiOutlineClear } from 'react-icons/ai';
import { FilterStyle, DivContentDisplay } from './styles';
import SelectFilter from '../../Select';
import { Form } from '@unform/web';
import Input from '../../Input';
export default function FiltroPaciente({ resultRef, list, filtro_identificador = true, filtro_nome = true, filtro_responsavel = true, filtro_status = true, filtro_nome_do_paciente = true, firstFilterId = null }) {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    // State variables for lists
    const [originalList, setOriginalList] = useState();
    const [paciente, setPaciente] = useState([]);
    const [responsaveis, setResponsaveis] = useState([]);
    const [identificadores, setIdentificadores] = useState([]);
    const [status, setStatus] = useState([]);

    // State variables for selected options
    const [statusSelecionado, setStatusSelecionado] = useState(null);
    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
    const [responsavelSelecionado, setResponsavelSelecionado] = useState(null);
    const [identificadorSelecionado, setIdentificadorSelecionado] = useState(null);

    // State variables for selected option objects
    const [identificadorSelecionadoOpt, setIdentificadorSelecionadoOpt] = useState(null);
    const [statusSelecionadoOpt, setStatusSelecionadoOpt] = useState(null);
    const [pacienteSelecionadoOpt, setPacienteSelecionadoOpt] = useState(null);
    const [responsavelSelecionadoOpt, setResponsavelSelecionadoOpt] = useState(null);

    // State variable for filter value
    const [filterValue, setFilter] = useState(null);

    // State variable for expand/collapse filter
    const [expand, setExpand] = useState(false);
    //#endregion
    //#region FUNCOES
    const handleChangePacienteSearch = () => {
        setPacienteSelecionado(null);
        setPacienteSelecionadoOpt(null);
        setResponsavelSelecionado(null);
        setResponsavelSelecionadoOpt(null);
        setIdentificadorSelecionado(null);
        setIdentificadorSelecionadoOpt(null);
    };
    const getPacientes = () => {
        let data = list;
        if (data !== null) {
            let pacientes = [];
            let identificadores = [];
            let responsaveis = [];
            let status = [];
            data.forEach((p) => {
                if (identificadores.find((e) => e.value === p.identificador) === undefined) {
                    identificadores.push({ value: p.identificador, label: p.identificador });
                }
                if (p.responsavel !== undefined && p.responsavel !== null && p.responsavel.length > 0) {
                    if (responsaveis.find((e) => e.value === p.responsavel[0].id) === undefined) {
                        responsaveis.push({ value: p.responsavel[0].id, label: p.responsavel[0].nome });
                    }
                }
                if (status.find((e) => e.value === p.status) === undefined) {
                    status.push({ value: p.status, label: p.status });
                }
                let paciente = { value: p.id, label: p.nome };
                pacientes.push(paciente);
                if (firstFilterId !== null && firstFilterId == p.id) {
                    setPacienteSelecionado(p.id);
                    setPacienteSelecionadoOpt(paciente);
                }
            });
            setIdentificadores(identificadores);
            setResponsaveis(responsaveis);
            setStatus(status);
            setPaciente(pacientes);
        }
    };
    const expandFiltro = () => {
        let text = document.getElementById('blank');
        setExpand(!expand);
        if (expand === false) {
            text.innerHTML = 'Fechar Filtro';
        } else {
            text.innerHTML = 'Expandir Filtro';
        }
    };
    const filter = () => {
        if (originalList !== null && originalList !== undefined) {
            let result = originalList.filter((value) => {
                let status, paciente, responsavel, identificadores = false;
                if (statusSelecionado === null) {
                    status = true;
                } else {
                    if (statusSelecionado == value.status) {
                        status = true;
                    } else {
                        status = false;
                    }
                }
                if (pacienteSelecionado === null) {
                    paciente = true;
                } else {
                    if (pacienteSelecionado == value.id) {
                        paciente = true;
                    } else {
                        paciente = false;
                    }
                }
                if (responsavelSelecionado === null) {
                    responsavel = true;
                } else {
                    if (value.responsavels !== undefined && value.responsavels !== null && value.responsavels.length > 0) {
                        if (responsavelSelecionado == value.responsavels[0].id) {
                            responsavel = true;
                        } else {
                            responsavel = false;
                        }
                    }
                }
                if (identificadorSelecionado === null) {
                    identificadores = true;
                } else {
                    if (identificadorSelecionado === value.identificador) {
                        identificadores = true;
                    } else {
                        identificadores = false;
                    }
                }
                return status && paciente && responsavel && identificadores;
            });
            if (filterValue === null || filterValue === '') {
                resultRef(result);
            } else {
                resultRef(result.filter((item) => item.nome.toString().toLowerCase().includes(filterValue.toString().toLowerCase())));
            }
        }
    };
    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            expandFiltro();
        }
    }, []);
    //#endregion

    //#region USE EFFECTs
    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction]);
    useEffect(() => {
        filter();
    }, [statusSelecionado, pacienteSelecionado, identificadorSelecionado, responsavelSelecionado]);
    useEffect(() => {
        getPacientes();
        setOriginalList(list);
    }, [list]);
    useEffect(() => {
        filter();
    }, [filterValue]);
    //#endregion
    //#region HTML
    return (
        <Form style={FilterStyle}>
            <div className='w-100per pointer' onClick={() => expandFiltro()}>
                <div style={{ display: 'flex' }}>
                    <p id='blank' style={{ marginBottom: -30 }}>Expandir Filtro</p>
                </div>
                <div style={{ cursor: 'pointer', marginLeft: '96%' }}>
                    <AiFillCaretDown color='white' size={22} />
                </div>
            </div>
            {expand &&
                <div className='div-expand-over' style={{ marginTop: 0, marginLeft: -16 }}>
                    <div style={DivContentDisplay} className='row'>
                        {filtro_identificador &&
                            <div className={filtro_nome === false ? 'col-12' : 'col-6'}>
                                <label className='label-filtro'>Identificador:</label>
                                <div className='d-flex justify-around align-center'>
                                    <SelectFilter name={'identificador'} value={identificadorSelecionadoOpt} options={identificadores} onChange={(e) => {
                                        setIdentificadorSelecionado(e.value);
                                        setIdentificadorSelecionadoOpt({ value: e.value, label: e.label });

                                        setResponsavelSelecionadoOpt(null);
                                        setResponsavelSelecionado(null);
                                        setPacienteSelecionadoOpt(null);
                                        setPacienteSelecionado(null);
                                        setStatusSelecionadoOpt(null);
                                        setStatusSelecionado(null);
                                    }} />
                                    <AiOutlineClear className='pointer m-2' onClick={() => { setIdentificadorSelecionado(null); setIdentificadorSelecionadoOpt(null); }} size={25} />
                                </div>
                            </div>
                        }
                        {filtro_nome &&
                            <div className={filtro_identificador === false ? 'col-12' : 'col-6'}>
                                <label className='label-filtro'>Nome:</label>
                                <div className='d-flex justify-around align-center'>
                                    <SelectFilter name={'paciente'} options={paciente} value={pacienteSelecionadoOpt} onChange={(e) => {
                                        setPacienteSelecionado(e.value);
                                        setPacienteSelecionadoOpt({ value: e.value, label: e.label });

                                        setIdentificadorSelecionado(null);
                                        setIdentificadorSelecionadoOpt(null);
                                        setResponsavelSelecionadoOpt(null);
                                        setResponsavelSelecionado(null);
                                        setStatusSelecionadoOpt(null);
                                        setStatusSelecionado(null);
                                    }} />
                                    <AiOutlineClear className='pointer m-2' onClick={() => { setPacienteSelecionado(null); setPacienteSelecionadoOpt(null); }} size={25} />
                                </div>
                            </div>
                        }
                    </div>
                    <div style={DivContentDisplay} className='row'>
                        {filtro_responsavel &&
                            <div className={filtro_status === false ? 'col-12' : 'col-6'}>
                                <label className='label-filtro'>Responsavel:</label>
                                <div className='d-flex justify-around align-center'>
                                    <SelectFilter name='status' options={responsaveis} value={responsavelSelecionadoOpt} onChange={(e) => {
                                        setResponsavelSelecionado(e.value);
                                        setResponsavelSelecionadoOpt({ value: e.value, label: e.label });

                                        setIdentificadorSelecionado(null);
                                        setIdentificadorSelecionadoOpt(null);
                                        setPacienteSelecionadoOpt(null);
                                        setPacienteSelecionado(null);
                                        setStatusSelecionadoOpt(null);
                                        setStatusSelecionado(null);
                                    }} />
                                    <AiOutlineClear className='pointer m-2' onClick={() => { setResponsavelSelecionado(null); setResponsavelSelecionadoOpt(null); }} size={25} />
                                </div>
                            </div>
                        }
                        {filtro_status &&
                            <div className={filtro_responsavel === false ? 'col-12' : 'col-6'}>
                                <label className='label-filtro'>Status:</label>
                                <div className='d-flex justify-around align-center'>
                                    <SelectFilter name='status' options={status} value={statusSelecionadoOpt} onChange={(e) => {
                                        setStatusSelecionado(e.value);
                                        setStatusSelecionadoOpt({ value: e.value, label: e.label });

                                        setIdentificadorSelecionado(null);
                                        setIdentificadorSelecionadoOpt(null);
                                        setResponsavelSelecionadoOpt(null);
                                        setResponsavelSelecionado(null);
                                        setPacienteSelecionadoOpt(null);
                                        setPacienteSelecionado(null);
                                    }} />
                                    <AiOutlineClear className='pointer m-2' onClick={() => { setStatusSelecionado(null); setStatusSelecionadoOpt(null); }} size={25} />
                                </div>
                            </div>
                        }
                    </div>
                    {filtro_nome_do_paciente &&
                        <div className='row'>
                            <div className='col-12 pt-4'>
                                <label className='label-filtro'>Nome do Paciente:</label>
                                <div className='d-flex justify-around align-center pr-25px w-100'>
                                    <Input upperContainerClassName='w-100 mt-0 pt-0' placeholder={'Pesquisar Paciente por Nome:'} name={'nome'} value={filterValue || ''} onChange={e => { handleChangePacienteSearch(); setFilter(e.target.value); }} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </Form>
    );
    //#endregion
}