import api from './api';
import moment from 'moment/moment';
import AtendimentoAlteracaoService from './AtendimentoAlteracaoService';

/**
 * Classe de serviço para manipulação de atendimentos.
 */
class AtendimentoService {
  /**
   * Recupera a lista de atendimentos com base nos parâmetros fornecidos.
   *
   * @param {string} params - Parâmetros de consulta para filtrar os atendimentos.
   * @returns {Promise} - Promessa que resolve com a resposta da API contendo a lista de atendimentos.
   */
  static index = (params) => {
    return api.get(`api/atendimento?${params}`);
  };
  /**
   * Armazena um objeto de atendimento no servidor.
   *
   * @param {Object} obj - O objeto de atendimento a ser armazenado.
   * @returns {Promise} - Uma promessa que resolve a resposta da requisição.
   */
  static store = (obj) => {
    return api.post('api/atendimento', obj);
  };
  /**
   * Obtém os detalhes de um atendimento específico pelo ID.
   *
   * @param {number|string} id - O ID do atendimento a ser recuperado.
   * @returns {Promise} - Uma promessa que resolve com os dados do atendimento.
   */
  static show = (id) => {
    return api.get(`api/atendimento/${id}`);
  };
  /**
   * Atualiza um atendimento existente.
   *
   * @param {Object} obj - Objeto contendo os dados atualizados do atendimento.
   * @param {number|string} id - ID do atendimento a ser atualizado.
   * @returns {Promise} - Retorna uma promessa que resolve com a resposta da API.
   */
  static update = (obj, id) => {
    return api.put(`api/atendimento/${id}`, obj);
  };
  /**
   * Exclui um atendimento pelo ID.
   *
   * @param {number|string} id - O ID do atendimento a ser excluído.
   * @returns {Promise} - Uma promessa que resolve a resposta da API.
   */
  static destroy = (id) => {
    return api.delete(`api/atendimento/${id}`);
  };
  /**
   * Obtém o horário atualizado do atendimento.
   *
   * @param {Object} atendimento - O objeto de atendimento.
   * @param {boolean} atendimento.is_ultima_aprovada - Indica se o atendimento é a última versão aprovada.
   * @param {string} atendimento.data_atendimento_inicial - A data e hora inicial do atendimento no formato ISO.
   * @param {Array} atendimento.atendimento_alteracaos - Lista de alterações do atendimento.
   * @returns {string} O horário atualizado no formato 'HH:mm'.
   */
  static getHorarioAtualizado(atendimento) {
    if (!atendimento.is_ultima_aprovada) {
      return moment(atendimento.data_atendimento_inicial.split('T')[1], 'HH:mm:ss').format('HH:mm');
    }
    const ultimaAlteracao = AtendimentoAlteracaoService.getUltimaAlteracao(atendimento.atendimento_alteracaos);
    return moment(ultimaAlteracao.data_atendimento_inicial.split('T')[1], 'HH:mm:ss').format('HH:mm');
  }
  /**
   * Retorna o status selecionado a partir de uma lista de status e um valor específico.
   *
   * @param {Array} statusList - Lista de objetos de status.
   * @param {string} value - Valor do status a ser selecionado.
   * @returns {Object|string} - Objeto de status correspondente ao valor fornecido ou uma string vazia se não encontrado.
   */
  static getStatusSelecionadoByValue(statusList, value) {
    if (!statusList || statusList.length < 1 || statusList === undefined || statusList === null) return;
    return statusList.filter(status => status.value === value)[0] || '';
  }
  /**
   * Obtém os dados de atendimento pelo ID do controle diário.
   *
   * @param {number} id - O ID do controle diário.
   * @param {string} [withs] - Parâmetros adicionais para incluir relacionamentos.
   * @param {string} [listas] - Listas de entidades relacionadas a serem incluídas na resposta.
   * @param {string} [extraSelects] - Seleções extras de campos específicos.
   * @param {string} [filters] - Filtros adicionais para a consulta.
   * @returns {Promise<Object>} - Uma promessa que resolve com os dados do atendimento.
   */
  static async getByControleDiarioId(id, withs, listas, extraSelects, filters) {
    const listasParam = listas !== undefined ? listas : 'solicitacao_de_alteracaos,guia,paciente.terapias_paciente';
    const extraSelectsParam = extraSelects !== undefined ? extraSelects : 'terapia_paciente.plano_saude.id';
    let url = `api/atendimento/getByControleDiario/${id}?id=${id}`;
    if (listasParam) url += `&listas=${listasParam}`;
    if (extraSelectsParam) url += `&extraSelect=${extraSelectsParam}`;
    if (withs) url += `&with=${withs}`;
    if (filters) url += `&filtro=${filters}`;
    return api.get(url);
  }
  /**
   * Retorna uma lista de opções para atendimento liberado.
   *
   * @returns {Array<{value: number, label: string}>} Lista de opções com valor e rótulo.
   */
  static getOpcoesAtendimentoLiberado() {
    return [
      { value: 1, label: 'Sim' },
      { value: 0, label: 'Não' },
    ];
  }
  /**
   * Retorna a opção de atendimento liberado correspondente ao valor fornecido.
   *
   * @param {string} value - O valor da opção de atendimento liberado a ser buscada.
   * @returns {Object|string} - A opção de atendimento liberado correspondente ao valor fornecido, ou uma string vazia se não for encontrada.
   */
  static getOpcaoAtendimentoLiberadoByValue(value) {
    return this.getOpcoesAtendimentoLiberado().filter(status => status.value === value)[0] || '';
  }
  /**
   * Obtém as alterações pelo ID do controle diário.
   *
   * @param {number} id - O ID do controle diário.
   * @returns {Promise} - Uma promessa que resolve com os dados das alterações.
   */
  static getAlteracoesByControleDiarioId(id, apenasPendentes) {
    return api.get(`api/atendimento/getAlteracoesByControleDiario/${id}` + (apenasPendentes ? '?apenasPendentes=true' : ''));
  }
  /**
   * Confere a validade dos atendimentos para um controle diário específico.
   *
   * @param {number} controleDiarioId - O ID do controle diário a ser verificado.
   * @param {boolean} [verifica_conflitos=true] - Indica se deve verificar conflitos nos atendimentos.
   * @returns {Promise} - Uma promessa que resolve com a resposta da API.
   */
  static confereValidadeAtendimentos(controleDiarioId, verifica_conflitos = true) {
    return api.post(`api/controle-diario/confere/validade/atendimentos/${controleDiarioId}`, { verifica_conflitos });
  }
}

export default AtendimentoService;
