import React from 'react';
import { useAuth } from '../../context/auth';

const UsuarioLogado = () => {

  const { user } = useAuth();
  
  return (
    <label>Olá, {user?.user?.tipo_user === 'user' ? user?.user?.user[0]?.nome : user?.user?.tipo_user === 'responsavel' ?
      user?.user?.responsavels[0]?.nome : user?.user?.name}</label>
  );
};

export default UsuarioLogado;
