//#region IMPORTAÇÕES
import React, { createContext, useContext, useEffect, useState } from 'react';
//#endregion

const PageBaseContext = createContext({});

/**
 * Provedor de contexto para a página base.
 *
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {React.ReactNode} props.children - Elementos filhos a serem renderizados dentro do provedor.
 *
 * @returns {JSX.Element} Provedor de contexto para a página base.
 *
 * @example
 * <PageBaseProvider>
 *   <YourComponent />
 * </PageBaseProvider>
 */
const PageBaseProvider = ({ children }) => {
    //#region VARIAVEIS
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [routes, setRoutes] = useState('');
    const [hasSubNavbar, setHasSubNavbar] = useState(false);
    const [hasTitle, setHasTitle] = useState(false);
    //#endregion

    //#region FUNCOES
    const toggleSubNavbar = () => {
        setHasSubNavbar(!hasSubNavbar);
    };

    const notifySubNavbar = () => {
        alert('Subnavbar is not implemented yet');
    };
    //#endregion

    //#region RETURN
    return (
        <PageBaseContext.Provider
            value={{
                title,
                setTitle,
                description,
                setDescription,
                hasSubNavbar,
                setHasSubNavbar,
                routes,
                setRoutes,
                toggleSubNavbar,
                notifySubNavbar,
                hasTitle,
                setHasTitle
            }}
        >
            {children}
        </PageBaseContext.Provider>
    );
    //#endregion
};

//#region USE
/**
 * Hook personalizado para gerenciar o contexto da página base.
 *
 * @param {Object} params - Parâmetros para configurar a página base.
 * @param {string} [params.title=''] - Título da página.
 * @param {string} [params.description=''] - Descrição da página.
 * @param {boolean} [params.hasTitle=true] - Indica se a página deve exibir o título.
 * @param {boolean} [params.hasSubMenu=false] - Indica se a página deve exibir um submenu.
 * @param {Array|string} [params.routes=[]] - Rotas associadas à página.
 * @param {boolean} [params.conditionalPageBase] - Condição para aplicar as configurações da página base.
 *
 * @returns {Object} O contexto da página base.
 *
 * @throws {Error} Se o hook for usado fora de um PageBaseProvider.
 */
const usePageBase = ({ title = '', description = '', hasTitle = true, hasSubMenu = false, routes = [] || '', conditionalPageBase } = {}) => {
    //#region VARIAVEIS
    const context = useContext(PageBaseContext);
    //#endregion

    //#region TESTE
    if (context === undefined) {
        throw new Error('usePageBase must be used within a PageBaseProvider');
    }
    //#endregion

    //#region DEFINIÇÕES
    useEffect(() => {
        if (conditionalPageBase === undefined || conditionalPageBase === true) {
            context.setTitle(title);
            context.setDescription(description);
            context.setHasTitle(hasTitle);
            context.setHasSubNavbar(hasSubMenu);
            context.setRoutes(routes);
        }
    }, [title, description, hasTitle, hasSubMenu, conditionalPageBase]);
    //#endregion

    //#region RETURN
    return context;
    //#endregion
};
//#endregion
//EXPORT
export { PageBaseProvider, usePageBase };
