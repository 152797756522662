import ModalTitulo from 'components/ModalTitulo';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import ReactModal from 'react-modal';
import { ClipLoader } from 'react-spinners';

// Create a context for the modal
const ModalContext = createContext();

// Create a provider component
export const ModalProvider = ({ children, navbarLeftContent }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState(null);
    const [title, setTitle] = useState('');
    const [width, setWidth] = useState('50%');
    const [height, setHeight] = useState('auto');
    const [algoCarregando, setAlgoCarregando] = useState(false);
    const [contentStyles, setContentStyles] = useState({
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        alignItems: 'stretch',
        padding: '0px',
        color: '#000',
        overflowY: 'auto',
        borderRadius: '10px',
        zIndex: 12,
    });
    const [overlayStyles, setOverlayStyles] = useState({
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 11,
    });
    const [navbarLeftContentState, setNavbarLeftContentState] = useState(navbarLeftContent);
    const customStyles = useMemo(() => ({
        overlay: {
            ...overlayStyles,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            ...contentStyles,
            width: width,
            height: height,
        },
    }), [width, height, contentStyles, overlayStyles]);

    const openModal = useCallback((modalContent) => {
        setContent(modalContent);
        setIsOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsOpen(false);
        setContent(null);
    }, []);

    const onClose = useCallback(() => {
        setIsOpen(false);
        setContent(null);
    }, []);

    const setNavbarLeftContent = useCallback((content) => {
        setNavbarLeftContentState(content);
    }, []);

    return (
        <ModalContext.Provider
            value={{
                isOpen,
                setIsOpen,
                content,
                openModal,
                closeModal,
                setTitle,
                setWidth,
                setHeight,
                setAlgoCarregando,
                setOverlayStyles,
                setContentStyles,
                title,
                setNavbarLeftContent
            }}>
            <ReactModal isOpen={isOpen} onRequestClose={closeModal} appElement={document.getElementById('root')} style={customStyles}>
                <nav className='navbar sticky-top bg-cinza-claro shadow m-0'>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div className='w-200px'>
                            {navbarLeftContentState !== false ? navbarLeftContentState : <ClipLoader size={22} color={'#000'} loading={algoCarregando} />}
                        </div>
                        <ModalTitulo>{title}</ModalTitulo>
                        <label className='btn-outline w-200px text-right' onClick={() => onClose()}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                {content}
            </ReactModal>
            {children}
        </ModalContext.Provider>
    );
};

// Create a custom hook to use the modal context
export const useModal = () => {
    return useContext(ModalContext);
};
