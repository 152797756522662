import Button from 'components/Button';
import Input, { RichTextEditor } from 'components/Input';
import Select from 'components/Select';
import PickList from 'components/PickList';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { BsEye } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import ReactModal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import api from 'services/api';
import Swal from 'sweetalert2';
import { Form } from '@unform/web';

const ModalNotificacao = forwardRef(({ modalData, getNotificacao }, ref) => {
    //#region VARIAVEIS
    const pickListRef = useRef();
    const formRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [loading, setloading] = useState(false);
    const [notificacao, setNotificacao] = useState({
        user_id: '',
        titulo: '',
        conteudo: '',
        nivel_urgencia: '',
        tipo: '',
        modo_envio: ''
    });
    const [usersList, setUsersList] = useState([]);
    const [alteredUsersList, setAlteredUsersList] = useState({ added: [], removed: [] });
    const defaultUsersList = [];

    const urgenciaOptions = useMemo(() => [
        { value: 'baixa', label: 'Baixa', name: 'nivel_urgencia' },
        { value: 'média', label: 'Média', name: 'nivel_urgencia' },
        { value: 'alta', label: 'Alta', name: 'nivel_urgencia' }
    ], []);

    const tipoOptions = useMemo(() => [
        { value: 'warning', label: 'Aviso', name: 'tipo' },
        { value: 'alert', label: 'Alerta', name: 'tipo' },
        { value: 'question', label: 'Questionamento', name: 'tipo' },
        { value: 'error', label: 'Erro', name: 'tipo' }
    ], []);

    const modoEnvioOptions = useMemo(() => [
        { value: 'sistema', label: 'Sistema', name: 'modo_envio' },
        { value: 'email', label: 'E-mail', name: 'modo_envio' },
    ], []);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: 'auto',
            maxHeight: '70vh',
            width: '85vw',
            marginRight: '-50%',
            paddingTop: '0px',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF'
        },
    };
    //#endregion

    //#region FUNÇÕES
    const previewNotificacao = useCallback(() => {
        const { conteudo, tipo } = notificacao;
        const titulo = document.getElementById('titulo').value;
        let option = {
            title: titulo,
            html: conteudo,
            icon: tipo,
            confirmButtonText: 'Visualizar',
            denyButtonText: 'Excluir',
            showConfirmButton: true,
            showDenyButton: true,
        };
        Swal.fire(option);
    }, [notificacao]);
    //#endregion

    //#region FUNÇÕES DE MODAL
    const openModal = useCallback(() => {
        setIsOpen(true);
        setloading(true);
        if (usersList.length === 0) {
            api.get('api/user')
                .then(({ data }) => {
                    let pickList = [];
                    data.forEach((user) => {
                        pickList.push({
                            value: user.id,
                            label: user.name,
                            secondLabel: user.tipo_user,
                            email: user.email,
                            tipo: user.tipo_user
                        });
                    });
                    setUsersList(pickList.sort((a, b) => {
                        if (a.tipo === b.tipo) {
                            return a.label.localeCompare(b.label);
                        } else {
                            return a.tipo.localeCompare(b.tipo);
                        }
                    }));
                    setloading(false);
                })
                .catch(error => console.error(error));
        }
    }, [usersList]);

    const closeModal = useCallback(() => {
        setNotificacao({
            user_id: '',
            titulo: '',
            conteudo: '',
            nivel_urgencia: '',
            tipo: '',
            modo_envio: ''
        });
        getNotificacao();
        setIsOpen(false);
    }, [getNotificacao]);

    useImperativeHandle(ref, () => {
        return {
            openModal,
            notificacao
        };
    }, [openModal, notificacao]);
    //#endregion

    //#region HANDLES
    const handleChange = useCallback((e) => {
        let name;
        let value;
        if (e.target) {
            name = e.target.name;
            value = e.target.value;
        } else {
            name = e.name;
            value = e.value;
        }
        setNotificacao((prevNotificacao) => ({
            ...prevNotificacao,
            [name]: value
        }));
    }, []);

    const handleSubmit = useCallback((data) => {
        try {
            const dados = {
                ...notificacao,
                titulo: data.titulo
            };
            const added = alteredUsersList.added;
            if (added.length === 0) {
                Swal.fire('Selecione ao menos um usuário para notificar...', '', 'warning');
                return;
            }
            const ids = added.map(user => user.value);

            const obj = {
                dados: dados,
                ids: ids
            };
            api.post('api/notificacao/notifica/user/ids', obj)
                .then((response) => {
                    if (response.status === 200) {
                        Swal.fire('Notificações enviadas com sucesso!', '', 'success', 2000);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    Swal.fire('Houve um problema a enviar as notificações...', '', 'error');
                });
        } catch (error) {
            console.error(error);
        } finally {
            closeModal();
        }
    }, [notificacao, alteredUsersList, closeModal]);
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (modalData) {
            setNotificacao(modalData);
        }
    }, [modalData]);
    //#endregion

    //#region HTML
    return (
        <div ref={ref}>
            <Button className='btn-padrao' onClick={() => openModal()}>Enviar Notificações</Button>
            <ReactModal style={customStyles} isOpen={isOpen} onRequestClose={() => closeModal()} shouldCloseOnEsc shouldCloseOnOverlayClick appElement={document.getElementById('root')}>
                <div className='div-titulo-fixo d-flex w-100' style={{ justifyContent: 'space-between', position: 'sticky' }}>
                    <label>
                        <BsEye color='purple' className='pointer' size={25} onClick={() => previewNotificacao()}></BsEye>
                    </label>
                    <label>Adicionar/Editar Notificação</label>
                    <label>
                        <CgClose color='red' className='pointer' size={25} onClick={() => closeModal()} />
                    </label>
                </div>
                {loading
                    ? <ClipLoader size={30} className='m-auto'></ClipLoader>
                    : <Form ref={formRef} onSubmit={handleSubmit} className='row pe-4 ps-4 pb-4 m-0' autoComplete="off">
                        <label className='col-12 mt-4'>
                            Título:
                            <Input type='text' id='titulo' name='titulo' defaultValue={notificacao.titulo} onChange={handleChange} />
                        </label>
                        <label className='col-12 mt-4'>
                            Conteúdo:
                            <RichTextEditor id='conteudo' name='conteudo' defaultValue={notificacao.conteudo} onChange={(e) => handleChange({ name: 'conteudo', value: e })} />
                        </label>
                        <label className='col-sm-12 col-lg-4 mt-4'>
                            Nível de Urgência:
                            <Select type='text' id='nivel_urgencia' name='nivel_urgencia' options={urgenciaOptions} defaultValue={notificacao.nivel_urgencia} onChange={handleChange} />
                        </label>
                        <label className='col-sm-12 col-lg-4 mt-4'>
                            Tipo:
                            <Select type='text' id='tipo' name='tipo' options={tipoOptions} defaultValue={notificacao.tipo} onChange={handleChange} />
                        </label>
                        <label className='col-sm-12 col-lg-4 mt-4'>
                            Modo de Envio:
                            <Select type='text' id='modo_envio' name='modo_envio' options={modoEnvioOptions} defaultValue={notificacao.modo_envio} onChange={handleChange} />
                        </label>
                        <div className={`col-12 flex-col mt-4${modalData ? ' some' : ''}`}>
                            <label>Usuário para notificar</label>
                            <PickList
                                ref={pickListRef}
                                todosDefault={usersList}
                                selecionadosDefault={defaultUsersList}
                                onSelectedChange={setAlteredUsersList}
                            />
                        </div>
                        <div className='d-flex justify-between'>
                            <Button className='w-300px' type='button' onClick={() => closeModal()}>Cancelar</Button>
                            <Button className='w-300px' type='submit'>Salvar</Button>
                        </div>
                    </Form>
                }
            </ReactModal>
        </div>
    );
    //#endregion
});

export { ModalNotificacao };