import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import ReactModal from 'react-modal';
import 'bootstrap/dist/css/bootstrap.min.css';

/**
 * Componente de modal para exibir conflitos.
 * 
 * @param {Object} props - As propriedades do componente.
 * @param {Object} ref - Referência para manipulação do componente.
 * @returns {JSX.Element} O componente de modal com os conflitos.
 */
const Conflitos = forwardRef((props, ref) => {
    //#region VARIAVEIS
    const [isOpen, setIsOpen] = useState(false);
    const [modalPendenciasDados, setModalPendenciasDados] = useState([]);
    // Estilos customizados para o modal
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: '70vh',
            width: '30vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#F5F7FA'
        },
    };
    //#endregion
    //#region FUNCOES
    /**
     * Abre o modal e define os dados das pendências.
     * 
     * @param {Object} dados - Dados das pendências.
     */
    const openModal = useCallback((dados) => {
        setModalPendenciasDados(dados);
        setIsOpen(true);
    });
    /**
     * Fecha o modal e limpa os dados das pendências.
     */
    const closeModal = useCallback(() => {
        setModalPendenciasDados(null);
        setIsOpen(false);
    }, []);
    /**
     * Função chamada após o modal ser aberto.
     */
    const afterOpenModal = () => {
        // Função para ações após a abertura do modal, se necessário
    };
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    //#region HTML
    return (
        <ReactModal title={modalPendenciasDados?.id} onRequestClose={(e) => closeModal()} afterOpenModal={afterOpenModal} style={customStyles} isOpen={isOpen} appElement={document.getElementById('root')} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} transparent>
            <div className='div-titulo d-flex justify-content-between align-center w-100 flex-wrap'>
                <p></p>
                <label>Erros e Conflitos do Atendimento {modalPendenciasDados?.id}</label>
                <CgClose className='icone-padrao' color='red' size={22} onClick={() => closeModal()}></CgClose>
            </div>
            <div className="accordion" id="accordionExample">
                {modalPendenciasDados?.horario_sala && (
                    <div className="accordion-item">
                        <h2 className="accordion-header collapsed bg-laranja-i font-branca" id="headingOne">
                            <button className="accordion-button bg-laranja-i collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Conflito de sala
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Já existe um atendimento no mesmo horário e sala que esse atendimento.
                            </div>
                        </div>
                    </div>
                )}

                {modalPendenciasDados?.executado_nao_realizado && (
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEleven">
                            <button className="accordion-button bg-laranja-i font-branca collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                Executado, mas não Realizado
                            </button>
                        </h2>
                        <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Esse conflito acontece quando o atendimento foi executado, mas não esta marcado como Realizado.
                            </div>
                        </div>
                    </div>
                )}
                {modalPendenciasDados?.horario_terapeuta && (
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed bg-laranja-i font-branca" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Conflito de terapeuta
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Já existem um atendimento com o mesmo terapeuta e no mesmo horário que esse atendimento.
                            </div>
                        </div>
                    </div>
                )}
                {modalPendenciasDados?.horario_paciente && (
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed bg-laranja-i font-branca" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Conflito de paciente
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Já existe um atendimento com o mesmo paciente e no mesmo horário que esse atendimento.
                            </div>
                        </div>
                    </div>
                )}
                {modalPendenciasDados?.sem_terapeuta && (
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button bg-pendente-i font-branca collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Sem Terapeuta
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Esse atendimento não possue um terapeuta definido.
                            </div>
                        </div>
                    </div>
                )}
                {modalPendenciasDados?.sem_status && (
                    <div className="accordion-item">
                        <h2 className="accordion-header collapsed" id="headingFive">
                            <button className="accordion-button bg-pendente-i font-branca collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Sem status definido
                            </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Não foi definido nenhum status para esse atendimento.
                            </div>
                        </div>
                    </div>
                )}
                {modalPendenciasDados?.sem_liberacao && (
                    <div className="accordion-item">
                        <h2 className="accordion-header collapsed" id="headingSix">
                            <button className="accordion-button bg-pendente-i font-branca collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                Sem liberação definida
                            </button>
                        </h2>
                        <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Não foi definido nenhum status de Liberado para esse atendimento.
                            </div>
                        </div>
                    </div>
                )}
                {modalPendenciasDados?.realizado_nao_liberado && (
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSeven">
                            <button className="accordion-button bg-pendente-i font-branca collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                Não liberado, mas Realizado
                            </button>
                        </h2>
                        <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Esse atendimento tem Status Realizado mas não esta liberado.
                            </div>
                        </div>
                    </div>
                )}
                {modalPendenciasDados?.liberado_realizado_sem_codigo && (
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEight">
                            <button className="accordion-button bg-pendente-i font-branca collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                Código 1 não definido
                            </button>
                        </h2>
                        <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Esse atendimento tem Status Realizado mas não tem Código 1 definido.
                            </div>
                        </div>
                    </div>
                )}
                {modalPendenciasDados?.liberado_realizado_sem_codigo2 && (
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingNine">
                            <button className="accordion-button bg-pendente-i font-branca collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                Código 2 não definido
                            </button>
                        </h2>
                        <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Esse atendimento tem Status Realizado mas não tem Código 2 definido.
                            </div>
                        </div>
                    </div>
                )}
                {modalPendenciasDados?.sem_execucao && (
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTen">
                            <button className="accordion-button bg-pendente-i font-branca collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                Sem status de Execução definido
                            </button>
                        </h2>
                        <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Esse atendimento tem Status Realizado não tem nenhum Status de Execução definido.
                            </div>
                        </div>
                    </div>
                )}
                {modalPendenciasDados?.sem_guia && (
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEleven">
                            <button className="accordion-button bg-pendente-i font-branca collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                Sem guia válida
                            </button>
                        </h2>
                        <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Esse atendimento não possue uma Guia válida.
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ReactModal>
    );
    //#endregion
});
export default Conflitos;
