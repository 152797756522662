import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Carregamento from 'components/Carregamento';
import { useAuth } from 'context/auth';
import api from 'services/api';
import { Container } from '../styles';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import RelatorioEvolucaoPaciente from 'components/Modais/TerapeutaRelatorios/RelatorioEvolucaoPaciente';
import { ChangeCase } from 'services/ChangeCase';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import { usePageBase } from 'context/page-base';
import useIcons from 'context/icons';
import Swal from 'sweetalert2';
import { useSnackbar } from 'context/snackbar';
import moment from 'moment';

/**
 * Componente de listagem de glosas.
 * 
 * Este componente exibe uma tabela com os atendimentos e glosas, permitindo a visualização e edição de cada item.
 * 
 * @component
 * @returns {JSX.Element} Retorna o componente de listagem de glosas.
 * 
 * @example
 * <ListagemDeGlosas />
 * 
 * @function
 * @name ListagemDeGlosas
 * 
 * @description
 * O componente utiliza hooks do React para gerenciar o estado e efeitos colaterais. Ele carrega os atendimentos e glosas ao montar e coleta as permissões do usuário para a página atual.
 * 
 * @hook
 * @name usePageBase
 * @description Hook que configura a base da página, incluindo título e descrição.
 * 
 * @hook
 * @name useAuth
 * @description Hook que fornece informações de autenticação do usuário.
 * 
 * @hook
 * @name useState
 * @description Hook que gerencia o estado local do componente.
 * 
 * @hook
 * @name useRef
 * @description Hook que cria uma referência persistente para o modal de edição de relatório.
 * 
 * @hook
 * @name useMemo
 * @description Hook que memoiza as colunas da tabela para evitar re-renderizações desnecessárias.
 * 
 * @hook
 * @name useEffect
 * @description Hook que executa efeitos colaterais, como carregar dados ao montar o componente.
 * 
 * @function
 * @name coletaPermissoes
 * @description Função que coleta permissões do usuário para a página atual.
 * 
 * @function
 * @name carregaAtendimentosEGlosas
 * @description Função assíncrona que carrega os atendimentos e glosas da API.
 * 
 * @function
 * @name onLoad
 * @description Função que é executada ao carregar o componente, chamando as funções de carregar dados e coletar permissões.
 */
export default function ListagemDeGlosas() {
    const pageBase = usePageBase({
        title: 'Atendimentos e Glosas',
        description: 'Tela onde é possível visualizar os atendimentos e glosas. Além de glosar manualmente um atendimento.',
        hasTitle: true,
        hasSubMenu: false
    });
    const { getIcon } = useIcons();
    const snackbar = useSnackbar();
    const [loading, setloading] = useState(false);
    const [glosasList, setGlosasList] = useState([]);
    const [glosasListOriginal, setGlosasListOriginal] = useState([]);
    const [selectedEvolucao, setSelectedEvolucao] = useState([]);
    const relatorioEdicaoModalRef = useRef();
    const columns = useMemo(() => [
        {
            Header: 'Data do Atendimento',
            accessor: 'data_atendimento_inicial',
            Cell: (({ cell }) => moment(cell.value).format('DD/MM/YYYY HH:mm')),
            filterable: true,
        },
        {
            Header: 'Terapeuta',
            accessor: 'terapeuta_nome',
            Cell: (({ cell }) => cell.value),
            filterable: true,
        },
        {
            Header: 'Paciente',
            accessor: 'paciente_nome',
            Cell: (({ cell }) => cell.value),
            filterable: true,
        },
        {
            Header: 'Status',
            accessor: 'status_de_glosa',
            filterable: true,
            Cell: (({ cell }) => {
                const style = { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '15px', margin: 'auto' };
                const status = ChangeCase.toSnakeCase(String(cell.value).toLowerCase());
                switch (status) {
                    case 'aceito':
                        return (
                            <b style={{ ...style, backgroundColor: '#00ff00' }}>
                                Aprovado
                            </b>
                        );
                    case 'glosado':
                        return (
                            <b style={{ ...style, backgroundColor: '#ff0000', color: 'white' }}>
                                Glosado
                            </b>
                        );
                    case 'glosa_aceita':
                        return (
                            <b style={{ ...style, backgroundColor: '#0000ff', color: 'yellow' }}>
                                Glosa Aceita
                            </b>
                        );
                    case 'sem_evolução':
                        return (
                            <b style={{ ...style, backgroundColor: '#f0f0f0' }}>
                                Sem Evolução
                            </b>
                        );
                    case 'pendente':
                        return (
                            <b style={{ ...style, backgroundColor: '#ffff00' }}>
                                Pendente
                            </b>
                        );
                    case 'glosado_manualmente':
                        return (
                            <b style={{ ...style, backgroundColor: '#ff0000', color: 'white' }}>
                                Glosado Manualmente
                            </b>
                        );
                    default:
                        return (
                            <b style={{ ...style, backgroundColor: '#ccc' }}>
                                ...
                            </b>
                        );
                }
            })
        },
        {
            Header: 'Ações',
            Cell: (({ cell }) => {
                const id = cell.row.original.evolucao_paciente_id;
                const glosado = ChangeCase.toSnakeCase(String(cell.row.original.status_de_glosa).toLowerCase()).includes('glosa');
                setSelectedEvolucao(cell.row.original);
                return (
                    <div className="w-100per d-flex justify-content-center ">
                        {(id) && <>
                            <button className="btn-icone" type="button" onClick={() => relatorioEdicaoModalRef?.current.openModal(id)}>
                                {getIcon('visualizar')}
                            </button>
                            <button className='btn-icone' onClick={() => { }}>
                                {getIcon('editar')}
                            </button>
                            {!glosado &&
                                <button title='Botão que define a linha como desistênte.' color="#7340BD" className='btn-icone' onClick={() => handleGlosaAtendimento(id)}>
                                    <FiXCircle color="#7340BD" size={22} />
                                </button>
                            }
                            <button title='Botão que define a linha como aceita.' color="#7340BD" className='btn-icone' onClick={() => handleAceitaAtendimento(id)}>
                                <FiCheckCircle color="#7340BD" size={22} />
                            </button>
                        </>
                        }
                    </div>
                );
            })
        }
    ], [getIcon]);

    //#region HANDLES
    /**
     * Função para tratar a glosa de um atendimento.
     * Exibe um modal de confirmação utilizando o Swal.fire, onde o usuário pode confirmar ou cancelar a ação.
     * Se confirmado, envia uma requisição PATCH para atualizar o status do atendimento e adicionar uma mensagem de resposta.
     * Exibe mensagens de sucesso ou erro utilizando o snackbar.
     *
     * @param {number} id - O ID do atendimento a ser glosado.
     */
    const handleGlosaAtendimento = useCallback((id) => {
        try {
            Swal.fire({
                title: 'Glosar Atendimento',
                text: 'Deseja realmente glosar este atendimento?',
                icon: 'warning',
                input: 'textarea',
                inputPlaceholder: 'Digite a mensagem de resposta...',
                inputAttributes: {
                    'aria-label': 'Digite a mensagem de resposta'
                },
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const mensagem_resposta = result.value;
                    const updateObj = { status: 5, mensagem_resposta: mensagem_resposta };
                    await api.patch(`api/evolucao-paciente/${id}`, updateObj)
                        .then(() => {
                            snackbar.displayMessage('Atendimento glosado com sucesso!', 'success');
                            carregaAtendimentosEGlosas();
                        }).catch((error) => {
                            snackbar.displayMessage('Erro ao glosar atendimento!', 'error');
                            console.error(error);
                        });
                }
            })
        } catch (e) {
            snackbar.displayMessage('Erro ao glosar atendimento!', 'error');
            console.error(e);
        }
    }, []);

    const handleAceitaAtendimento = useCallback((id) => {
        try {
            Swal.fire({
                title: 'Aceitar Atendimento',
                text: 'Deseja realmente aceitar este atendimento?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const updateObj = { status: 1 };
                    await api.patch(`api/evolucao-paciente/${id}`, updateObj)
                        .then(() => {
                            snackbar.displayMessage('Atendimento aceito com sucesso!', 'success');
                            carregaAtendimentosEGlosas();
                        }).catch((error) => {
                            snackbar.displayMessage('Erro ao aceitar atendimento!', 'error');
                            console.error(error);
                        });
                }
            })
        } catch (e) {
            snackbar.displayMessage('Erro ao aceitar atendimento!', 'error');
            console.error(e);
        }
    }, []);
    //#endregion

    //#region FUNCOES
    /**
     * Função assíncrona que carrega atendimentos e glosas.
     * Faz uma requisição POST para a API com os parâmetros fornecidos e atualiza as listas de glosas.
     * 
     * @async
     * @function carregaAtendimentosEGlosas
     * @returns {Promise<void>} - Uma promessa que resolve quando os dados são carregados e as listas são atualizadas.
     * @throws {Error} - Lança um erro se a requisição falhar.
     */
    async function carregaAtendimentosEGlosas() {
        try {
            const { data } = await api.post('api/atendimento/relatorio/filtrado', {
                // Adicione os parâmetros necessários aqui
                paciente_id: null,
                tipo_atendimento_id: null,
                data_inicio: null,
                data_fim: null,
                terapeuta_id: null,
                status_atendimento_id: null,
                liberado: null,
                executado: null,
                especialidade_id: null,
                terapia_id: null,
                plano_saude_id: null,
                status_de_assinatura: null,
            });
            setGlosasList(data);
            setGlosasListOriginal(data);
        } catch (e) {
            console.error(e);
        }
    }
    /**
     * Função assíncrona que carrega atendimentos e glosas, coleta permissões e atualiza o estado de carregamento.
     * 
     * @async
     * @function onLoad
     * @returns {Promise<void>} - Uma promessa que resolve quando todas as operações assíncronas são concluídas.
     */
    const onLoad = async () => {
        setloading(true);
        await carregaAtendimentosEGlosas();
        setloading(false);
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        onLoad();
    }, []);
    //#endregion
    //#region HTML
    return (
        <Container>
            {loading ? <Carregamento></Carregamento> : ''}
            <TableDefaultPagination columns={columns} source={glosasList} searchBar={true} tableTitle={pageBase.title}></TableDefaultPagination>
            <RelatorioEvolucaoPaciente ref={relatorioEdicaoModalRef} onClose={() => { }} tipo={'visualizacao'} />
        </Container>
    );
    //#endregion
}