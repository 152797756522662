import styled from 'styled-components';

export const TableAgendamentos = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 0.7rem;
    color: #333;
    margin: 0;
    border-radius: 0.5rem;
    overflow: hidden;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.1);

    td, th {
        padding: 0.5rem;
        border-bottom: 1px solid #e5e5e5;
    }
`;