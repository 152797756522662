import React, { useState, useEffect } from 'react';
import { ModalProvider, useModal } from 'context/modal-base';
import useIcons from 'context/icons';
import { marked } from 'marked';

const UpdateNotes = () => {
    const { openModal, setTitle, setAlgoCarregando } = useModal();
    const { iconArray } = useIcons();
    const [changelog, setChangelog] = useState('');

    useEffect(() => {
        setTitle('Notas de Atualização');
        setAlgoCarregando(true);
        fetch('../CHANGELOG.md')
            .then(response => response.text())
            .then(text => {
                setChangelog(marked(text));
                setAlgoCarregando(false);
            });
    }, [setTitle, setAlgoCarregando]);

    const handleOpenModal = () => {
        openModal(
            <div className='modal-content'>
                <div dangerouslySetInnerHTML={{ __html: changelog }} />
            </div>
        );
    };

    return (
        <button className='btn-icone' onClick={handleOpenModal}>
            {React.cloneElement(iconArray['exclamacao-circulo'], { size: 20 })}
        </button>
    );
};
const UpdateNotesWraper = () => <ModalProvider><UpdateNotes /></ModalProvider>
export default UpdateNotesWraper;
