import { useEffect, useState } from 'react';

import { TutorialBadge } from 'components/AjudanteInicial';
import { Dashboard } from 'helpers/dashboard.helper';
import moment from 'moment';
import { ScaleLoader } from 'react-spinners';
import AcessoRapido from '../../components/AcessoRapido';
import { MostradoresColoridos } from '../../components/MostradoresColoridos';
import { useAuth } from '../../context/auth';
import api from '../../services/api';
import RecadosCard from '../DashboardPadrao/RecadosCard';
import { Container, ContainerSub, GridHeader, GridRecados, Header } from './styles';
import { usePageBase } from 'context/page-base';
import BalaoDica from 'components/Baloes/BalaoDica';

export default function DashboardPadrao() {
    const pageBase = usePageBase({
        title: 'Dashboard Padrão',
        description: 'Dashboard padrão do sistema',
        hasTitle: false,
        hasSubmenu: false,
    });
    //#region VARIAVEIS
    const { user } = useAuth();
    const tipo_user = user.tipo_user;

    const [dashboardStructure, setDashboardStructure] = useState(null);
    const [recado, setRecado] = useState([]);
    const [mostradoresColoridos, setMostradoresColoridos] = useState({
        titulo: 'Estatísticas',
        mostradores: [],
    });
    const [acessoRapido, setAcessoRapido] = useState([]);
    const [loadingMostradores, setLoadingMostradores] = useState(false);
    const [loadingAcessos, setLoadingAcessos] = useState(false);
    const mostradorStyle = {
        borderTopLeftRadius: '5%',
        borderTopRightRadius: '5%',
        gridArea: 'mostradores',
        margin: '0',
        padding: '0'
    };
    //#endregion

    //#region FUNCOES

    const onLoad = async () => {
        const tipoUserUpper = tipo_user.toUpperCase();
        const dashboard = new Dashboard(tipoUserUpper, user.id);
        await dashboard.setMostradoresInformacao();

        try {
            if (dashboard.getEnableRecados()) {
                const recadoResponse = await api.get('api/recado/get/by/group/' + user.tipo_user);
                setRecado(recadoResponse.data);
            }
            if (dashboard.getEnableMostradores()) {
                const mostradores = dashboard.getMostradores();
                setMostradoresColoridos({
                    titulo: 'Estatísticas',
                    mostradores: mostradores
                });
            }
            if (dashboard.getEnableAcessoRapido) {
                const acessoRapido = dashboard.getAcessoRapido();
                setAcessoRapido(acessoRapido);
            }
            setDashboardStructure(dashboard);
            setLoadingMostradores(false);
            setLoadingAcessos(false);
        } catch (error) {
            console.error(error);
        }
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (tipo_user) {
            setLoadingMostradores(true);
            setLoadingAcessos(true);
            onLoad();
        }
    }, [tipo_user]);
    //#endregion
    //#region HTML
    return (
        <>
            <Container className='row'>
                {!dashboardStructure &&
                    <div className='col-12 d-flex'>
                        <label className="text-center all-center w-content h-content mx-auto">
                            Carregando dashboard...<br></br><br></br>
                            <ScaleLoader color='#000' loading={true} />
                        </label>
                    </div>
                }
                <ContainerSub id='containerSub'>
                    {dashboardStructure && dashboardStructure.getEnableHeader() &&
                        <GridHeader id="header">
                            <Header>
                                <label>Bem Vindo {user?.name && user?.name}!</label>
                                <label>Hoje é dia {moment.utc().format('DD/MM/YYYY')}</label>
                            </Header>
                        </GridHeader>
                    }
                    {(dashboardStructure && dashboardStructure && dashboardStructure?.getEnableMostradores()) &&
                        <MostradoresColoridos mostradores={mostradoresColoridos.mostradores} style={mostradorStyle} titulo={mostradoresColoridos.titulo} id="mostradores" className='mostradores' name="mostradores" loading={loadingMostradores || mostradoresColoridos.mostradores.length === 0} />
                    }
                    {dashboardStructure?.getEnableRecados() &&
                        <>
                            <GridRecados id="recados">
                                <RecadosCard title={'Recado'} recado={recado} />
                            </GridRecados>
                            <BalaoDica elementoAncora='recados'>
                                Aqui você poderia visualizar os recados importantes do sistema e da clínica, sempre fique de olho 👀.
                            </BalaoDica>
                        </>
                    }
                    {acessoRapido && dashboardStructure && dashboardStructure?.getEnableAcessoRapido() &&
                        <AcessoRapido id="acesso" name="acesso" acessos={acessoRapido} loading={loadingAcessos} />
                    }
                </ContainerSub>
            </Container>
            {dashboardStructure &&
                <TutorialBadge
                    steps={
                        [
                            {
                                title: 'Bem Vindo',
                                text: <div className="d-flex flex-col">
                                    <span className="font-12px font-cinza">(Aperte em próximo para ir para a próxima etapa, ou no X para sair do tutorial)</span>
                                    <span>Bem vindo ao Neuro Sistema! Passaremos por algumas etapas, para você aprender como utilizar o sistema!</span>
                                </div>
                            },
                            { title: 'Informações Básicas', selector: '#header', text: 'Aqui você tem algumas informações básicas!' },
                            { title: 'Indicadores Importantes', selector: '#mostradores', text: 'Nesse local, você pode ver alguns indicadores importantes, passe o mouse por cima, para ver seus detalhes!' },
                            { title: 'Acesso Rápido', selector: '#acesso', text: 'Temos aqui alguns links de Acesso Rápido, clique para acessa-los!' },
                            { title: 'Recados Importantes', selector: '#recados', text: 'Nesse local, você pode ver alguns recados importantes, sempre se atente a isso!' },
                        ]
                    }
                    hasTopicos={true}
                    standartFinal={true}
                />
            }
        </>
    );
    //#endregion
};