import { Form } from '@unform/web';
import { TutorialBadge } from 'components/AjudanteInicial';
import Carregamento from 'components/Carregamento';
import LiveBadge from 'components/LiveBadge';
import AgendaModalPaciente from 'components/Modais/Pacientes/AgendaModal';
import { MultiSelect } from 'components/Select/MultiSelect';
import useTipoAtendimentosData from 'context/tipo-atendimento';
import { toPng } from 'html-to-image';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { unstable_batchedUpdates } from 'react-dom';
import { AiFillCaretDown, AiOutlineClear, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { BsDownload, BsFullscreen } from 'react-icons/bs';
import { FaBroom } from 'react-icons/fa';
import { FiFilter, FiRefreshCcw } from 'react-icons/fi';
import 'styles/global.css';
import Swal from 'sweetalert2';
import Select from '../../../../components/Select';
import { useAuth } from '../../../../context/auth';
import usePermissoes from '../../../../context/permission';
import { useSnackbar } from '../../../../context/snackbar';
import { getLegendaAgendamento } from '../../../../helpers/agenda.helper';
import AgendamentoService from '../../../../services/AgendamentoService';
import api from '../../../../services/api';
import EstacaoService from '../../../../services/EstacaoService';
import Input from '../../../Login/components/Input';
import AdicionarAgendamento from '../../../TerapeutaDashboard/Dashboard/Modal/AdicionarAgendamento';
import LegendaDefault from '../ControleDiario/Modal/Legenda';
import DetalhesDoAgendamento from './Modal/DetalhesDoAgendamento';
import Responsavel from './Modal/Responsavel';
import { Container, DivContentDisplay, filtered, listOfStyles, styles_escuro, styles_filtred } from './styles';
import TbodyAgendaSemanal from './tbody-agenda-semana';
import { usePageBase } from 'context/page-base';

/**
 * Componente principal da agenda semanal.
 * 
 * @component
 * @returns {JSX.Element} O componente da agenda semanal.
 * 
 * @example
 * <AgendaSemanal />
 * 
 * @description
 * Este componente é responsável por exibir a agenda semanal, permitindo a visualização e o cadastro de agendamentos.
 * Ele utiliza diversos hooks e funções para gerenciar o estado e as operações da agenda.
 * 
 * @function
 * @name AgendaSemanal
 * 
 * @property {Object} pageBase - Configurações básicas da página.
 * @property {Object} user - Dados do usuário autenticado.
 * @property {Array<number>} stylesSize - Tamanhos dos estilos.
 * @property {Object} stylesNormal - Estilos normais.
 * @property {Object} stylesNormalFullscreen - Estilos normais em tela cheia.
 * @property {Object} stylesEspera - Estilos no modo espera.
 * @property {Object} stylesEsperaFullscreen - Estilos no modo espera em tela cheia.
 * @property {Object} styles - Estilos atuais.
 * @property {Object} socket - Estado do socket.
 * @property {Object} socketState - Estado do socket.
 * @property {Object} snackbar - Utilitário de notificações.
 * @property {boolean} loading - Estado de carregamento.
 * @property {boolean} algoCarregando - Estado de carregamento de algo.
 * @property {boolean} fullScreenState - Estado de tela cheia.
 * @property {boolean} mostrandoReserva - Estado de exibição de reserva.
 * @property {boolean} esperaMode - Estado do modo espera.
 * @property {Object} usuario - Dados do usuário autenticado.
 * @property {Object} permissoes - Permissões do usuário.
 * @property {boolean} isModalAdicionarAtendimentoVisible - Visibilidade do modal de adicionar atendimento.
 * @property {boolean} isModalLegendaVisible - Visibilidade do modal de legenda.
 * @property {boolean} isModalResponsavelVisible - Visibilidade do modal de responsável.
 * @property {boolean} isModalDetalhesDoAtendimentoVisible - Visibilidade do modal de detalhes do atendimento.
 * @property {Array<string>} dayName - Nomes dos dias da semana.
 * @property {Array<Object>} estacaos - Lista de estações.
 * @property {Array<Object>} memorizedEstacaos - Lista memorizada de estações.
 * @property {Array<Object>} estacaosOriginal - Lista original de estações.
 * @property {Array<Object>} horarios - Lista de horários.
 * @property {Array<Object>} memorizedHorarios - Lista memorizada de horários.
 * @property {Array<Object>} agendamentos - Lista de agendamentos.
 * @property {Object} socketAgendamento - Estado do socket de agendamento.
 * @property {Array<Object>} newAgendamentos - Lista de novos agendamentos.
 * @property {Array<Object>} horariosEstacoesDisponiveis - Lista de horários e estações disponíveis.
 * @property {string|null} hour - Hora selecionada.
 * @property {Object|null} estacaoObj - Objeto da estação selecionada.
 * @property {Object|null} tipoAtendimentoSelecionado - Tipo de atendimento selecionado.
 * @property {Object|null} reservaSelecionada - Reserva selecionada.
 * @property {boolean} expand - Estado de expansão do filtro.
 * @property {string} dataSelecionada - Data selecionada.
 * @property {number} agendamentoId - ID do agendamento.
 * @property {string} idHorario - ID do horário.
 * @property {Date} dateNow - Data atual.
 * @property {Object} dataAtual - Data atual.
 * @property {number} diaAtual - Dia atual.
 * @property {Object} dataSave - Data salva.
 * @property {Object} agendaModalPacienteRef - Referência do modal de agenda do paciente.
 * @property {Object} elementRef - Referência do elemento para exportação de imagem.
 * @property {Object} estacaoTableRef - Referência da tabela de estações.
 * @property {Object} filtroDivRef - Referência do filtro.
 * @property {string} filtroIncidencia - Incidência do filtro.
 * @property {Object} tipoAtendimentosOptions - Opções de tipos de atendimento.
 * @property {Function} fetchTipoAtendimentosData - Função para buscar dados de tipos de atendimento.
 * @property {Array<Object>} terapeutasOptions - Opções de terapeutas.
 * @property {Array<Object>} terapiasOptions - Opções de terapias.
 * @property {Array<Object>} especialidadesOptions - Opções de especialidades.
 * @property {Array<Object>} modalidadesOptions - Opções de modalidades.
 * @property {Array<Object>} pacientesOptions - Opções de pacientes.
 * @property {Array<Object>} memoizedTerapeutasOptions - Opções memorizadas de terapeutas.
 * @property {Array<Object>} memoizedTerapiasOptions - Opções memorizadas de terapias.
 * @property {Array<Object>} memoizedEspecialidadesOptions - Opções memorizadas de especialidades.
 * @property {Array<Object>} memoizedTiposAtendimentoOptions - Opções memorizadas de tipos de atendimento.
 * @property {Array<Object>} memoizedModalidadesOptions - Opções memorizadas de modalidades.
 * @property {Array<Object>} memoizedPacientesOptions - Opções memorizadas de pacientes.
 * @property {Array<Object>} terapeutaSelecionadoOpt - Opção de terapeuta selecionado.
 * @property {Array<Object>} pacienteSelecionadoOpt - Opção de paciente selecionado.
 * @property {Array<Object>} estacaoSelecionadaOpt - Opção de estação selecionada.
 * @property {Array<Object>} estacaoSelecionadaOptTemp - Opção temporária de estação selecionada.
 * @property {Array<Object>} terapiasSelecionadaOpt - Opção de terapia selecionada.
 * @property {Array<Object>} especialidadesSelecionadaOpt - Opção de especialidade selecionada.
 * @property {Array<Object>} salasList - Lista de salas.
 * @property {Object} scrollPos - Posição de rolagem.
 * @property {Object} tableRef - Referência da tabela.
 * @property {Object} horaTableBodyRef - Referência do corpo da tabela de horários.
 * @property {Object} agendamentoTableBodyRef - Referência do corpo da tabela de agendamentos.
 * @property {Object} estacaoTableHeadRef - Referência do cabeçalho da tabela de estações.
 * 
 * @function atualizaScrollTbody - Atualiza a posição de rolagem do corpo da tabela de agendamentos.
 * @function enterFullscreen - Entra no modo de tela cheia.
 * @function exitFullScreen - Sai do modo de tela cheia.
 * @function fullScreen - Alterna o modo de tela cheia.
 * @function aprovaReserva - Aprova uma reserva como agendamento.
 * @function expandFiltro - Expande ou colapsa o filtro.
 * @function abreFiltro - Abre o filtro.
 * @function fechaFiltro - Fecha o filtro.
 * @function handleLimpaFiltros - Limpa os filtros.
 * @function estraiIncidenciasUnicas - Extrai incidências únicas de pacientes, terapeutas, modalidades e tipos de uma lista de agendamentos.
 * @function filtrar - Filtra os agendamentos com base nos filtros selecionados.
 * @function updateFilter - Atualiza o filtro com base nos checkboxes selecionados.
 * @function excluirAgendamento - Exclui um agendamento.
 * @function handleOpenAgendamento - Abre o modal de agendamento.
 * @function handleOnMouseEnter - Manipula o evento de mouse enter.
 * @function handleOnMouseLeave - Manipula o evento de mouse leave.
 * @function modalDetalhesAgendamento - Abre o modal com os detalhes do agendamento.
 * @function modalEditar - Abre o modal para editar um agendamento.
 * @function carregaEstacoes - Carrega as estações.
 * @function carregaHorarios - Carrega os horários.
 * @function createNewAgendamentos - Cria novos agendamentos com base nos dados fornecidos.
 * @function carregaAgendamentos - Carrega os agendamentos com base em uma data.
 * @function atualizaSocket - Atualiza o socket com os dados fornecidos.
 * @function saveScrollPos - Salva a posição de rolagem.
 * @function atualizaAgendamento - Atualiza um agendamento.
 * @function atualizaComponenteData - Atualiza os dados do componente.
 * @function handleOpenModalAgendaPaciente - Abre o modal da agenda do paciente.
 * @function handleDrop - Manipula o evento de drop na grade de horários do terapeuta.
 * @function handleClickNextDay - Manipula o evento de clique para o próximo dia.
 * @function handleClickPrevDay - Manipula o evento de clique para o dia anterior.
 * @function handleSelectDay - Manipula a seleção de um dia.
 * @function syncScroll - Sincroniza a posição de rolagem de um elemento de origem com um ou dois elementos de destino.
 * @function handleExportToImage - Manipula a exportação do componente para uma imagem.
 * @function handleChangeModalLegenda - Manipula a mudança de visibilidade do modal de legenda.
 * @function handleChangeEsperaMode - Manipula a mudança do estado do modo espera.
 * @function onload - Função chamada quando o componente é carregado.
 * @function filterOptionPaciente - Função memorizada para filtrar opções de pacientes com base no valor de entrada.
 */
export default function AgendaSemanal() {
	const pageBase = usePageBase({
		title: 'Agenda Semanal',
		description: 'Tela que cadastra e visualiza agendamentos para o dia/semana.',
		hasTitle: true,
		hasSubmenu: true
	});
	//setRoutes(['dashboard/{modulo}/agenda']);
	//#region VARIÁVEIS
	//USUARIO
	const { user } = useAuth();
	const stylesSize = [90, 70, 90, 70];
	const differenceBase = [0, 350];
	/*==VARS ESTILOS==*/
	const stylesNormal = useMemo(() => listOfStyles({
		baseWidth: stylesSize[0],
		baseHeight: stylesSize[1],
		baseWidthHora: stylesSize[2],
		baseHeightHora: stylesSize[3],
		differenceBase
	}), [stylesSize]);

	const stylesNormalFullscreen = useMemo(() => listOfStyles({
		baseWidth: stylesSize[0],
		baseHeight: stylesSize[1],
		baseWidthHora: stylesSize[2],
		baseHeightHora: stylesSize[3],
		isFullScreen: true
	}), [stylesSize]);

	const stylesEspera = useMemo(() => listOfStyles({
		baseWidth: stylesSize[0],
		baseHeight: stylesSize[1],
		baseWidthHora: stylesSize[2],
		baseHeightHora: stylesSize[3],
		esperaMode: true,
	}), [stylesSize]);

	const stylesEsperaFullscreen = useMemo(() => listOfStyles({
		baseWidth: stylesSize[0],
		baseHeight: stylesSize[1],
		baseWidthHora: stylesSize[2],
		baseHeightHora: stylesSize[3],
		isFullScreen: true,
		esperaMode: true
	}), [stylesSize]);
	const [styles, setStyles] = useState(stylesNormal);
	/*==VARS SOCKET==*/
	const [socket, setSocket] = useState(null);
	const [socketState, setSocketState] = useState(null);
	/*==VARS UTILITÁRIOS==*/
	const snackbar = useSnackbar();
	/*==VARS LOADING==*/
	const [loading, setloading] = useState(true);
	const [algoCarregando, setAlgoCarregando] = useState(false);
	const [fullScreenState, setfullScreenState] = useState(false);
	const [mostrandoReserva, setMostrandoReserva] = useState(false);
	const [esperaMode, setEsperaMode] = useState(false);
	/*==VARS PERMISSÕES==*/
	const usuario = useAuth();
	const permissoes = usePermissoes();
	/*==VARS MODAIS==*/
	const [isModalAdicionarAtendimentoVisible, setIsModalAdicionarAtendimentoVisible] = useState(false);
	const [isModalLegendaVisible, setIsModalLegendaVisible] = useState(false);
	const [isModalResponsavelVisible, setIsModalResponsavelVisible] = useState(false);
	const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
	/*==VARS DIAS==*/
	const dayName = ['Domingo', 'Segunda - Feira', 'Terça - Feira', 'Quarta - Feira', 'Quinta - Feira', 'Sexta - Feira', 'Sábado'];
	/*==VARS Listas de Dados==*/
	const [estacaos, setEstacaos] = useState([]);
	const memorizedEstacaos = useMemo(() => estacaos, [estacaos]);
	const [estacaosOriginal, setEstacaosOriginal] = useState([]);

	const [horarios, setHorarios] = useState([]);
	const memorizedHorarios = useMemo(() => horarios, [horarios]);

	const [agendamentos, setAgendamentos] = useState([]);
	const [socketAgendamento, setSocketAgendamento] = useState(null);
	const [newAgendamentos, setNewAgendamentos] = useState([]);
	const [horariosEstacoesDisponiveis, setHorariosEstacoesDisponiveis] = useState([]);

	const [hour, setHour] = useState(null);
	const [estacaoObj, setEstacaoObj] = useState(null);
	const [tipoAtendimentoSelecionado, setTipoAtendimentoSelecionado] = useState(null);
	const [reservaSelecionada, setReservaSelecionada] = useState(null);
	const [expand, setExpand] = useState(false);
	const [dataSelecionada, setDataSelecionada] = useState(moment().format('YYYY-MM-DD'));
	const [agendamentoId, setAgendamentoId] = useState();
	const [idHorario, setIdHorario] = useState();
	/*==VARS DATAS==*/
	const dateNow = new Date();
	const [dataAtual, setDataAtual] = useState(moment(dateNow));
	const [diaAtual, setDiaAtual] = useState(dateNow.getDay());
	const [dataSave, setDataSave] = useState(moment());
	const agendaModalPacienteRef = useRef();
	/*==VARS EXPORT TO IMAGE==*/
	const elementRef = useRef(null);
	const estacaoTableRef = useRef(null);
	/*==VARS FILTRO==*/
	const filtroDivRef = useRef(null);
	const [filtroIncidencia, setFiltroIncidencia] = useState('');

	const { tipoAtendimentosOptions, fetchTipoAtendimentosData } = useTipoAtendimentosData();

	const [terapeutasOptions, setTerapeutasOptions] = useState([]);
	const [terapiasOptions, setTerapiasOptions] = useState([]);
	const [especialidadesOptions, setEspecialidadesOptions] = useState([]);
	const [modalidadesOptions, setModalidadesOptions] = useState([]);
	const [pacientesOptions, setPacientesOptions] = useState([]);
	//Memorizados
	const memoizedTerapeutasOptions = useMemo(() => terapeutasOptions, [terapeutasOptions]);
	const memoizedTerapiasOptions = useMemo(() => terapiasOptions, [terapiasOptions]);
	const memoizedEspecialidadesOptions = useMemo(() => especialidadesOptions, [especialidadesOptions]);
	const memoizedTiposAtendimentoOptions = useMemo(() => tipoAtendimentosOptions, [tipoAtendimentosOptions]);
	const memoizedModalidadesOptions = useMemo(() => modalidadesOptions, [modalidadesOptions]);
	const memoizedPacientesOptions = useMemo(() => pacientesOptions, [pacientesOptions]);

	const [terapeutaSelecionadoOpt, setTerapeutaSelecionadoOpt] = useState([]);
	const [pacienteSelecionadoOpt, setPacienteSelecionadoOpt] = useState([]);
	const [estacaoSelecionadaOpt, setEstacaoSelecionadaOpt] = useState([]);
	const [estacaoSelecionadaOptTemp, setEstacaoSelecionadaOptTemp] = useState([]);
	const [terapiasSelecionadaOpt, setTerapiasSelecionadaOpt] = useState([]);
	const [especialidadesSelecionadaOpt, setEspecialidadesSelecionadaOpt] = useState([]);

	const [salasList, setSalasList] = useState([]);

	//===VARS SCROLL SAVE===*/
	const [scrollPos, setScrollPos] = useState({ top: 0, left: 0 });
	//===VARS REFS===*/
	const tableRef = useRef(null);
	const horaTableBodyRef = useRef(null);
	const agendamentoTableBodyRef = useRef(null);
	const estacaoTableHeadRef = useRef(null);
	//#endregion
	//#region FUNCOES
	/**
	 * Handles the loading of the tbody element for agendamentos (appointments).
	 * It restores the scroll position of the table body to the previously saved position.
	 * 
	 * If the `agendamentoTableBodyRef` is available, it uses that reference to set the scroll position.
	 * Otherwise, it falls back to using the element with the ID 'tbody-grade-de-agendamentos'.
	 * 
	 * @function
	 */
	const atualizaScrollTbody = (e) => {
		if (e?.current) {
			e.current.scrollTop = scrollPos.top;
			e.current.scrollLeft = scrollPos.left;
			setloading(false);
		}
	};
	/**
	 * Enters fullscreen mode for the specified element.
	 */
	const enterFullscreen = () => {
		const elem = tableRef.current;
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) { // Firefox
			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
			elem.webkitRequestFullscreen();
		}
	};
	/**
	 * Exits full screen mode.
	 */
	const exitFullScreen = () => {
		const isDocumentActive = () => {
			return !document.hidden;
		};
		if (isDocumentActive) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			}
		}
	};
	/**
	 * Toggles the fullscreen mode.
	 */
	const fullScreen = useCallback(() => {
		if (!document.fullscreenElement) {
			enterFullscreen();
			setfullScreenState(true);
		} else {
			exitFullScreen();
			setfullScreenState(false);
		}
	}, []);
	/**
	 * Approves a reservation as an appointment.
	 * 
	 * @param {Object} agendamento - The appointment object.
	 * @param {number} agendamento.id - The ID of the appointment.
	 * @param {string} agendamento.data_atendimento_inicial - The initial date and time of the appointment.
	 * @param {string} agendamento.data_atendimento_final - The final date and time of the appointment.
	 * @param {string} agendamento.paciente_nome - The name of the patient.
	 * @param {string} agendamento.terapeuta_nome_curto - The short name of the therapist.
	 */
	const aprovaReserva = useCallback((agendamento) => {
		let id = agendamento.id;
		let data1 = agendamento.data_atendimento_inicial.split(' ')[1].split('.')[0];
		let data2 = agendamento.data_atendimento_final.split(' ')[1].split('.')[0];
		let conteudo = `Paciente: ${agendamento.paciente_nome} \n`
			+ `Terapeuta: ${agendamento.terapeuta_nome_curto} \n`
			+ `${data1} até ${data2}`;
		let tipo = 'question';
		if (id !== null) {
			let option = {
				title: 'Você deseja confirmar essa reserva, como agendamento?',
				text: conteudo,
				icon: tipo,
				confirmButtonText: 'Confirmar',
				denyButtonText: 'Cancelar',
				showConfirmButton: true,
				showDenyButton: true
			};
			Swal.fire(option).then((result) => {
				if (result.isConfirmed) {
					AgendamentoService.update({ id: id, reserva: 0 })
						.then(() => {
							snackbar.displayMessage('Reserva aprovada como agendamento.', 'success');
							atualizaComponenteData();
						})
						.catch((e) => {
							snackbar.displayMessage('Houve um erro ao aprovar a reserva.', 'error');
							console.error(e);
						});
				}
			});
		}
	}, []);
	/*==FILTRO==*/
	/**
	 * Expands or collapses the filter.
	 * 
	 * @param {Event} e - The event object.
	 * @returns {void}
	 */
	const expandFiltro = useCallback((e) => {
		if (expand) {
			fechaFiltro();
		} else {
			abreFiltro();
		}
	}, [expand]);
	/**
	 * Opens the filter.
	 */
	const abreFiltro = useCallback(() => {
		setExpand(true);
	}, []);
	/**
	 * Closes the filter dropdown if the event target is outside the filter container.
	 * @param {Event} event - The event object.
	 */
	const fechaFiltro = useCallback((event) => {
		try {
			if (!event) {
				setExpand(false);
			} else {
				const element = document.querySelector('.p-multiselect-panel.p-component');
				if (filtroDivRef.current && !filtroDivRef.current.contains(event.target) && event.target !== element && !element?.contains(event.target)) {
					setExpand(false);
				}
			}
		} catch (error) {
			console.error(error);
		}
	}, []);
	/**
	 * Clears the filters for the therapist's agenda.
	 */
	const handleLimpaFiltros = useCallback(() => {
		unstable_batchedUpdates(() => {
			setPacienteSelecionadoOpt([]);
			setEstacaoSelecionadaOpt([]);
			setEstacaoSelecionadaOptTemp([]);
			setTerapiasSelecionadaOpt([]);
			setEspecialidadesSelecionadaOpt([]);
			setTerapeutaSelecionadoOpt([]);
			setFiltroIncidencia('');
		});
	}, []);
	/**
	 * Extracts unique patients, therapists, modalities, and types from the given list of appointments.
	 * 
	 * @param {Array} agendamentos - The list of appointments.
	 * @returns {Object} An object containing unique patients, therapists, modalities, and types.
	 */
	const estraiIncidenciasUnicas = (agendamentos) => {
		const pacientes = [];
		const terapeutas = [];
		const modalidades = [
			{
				label: 'Presencial',
				value: 1
			}
		];
		fetchTipoAtendimentosData();
		const terapias = [];
		const especialidades = [];

		agendamentos.forEach((agendamento) => {
			if (!pacientes.some(patient => patient.value === agendamento.paciente_id)) {
				pacientes.push({
					value: agendamento.paciente_id,
					label: `(${agendamento.paciente_identificador}) ${agendamento.paciente_nome}`,
					nome: agendamento.paciente_nome,
					identificador: agendamento.paciente_identificador
				});
			}

			if (!terapeutas.some(therapist => therapist.value === agendamento.terapeuta_id)) {
				terapeutas.push({
					value: agendamento.terapeuta_id,
					label: agendamento.terapeuta_nome
				});
			}

			if (!terapias.some(terapia => terapia.value === agendamento.terapia_id)) {
				terapias.push({
					value: agendamento.terapia_id,
					label: agendamento.terapia_terapia
				});
			}

			if (!especialidades.some(especialidade => especialidade.value === agendamento.especialidade_id)) {
				especialidades.push({
					value: agendamento.especialidade_id,
					label: agendamento.especialidade_especialidade
				});
			}
		});
		setTerapeutasOptions(terapeutas);
		setTerapiasOptions(terapias)
		setEspecialidadesOptions(especialidades);
		setModalidadesOptions(modalidades);
		setPacientesOptions(pacientes);
	};
	/**
	 * Filters the agendamentos based on the selected filters and updates the agendamentos list.
	 * 
	 * @returns {void}
	 */
	const filtrar = useCallback((newAgendamentosOutside = null) => {
		const agendamentosL = newAgendamentosOutside ? newAgendamentosOutside : agendamentos;
		if (agendamentosL.length > 0) {
			const filtro = filtroIncidencia === '' ? null : filtroIncidencia;
			const idPaciente = pacienteSelecionadoOpt.value ? pacienteSelecionadoOpt.value : null;
			const idTerapeuta = terapeutaSelecionadoOpt.value ? terapeutaSelecionadoOpt.value : null;
			const agendamentosNovos = agendamentosL.map((a) => {
				/* filtra terapia? */
				const filterTerapia = terapiasSelecionadaOpt?.length > 0;
				/* filtra especialidade? */
				const filterEspecialidade = especialidadesSelecionadaOpt?.length > 0;
				/* filtra terapeuta? */
				const filterTerapeuta = idTerapeuta === null ? false : true;
				/* filtra paciente? */
				const filterPaciente = idPaciente === null ? false : true;

				/* Testa incidência de terapeuta */
				const terapeutaFiltro = filterTerapeuta ? a.terapeuta_id === idTerapeuta : true;
				/* Testa incidência de paciente */
				const pacienteFiltro = filterPaciente ? a.paciente_id === idPaciente : true;
				/* Testa incidência de terapia */
				const inTerapias = filterTerapia ? terapiasSelecionadaOpt.some(terapia => terapia.value === a.terapia_id) : true;
				/* Testa incidência de especialidade */
				const inEspecialidade = filterEspecialidade ? especialidadesSelecionadaOpt.some(especialidade => especialidade.value === a.especialidade_id) : true;

				/* Variável que define no final se será filtrao, não filtrado ou sem filtros */
				let filtrado = (filterTerapia || filterEspecialidade || filterTerapeuta || filterPaciente)
					? (terapeutaFiltro && pacienteFiltro && inTerapias && inEspecialidade)
						? true : false
					: undefined;
				if (filtrado === undefined) {
					if (filtro !== '') {
						if (String(a.paciente_nome).toLowerCase().includes(String(filtro).toLowerCase()) ||
							String(a.paciente_identificador).toLowerCase().includes(String(filtro).toLowerCase())) {
							filtrado = true;
						}
					}
				}
				return ({ ...a, filtrado: filtrado });
			});
			createNewAgendamentos(agendamentosNovos);
			updateFilter();
		}
	}, [agendamentos, filtroIncidencia, pacienteSelecionadoOpt, terapeutaSelecionadoOpt, terapiasSelecionadaOpt, especialidadesSelecionadaOpt]);
	/**
	 * Updates the filter based on the selected checkboxes.
	 * 
	 * @returns {Array<string>} An array of selected checkbox values.
	 */
	const updateFilter = useCallback(() => {
		let fields = document.getElementsByName('salas[]');
		let checks = [];
		fields.forEach((x) => {
			if (x.checked) {
				checks.push(x.value);
			}
		});

		return checks;
	}, []);
	/*==AGENDAMENTO==*/
	/**
	 * Function to delete an appointment.
	 * 
	 * @param {number} id - The ID of the appointment to be deleted.
	 * @returns {void}
	 */
	const excluirAgendamento = (id) => {
		Swal.fire({
			title: 'Tem certeza? (' + id + ')',
			text: 'Você não poderá reverter isso!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sim, excluir!'
		}).then((result) => {
			if (result.isConfirmed) {
				AgendamentoService.destroy(id).then(({ data }) => {
					atualizaSocket({ id: id, deleted_at: moment.utc().format('YYYY-MM-DD HH:mm:ss') });
					atualizaComponenteData();
					snackbar.displayMessage('Deletado! O agendamento foi deletado.', 'success');
				});
			}
		});
	};
	/*==MODAIS==*/
	/**
	 * Handles the opening of an appointment.
	 * 
	 * @param {string} hour - The hour of the appointment.
	 * @param {object} estacao - The station object.
	 * @param {string} idHorario - The ID of the schedule.
	 * @param {Event} e - The event object.
	 * @returns {void}
	 */
	const handleOpenAgendamento = (hour, estacao, idHorario, e) => {
		e.stopPropagation();
		const target = e.target;
		const name = target.dataset.name;
		if (name === 'espaco-vazio') {
			if (permissoes['criacao_edicao']) {
				setHour(hour);
				setEstacaoObj(estacao);
				setIsModalAdicionarAtendimentoVisible(true);
				setIdHorario(idHorario);
			}
		}
	};

	const handleOnMouseEnter = (linhaId, colunaId) => {
		const linha = document.getElementById('linha-horario-' + linhaId);
		const coluna = document.getElementById('coluna-sala-cont-' + colunaId);
		const coluna1 = document.getElementById('coluna-estacao-cont-' + colunaId);
		const coluna2 = document.getElementById('coluna-responsavel-cont-' + colunaId);

		linha.classList.add('hovered-linha');
		coluna.classList.add('hovered-coluna');
		coluna1.classList.add('hovered-coluna');
		coluna2.classList.add('hovered-coluna');
	};
	const handleOnMouseLeave = (linhaId, colunaId) => {
		const linha = document.getElementById('linha-horario-' + linhaId);
		const coluna = document.getElementById('coluna-sala-cont-' + colunaId);
		const coluna1 = document.getElementById('coluna-estacao-cont-' + colunaId);
		const coluna2 = document.getElementById('coluna-responsavel-cont-' + colunaId);

		linha.classList.remove('hovered-linha');
		coluna.classList.remove('hovered-coluna');
		coluna1.classList.remove('hovered-coluna');
		coluna2.classList.remove('hovered-coluna');
	};
	/**
	 * Opens the modal with the details of an appointment.
	 * 
	 * @param {Object} detalhes - The details of the appointment.
	 */
	const modalDetalhesAgendamento = (detalhes) => {
		setAgendamentoId(detalhes.id);
		setIsModalDetalhesDoAtendimentoVisible(true);
	};
	/**
	 * Opens a modal for editing an appointment.
	 *
	 * @param {Object} detalhes - The details of the appointment.
	 * @param {string} hour - The hour of the appointment.
	 * @param {Object} estacao - The station of the appointment.
	 * @param {string} idHorario - The ID of the appointment time.
	 * @param {string} reserva - The reservation of the appointment.
	 * @param {string} tipoAtendimento - The type of appointment.
	 */
	const modalEditar = (detalhes, hour, estacao, idHorario, reserva, tipoAtendimento) => {
		setAgendamentoId(detalhes.id);
		setHour(hour);
		setEstacaoObj(estacao);
		setIsModalAdicionarAtendimentoVisible(true);
		setIdHorario(idHorario);
		setTipoAtendimentoSelecionado(tipoAtendimento);
		setReservaSelecionada(reserva);
	};
	/*==GETTERS==*/
	/**
	 * Retrieves the agenda for the current day.
	 * 
	 * @returns {void}
	 */
	const carregaEstacoes = () => {
		let params = 'with=sala.responsavel_salas.terapeutum,sala&';
		let filters = updateFilter();
		if (filters.length > 0) {
			params += 'filter[sala_id]=' + filters.join(',');
		}
		EstacaoService.index(params).then(({ data }) => {
			data.sort((a, b) => Number(a.sala.sala) < Number(b.sala.sala) ? -1 : Number(a.sala.sala) > Number(b.sala.sala) ? 1 : 0);
			setEstacaos(data);
			const salasMap = new Map();
			data.forEach((opt) => {
				if (!salasMap.has(opt.sala.sala)) {
					salasMap.set(opt.sala.sala, []);
				}
				salasMap.get(opt.sala.sala).push({
					value: opt.id,
					code: opt.id,
					name: `${opt.estacao} (${opt.sala.sala})`,
				});
			});
			const salasList = Array.from(salasMap.entries()).map(([sala, estacoes]) => ({
				label: sala,
				value: sala,
				code: sala,
				items: estacoes,
			}));
			setSalasList(salasList);
			setEstacaosOriginal(data);
		});
	};
	/**
	 * Retrieves the agenda horarios for a given diaAtualLocal.
	 * @param {string} diaAtualLocal - The current local day.
	 */
	const carregaHorarios = (diaAtualLocal) => {
		api.get('/api/horario-atendimento?filter[dia_semana]=' + diaAtualLocal).then(({ data }) => {
			setHorarios(data);
		});
	};
	/**
	 * Creates new agendamentos based on the given data.
	 * 
	 * @param {Array} data - The data to create agendamentos from.
	 * @returns {void}
	 */
	const createNewAgendamentos = (data) => {
		let pacientes = [];
		let newAgendamentosL = [];
		for (let index = 0; index < data.length; index++) {
			const atendimento = data[index];
			if (pacientes.filter(filter => filter.value === atendimento?.paciente_id).length <= 0) {
				pacientes.push({
					value: atendimento?.paciente_id,
					nome: atendimento?.paciente_nome,
					identificador: atendimento?.paciente_identificador,
					label: `(${atendimento?.paciente_identificador}) ${atendimento?.paciente_nome}`
				});
			}
			if (!newAgendamentosL[atendimento.estacao_id]) {
				newAgendamentosL[atendimento.estacao_id] = {};
			}
			if (!newAgendamentosL[atendimento.estacao_id][atendimento.horarios_atendimento_id]) {
				newAgendamentosL[atendimento.estacao_id][atendimento.horarios_atendimento_id] = [];
			}
			newAgendamentosL[atendimento.estacao_id][atendimento.horarios_atendimento_id].push(
				(atendimento.prop && atendimento.prop.constructor === Array)
					? atendimento.reverse()
					: atendimento
			);
		}
		setAgendamentos(data);
		setNewAgendamentos(newAgendamentosL);
		setAlgoCarregando(false);
	};

	/**
	 * Loads appointments based on a given date.
	 * 
	 * @param {Date} dataSaveLocal - The date to load appointments for.
	 * @returns {Promise<void>} - A promise that resolves when the appointments are loaded.
	 */
	const carregaAgendamentos = useCallback(async (dataSaveLocal) => {
		setNewAgendamentos(null);
		let day = moment(dataSaveLocal).day();
		AgendamentoService.getByWeekDay(day, 'terapia_paciente,terapia_paciente.plano_saude,terapia,especialidade,estacao', 'terapia_paciente.plano_saude_id,plano_saude.nome_reduzido,plano_saude.limite_diario_terapias,terapia.terapia,especialidade.especialidade')
			.then(({ data }) => {
				setHorariosEstacoesDisponiveis(data['horariosEstacoes']);
				estraiIncidenciasUnicas(data['lista']);
				filtrar(data['lista']);
			});
	}, [filtrar]);
	/*==FUNÇÕES DE ATUALIZAÇÃO==*/
	/**
	 * Updates the socket with the provided data.
	 * @param {any} data - The data to be sent through the socket.
	 */
	const atualizaSocket = (data) => {
		if (data) {
			socket.send(JSON.stringify(data));
		}
	};

	const saveScrollPos = () => {
		if (agendamentoTableBodyRef.current) {
			setScrollPos({
				top: agendamentoTableBodyRef.current.scrollTop,
				left: agendamentoTableBodyRef.current.scrollLeft,
			});
		}
	};
	/**
	 * Updates the appointment.
	 *
	 * @param {Object} agendamento - The appointment object to be updated.
	 * @returns {void}
	 */
	const atualizaAgendamento = (agendamento) => {
		setAlgoCarregando(true);
		try {
			// Crie uma cópia do estado atual
			let newAgendamentosLocal = [...agendamentos];
			if (agendamento.droped === true) {
				let index = newAgendamentosLocal.findIndex((a) => a.id === agendamento.id);
				if (index !== -1) {
					newAgendamentosLocal.splice(index, 1);
				}
			}
			let index = newAgendamentosLocal.findIndex((a) => a.id === agendamento.id);
			if (agendamento.deleted_at) {
				if (index !== -1) {
					newAgendamentosLocal.splice(index, 1);
				}
			} else {
				if (index !== -1) {
					newAgendamentosLocal[index] = agendamento;
				} else {
					newAgendamentosLocal.push(agendamento);
				}
			}
			// Atualize o estado com o novo array de agendamentos
			setSocketAgendamento(null);
			setAgendamentos(newAgendamentosLocal);
			createNewAgendamentos(newAgendamentosLocal);
		} catch (error) {
			console.error(error);
		}
		setAlgoCarregando(false);
	};
	/**
	 * Updates the component data.
	 * 
	 * @param {string|null} diaAtualLocal - The current day in local time. If null, it will be set to the value of `dataSaveLocal`.
	 * @param {string|null} dataSaveLocal - The saved data in local time. If null, it will be set to the value of `dataSave`.
	 * @returns {Promise<void>} - A promise that resolves when the component data is updated.
	 */
	const atualizaComponenteData = async (diaAtualLocal = null, dataSaveLocal = null) => {
		saveScrollPos();
		setloading(true);
		setAlgoCarregando(true);
		try {
			if (diaAtualLocal === null && dataSaveLocal === null) {
				carregaAgendamentos(dataSave);
			} else {
				setDiaAtual(diaAtualLocal);
				setDataAtual(dataSaveLocal);
				setDataSave(dataSaveLocal);
				setDataSelecionada(dataSaveLocal.format('YYYY-MM-DD'));
				carregaHorarios(diaAtualLocal);
				carregaAgendamentos(dataSaveLocal.format('YYYY-MM-DD'));
			}
		} catch (error) {
			snackbar.displayMessage('Erro ao atualizar componente.', 'error');
			console.error(error);
		}
	};
	//#endregion
	//#region HANDLES
	const handleOpenModalAgendaPaciente = function () {
		if (agendaModalPacienteRef.current) {
			agendaModalPacienteRef.current.openModal();
		}
	};
	/**
	 * Handles the drop event for a therapist's schedule grid.
	 * 
	 * @param {Object} antigaGridPos - The previous grid position.
	 * @param {Object} novaGridPos - The new grid position.
	 * @param {Object} content - The content being dropped.
	 * @returns {Promise<boolean>} - A promise that resolves to true if the drop event was successful, or false otherwise.
	 */
	const handleDrop = useCallback(async (antigaGridPos, novaGridPos, content) => {
		setAlgoCarregando(true);
		try {
			const hora = memorizedHorarios.filter((filter) => {
				const newHour = moment.utc(filter.horario);
				return newHour.format('HH:mm').includes(novaGridPos.row);
			})[0];
			const hora_id = hora.id;
			const data_inicio = moment.utc(hora.horario).format('YYYY-MM-DD HH:mm:ss') + '.00';
			const data_fim = moment.utc(hora.horario).add(40, 'minutes').format('YYYY-MM-DD HH:mm:ss') + '.00';
			const newObj = {
				...content,
				data_atendimento_inicial: data_inicio,
				data_atendimento_final: data_fim,
				estacao_id: novaGridPos.col,
				horarios_atendimento_id: hora_id
			};
			const result = api.patch('api/agendamento/' + content.id, newObj).then((response) => {
				if (response.status === 200) {
					snackbar.displayMessage('Alterado local e hora do agendamento com sucesso!', 'success');
					atualizaAgendamento({ ...newObj, droped: true }, true);
					atualizaSocket({ ...newObj, droped: true });
					return true;
				}
			}).catch((error) => {
				snackbar.displayMessage('Erro ao alterar o horario ou local do agendamento', 'error');
				console.error(error);
			}).finally(() => {
				setAlgoCarregando(false);
			});
			return result;
		} catch (error) {
			console.error(error);
			snackbar.displayMessage('Erro ao alterar o horario ou local do agendamento', 'error');
			setAlgoCarregando(false);
			return false;
		}
	}, [agendamentos, memorizedHorarios]);
	/**
	 * Handles the click event to move to the next day.
	 * If the next day is Sunday, it skips to Monday.
	 *
	 * @param {moment.Moment} oldDataSave - The previous date to start from. If not provided, defaults to `dataSave`.
	 */
	const handleClickNextDay = (oldDataSave) => {
		const localDataSave = oldDataSave ? oldDataSave : dataSave;
		let dataSaveLocal = moment(localDataSave).add(1, 'days');
		if (dataSaveLocal.day() === 0) {
			dataSaveLocal = moment(dataSave).add(2, 'days');
		}
		let diaAtualLocal = dataSaveLocal.format('e');

		atualizaComponenteData(diaAtualLocal, dataSaveLocal);
	};
	/**
	 * Handles the click event to navigate to the previous day.
	 * If the previous day is Sunday, it navigates to the previous Friday.
	 *
	 * @param {string} oldDataSave - The previous date string to use. If not provided, it defaults to `dataSave`.
	 */
	const handleClickPrevDay = (oldDataSave) => {
		const localDataSave = oldDataSave ? oldDataSave : dataSave;
		let dataSaveLocal = moment(localDataSave).subtract(1, 'days');
		if (dataSaveLocal.day() === 0) {
			dataSaveLocal = moment(dataSave).subtract(2, 'days');
		}
		let diaAtualLocal = dataSaveLocal.format('e');
		atualizaComponenteData(diaAtualLocal, dataSaveLocal);
	};
	const handleSelectDay = (e) => {
		const dia = e.target.value;
		const dataSaveLocal = moment().day(dia);
		atualizaComponenteData(dia, dataSaveLocal);
	};
	/**
	 * Synchronizes the scroll position of a source element with one or two target elements.
	 * 
	 * @param {RefObject} source - The source element to synchronize scroll position from.
	 * @param {RefObject} targetUp - The target element to synchronize vertical scroll position to. Can be null.
	 * @param {RefObject} targetLeft - The target element to synchroniz0e horizontal scroll position to. Can be null.
	 */
	const syncScroll = (source, targetUp, targetLeft) => {
		source = source.current;
		targetUp = targetUp != null ? targetUp.current : null;
		targetLeft = targetLeft != null ? targetLeft.current : null;
		if (targetUp != null)
			targetUp.scrollTop = source?.scrollTop;
		if (targetLeft != null)
			targetLeft.scrollLeft = source?.scrollLeft;
	};
	/**
	 * Handles the export of the component to an image.
	 */
	const handleExportToImage = () => {
		if (elementRef.current === null) {
			return;
		}
		elementRef.current.classList.add('overflow-y-no-scroll');
		elementRef.current.classList.add('bg-branco');
		elementRef.current.classList.add('w-content-i');
		elementRef.current.classList.add('h-content-i');
		agendamentoTableBodyRef.current.classList.add('w-content-i');
		agendamentoTableBodyRef.current.classList.add('h-content-i');
		agendamentoTableBodyRef.current.classList.add('max-w-content-i');
		estacaoTableHeadRef.current.classList.add('max-w-content-i');
		estacaoTableHeadRef.current.classList.add('w-content-i');
		estacaoTableHeadRef.current.classList.add('h-content-i');
		horaTableBodyRef.current.classList.add('h-content-i');
		estacaoTableRef.current.classList.add('w-content-i');
		estacaoTableRef.current.classList.add('h-content-i');
		toPng(elementRef.current)
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.download = `agenda-${esperaMode ? 'espera-' : ''}${dataSave.format('DD_MM_YYYY-HH_MM')}.jpeg`;
				link.href = dataUrl;
				link.click();
			})
			.catch((err) => {
				console.error('Error generating image:', err);
			})
			.finally(() => {
				elementRef.current.classList.remove('overflow-y-no-scroll');
				elementRef.current.classList.remove('bg-branco');
				elementRef.current.classList.remove('w-content-i');
				elementRef.current.classList.remove('h-content-i');
				agendamentoTableBodyRef.current.classList.remove('w-content-i');
				agendamentoTableBodyRef.current.classList.remove('h-content-i');
				agendamentoTableBodyRef.current.classList.remove('max-w-content-i');
				estacaoTableHeadRef.current.classList.remove('max-w-content-i');
				estacaoTableHeadRef.current.classList.remove('w-content-i');
				estacaoTableHeadRef.current.classList.remove('h-content-i');
				horaTableBodyRef.current.classList.remove('h-content-i');
				estacaoTableRef.current.classList.remove('w-content-i');
				estacaoTableRef.current.classList.remove('h-content-i');
			});
	};
	/**
	 * Handles the change of the modal visibility for the legend.
	 */
	const handleChangeModalLegenda = () => {
		setIsModalLegendaVisible((isModalLegendaVisible) => !isModalLegendaVisible);
	};
	/**
	 * Handles the change of the esperaMode state.
	 */
	const handleChangeEsperaMode = () => {
		setEsperaMode((esperaMode) => !esperaMode);
	};
	//#region USE EFFECT
	useEffect(() => {
		if (isModalAdicionarAtendimentoVisible) {
			if (fullScreenState) {
				exitFullScreen();
			}
		} else {
			if (fullScreenState) {
				enterFullscreen();
			}
		}
	}, [isModalAdicionarAtendimentoVisible]);
	useEffect(() => {
		setStyles(
			esperaMode
				? fullScreenState
					? stylesEsperaFullscreen : stylesEspera
				: fullScreenState
					? stylesNormalFullscreen
					: stylesNormal
		);
	}, [esperaMode, fullScreenState]);
	useEffect(() => {
		if (socketAgendamento) {
			atualizaAgendamento(socketAgendamento);
		}
	}, [socketAgendamento]);
	useEffect(() => {
		if (newAgendamentos?.length > 0) {
			filtrar();
			if (estacaoSelecionadaOpt?.length > 0) {
				let estacoesFiltradas = estacaosOriginal.filter((e) => {
					let estacao = e.id;
					let result = estacaoSelecionadaOpt.filter((filtro) => Number(filtro) === Number(estacao))?.length > 0;
					return result;
				});
				setEstacaos(estacoesFiltradas);
			} else {
				setEstacaos(estacaosOriginal);
			}
		}
	}, [pacienteSelecionadoOpt, estacaoSelecionadaOpt, terapiasSelecionadaOpt, especialidadesSelecionadaOpt, terapeutaSelecionadoOpt, filtroIncidencia]);
	/**
	 * Function called when the component is loaded.
	 * It collects the lists, gets the agenda for the current day,
	 * loads the appointments for the selected date.
	 */
	function onload() {
		/* COLETA DAS LISTAS */
		carregaEstacoes();
		carregaHorarios(diaAtual);
		carregaAgendamentos(dataSelecionada);
	}
	/* WEBSOCKET */
	useEffect(() => {
		if (!socket) {
			let urlWS;
			if (window.location.hostname === 'localhost') {
				urlWS = 'ws://localhost:6001/ws/agenda';
			} else {
				urlWS = 'wss://sistema.neurointensiva.com/ws/agenda';
			}
			const socketLocal = new WebSocket(urlWS);
			setSocket(socketLocal);

			socketLocal.addEventListener('open', function () {
				setSocketState(true);
				//console.info('Conectado ao servidor');
			});

			socketLocal.addEventListener('close', function () {
				setSocketState(false);
				//console.info('Desconectado do servidor');
			});

			socketLocal.addEventListener('message', function (event) {
				try {
					if (event.data !== null && event.data !== undefined && event.data !== '') {
						const data = (JSON.parse(event.data));
						setSocketAgendamento(data);
					}
				} catch (error) {
					console.error('Erro ao parsear a mensagem: ', error);
				}
			});

			socketLocal.addEventListener('error', function (event) {
				console.error('Erro: ', event);
			});
		}
	}, [socket]);
	useEffect(() => {
		const handleAtalhos = (e) => {
			if (e.key === 'Escape') {
				setfullScreenState(false);
				setExpand(false);
			}
		};
		document.addEventListener('keydown', handleAtalhos);
		document.addEventListener('mousedown', (e) => {
			fechaFiltro(e);
		});
		onload();
		return () => {
			document.removeEventListener('mousedown', (e) => {
				fechaFiltro(e);
			});
			document.removeEventListener('keydown', handleAtalhos);
		};
	}, []);
	/**
	 * A memoized function to filter patient options based on the input value.
	 *
	 * @param {Object} option - The option object containing patient data.
	 * @param {string} inputValue - The current input value to filter options.
	 * @returns {boolean} - Returns true if the option matches the input value, otherwise false.
	 *
	 * The function checks if the input value is present in the patient's identifier or name.
	 * If the input value is found in any patient's identifier in the pacientesList, it prioritizes matching the identifier.
	 * Otherwise, it matches the patient's name.
	 */
	const filterOptionPaciente = useMemo(() => (option, inputValue) => {
		if (!inputValue) return true;

		const lowerInputValue = inputValue.toLowerCase();
		const identificador = option.data.identificador.toLowerCase();
		const nome = option.data.nome.toLowerCase();
		const identificadorResult = identificador.includes(lowerInputValue);

		if (memoizedPacientesOptions.some(opt => opt.identificador.toLowerCase().includes(lowerInputValue))) {
			return identificadorResult;
		}
		return nome.includes(lowerInputValue);
	}, [memoizedPacientesOptions]);
	//#endregion
	//#region HTML
	return (
		<Container>
			{loading === true ?
				<Carregamento></Carregamento>
				: null
			}
			<div style={styles.containerFlexColumn}>
				<div ref={tableRef} style={{ backgroundColor: '#f6f6f9 !important', maxWidth: '100vw' }}>
					<div style={styles.tr0}>
						<div ref={filtroDivRef} className='div-filter-style justify-content-start align-center' style={styles.filtro}>
							<div id="div-expand-filter" className="w-100per pointer d-flex justify-between align-center" style={{ height: '40px' }}
								onClick={({ target }) => {
									if (target === document.getElementById('div-expand-filter')) {
										expandFiltro();
									}
								}}>
								<div id="blank" style={{ height: 'fit-content' }} onClick={(e) => expandFiltro()}>
									{!expand ? 'Expandir' : 'Fechar'} Filtro
								</div>
								<div className='flex-nowrap'>
									{pacienteSelecionadoOpt?.value !== undefined || terapiasSelecionadaOpt?.length > 0 || especialidadesSelecionadaOpt?.length > 0 || estacaoSelecionadaOpt?.length > 0 || terapeutaSelecionadoOpt?.value !== undefined || filtroIncidencia
										? <FaBroom color="yellow" id="botao-limpa-filtro" size={22} onClick={() => { handleLimpaFiltros(); }} />
										: <div width='22' height='22'></div>
									}
									<AiFillCaretDown onClick={(e) => expandFiltro()} className='pointer' color="white" size={22} style={{ marginLeft: '15px' }} />
								</div>
							</div>

							<Form className='div-expand-over' style={{ backgroundColor: 'rgb(149, 134, 172)', width: '50vw', marginLeft: '-16px', ...expand ? {} : { display: 'none' } }}>
								<div style={DivContentDisplay} className='d-flex flex-col'>
									<div className='row'>
										<div className="col-6 row">
											<div className="col-12">
												<label className="label-filtro">Paciente:</label>
												<div className="d-flex justify-around align-center">
													<Select
														name={'pacientes'}
														options={memoizedPacientesOptions}
														value={pacienteSelecionadoOpt}
														filterOption={filterOptionPaciente}
														onChange={(e) => setPacienteSelecionadoOpt({ value: e.value, label: e.label })} />
													<AiOutlineClear className="pointer m-2" onClick={() => setPacienteSelecionadoOpt([])} size={25} />
												</div>
											</div>
											<div className="col-12">
												<label className="label-filtro">Terapeuta:</label>
												<div className="d-flex justify-around align-center">
													<Select
														name={'terapeutas'}
														options={memoizedTerapeutasOptions}
														value={terapeutaSelecionadoOpt}
														onChange={(e) => setTerapeutaSelecionadoOpt({ value: e.value, label: e.label })} />
													<AiOutlineClear className="pointer m-2" onClick={() => setTerapeutaSelecionadoOpt([])} size={25} />
												</div>
											</div>
											<div className="col-12">
												<label className="label-filtro">Terapias:</label>
												<div className="d-flex justify-around align-center">
													<Select
														name={'terapias'}
														options={memoizedTerapiasOptions}
														value={terapiasSelecionadaOpt}
														onChange={(e) => setTerapiasSelecionadaOpt(e)}
														isMulti={true} />
													<AiOutlineClear className="pointer m-2" onClick={() => setTerapiasSelecionadaOpt([])} size={25} />
												</div>
											</div>
											<div className="col-12">
												<label className="label-filtro">Especialidades:</label>
												<div className="d-flex justify-around align-center">
													<Select
														name={'especialidades'}
														options={memoizedEspecialidadesOptions}
														value={especialidadesSelecionadaOpt}
														onChange={(e) => setEspecialidadesSelecionadaOpt(e)}
														isMulti={true} />
													<AiOutlineClear className="pointer m-2" onClick={() => setEspecialidadesSelecionadaOpt([])} size={25} />
												</div>
											</div>
											<div className="col-12">
												<label className="label-filtro">Pesquisar:</label>
												<div className="d-flex justify-around align-center">
													<Input
														name={'nome'}
														value={filtroIncidencia}
														onChange={(e) => {
															setFiltroIncidencia(e.target.value);
															setPacienteSelecionadoOpt([]);
															setEstacaoSelecionadaOpt([]);
															setEstacaoSelecionadaOptTemp([]);
														}}
														placeholder={'Digite os termos da pesquisa(Identificador, nome)...'} />
													<AiOutlineClear className="pointer m-2" onClick={() => setFiltroIncidencia('')} size={25} />
												</div>
											</div>
										</div>
										<div className="col-6 row">
											<div className="col-12">
												<label className="label-filtro">Salas:</label>
												<div className="d-flex justify-around align-center">
													<MultiSelect
														id={'salas'}
														name={'salas'}
														options={salasList}
														value={estacaoSelecionadaOptTemp}
														onChange={(e) => setEstacaoSelecionadaOptTemp(e.value)}
													/>
													<AiOutlineClear className="pointer m-2" onClick={(e) => {
														unstable_batchedUpdates(() => {
															setEstacaoSelecionadaOpt([]);
															setEstacaoSelecionadaOptTemp([]);
														});
													}} size={25} />
													<FiFilter className="pointer m-2" onClick={() => setEstacaoSelecionadaOpt(estacaoSelecionadaOptTemp)} size={25} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</Form>
						</div>
						<div id='div-navegacao-dias' className='p-1 justify-content-around d-flex align-center' style={{ width: '300px' }}>
							<AiOutlineLeft id='icone-navegacao-atras' className='pointer' color="black" size={30} onClick={() => handleClickPrevDay()} />
							<div id='input-navegacao-livre'>
								<select className='texto-navegacao'
									/* title={`${dataAtual.format('DD/MM/YYYY')}`} */
									style={{ border: 'none', backgroundColor: 'transparent', padding: '0', margin: '0' }}
									value={diaAtual}
									onChange={handleSelectDay}>
									{dayName.map((dia, index) => {
										return <option key={index} value={index}>{dia}</option>;
									})}
								</select>
								<br></br>
							</div>
							<AiOutlineRight id='icone-navegacao-frente' className='pointer' color="black" size={30} onClick={() => handleClickNextDay()} />
						</div>
						<div id='caixa-de-ferramentas' className='d-inline-flex justify-content-end align-center' style={{ width: '400px' }}>
							<div className='d-flex justify-center align-center'>
								<span className='font-roxo-claro3'>{agendamentos?.length}</span>
							</div>
							{/* SWITCH AGENDA ESPERA */}
							<div id='switch-agenda-espera' className="form-check form-switch me-2">
								<input className="form-check-input" type="checkbox" role="switch" id="esperaCheck" checked={esperaMode} onChange={() => handleChangeEsperaMode()} />
								<label className="form-check-label" htmlFor="esperaCheck">Agenda Espera</label>
							</div>
							{/* BOTÃO EXPORT IMAGEM */}
							<BsDownload id='botao-download' className='pointer' size={30} onClick={() => handleExportToImage()} style={{ marginRight: '10px' }} />
							{/* BOTÃO AGENDA PACIENTE */}
							{pacienteSelecionadoOpt?.value !== undefined &&
								<button id='botao-agenda-paciente' type='button' className={'btn-outline pointer mr-10px'} onClick={() => handleOpenModalAgendaPaciente()} >
									<FiRefreshCcw className={`font-roxo-claro3`} size={30} />
								</button>
							}
							{/* BOTÃO ATUALIZA AGENDA */}
							<button id='botao-refresh' type='button' className={'btn-outline roda-hover pointer mr-10px'} onClick={() => atualizaComponenteData()} >
								<FiRefreshCcw className={`font-roxo-claro3${algoCarregando ? ' rodando' : ''}`} size={30} />
							</button>
							{/* BOTÃO LEGENDA */}
							<button id='botao-legenda' onClick={() => handleChangeModalLegenda()} className='btn-outline' style={{ marginRight: '10px' }}>
								<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
									<circle cx="12" cy="12" r="10" fill="none" stroke="rgb(149, 134, 172)" strokeWidth="2" />
									<text x="12" y="17" textAnchor="middle" fontSize="14" fill="rgb(149, 134, 172)" fontWeight='bold'>?</text>
								</svg>
							</button>
							{/* BOTÃO FULLSCREEN */}
							<button id='botao-fullscreen' onClick={(e) => fullScreen()}>
								<BsFullscreen size={25} color='rgb(149, 134, 172)' stroke="rgb(149, 134, 172)" strokeWidth="2" fontWeight='bold' fontSize='14' />
							</button>
						</div>
					</div>
					<div ref={elementRef} id='div-agenda' className='d-inline-flex'>
						<table className='table-agenda-terapeuta' id='label-e-horas' style={styles.table}>
							<thead style={styles.thead}>
								{/* ETIQUETAS */}
								<tr key={'linha-salas'} style={styles.thead.trS}>
									<th key={'coluna-salas'} style={styles.tr1}>Salas</th>
								</tr>
								<tr key={'linha-estacoes'} style={styles.thead.trE}>
									<th key={'coluna-estacoes'} style={styles.tr1}>Estações</th>
								</tr>
								<tr key={'linha-responsaveis'} style={styles.thead.trR}>
									<th key={'coluna-responsaveis'} style={styles.tr1}>Resp.</th>
								</tr>
							</thead>
							{/* HORAS */}
							<tbody id='tbody-horarios' ref={horaTableBodyRef} style={styles.tbody0} onScrollCapture={(e) => { syncScroll(horaTableBodyRef, agendamentoTableBodyRef, null); }}>
								{memorizedHorarios.map((horario, index) => {
									let tarde = (
										Number(horario.horario.split('T')[1].split(':')[0]) >= 17
										&&
										(Number(horario.horario.split('T')[1].split(':')[0]) !== 17 || (Number(horario.horario.split('T')[1].split(':')[0]) === 17 && Number(horario.horario.split('T')[1].split(':')[1]) >= 40))
									);
									return (
										<tr key={'linha-horario-' + index} style={styles.tbody.tr}>
											<td key={'coluna-horario-' + index} id={'linha-horario-' + horario.id} style={tarde ? { ...styles.trHora, ...styles.tarde } : { ...styles.trHora }} align={'center'}>{moment.utc(horario.horario).format('HH:mm')}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						<table ref={estacaoTableRef} className='table-agenda-terapeuta' id='salas-e-agendamentos' style={styles.table}>
							<thead id='thead-sala-estacao' ref={estacaoTableHeadRef} style={styles.thead} onScrollCapture={(e) => syncScroll(estacaoTableHeadRef, null, agendamentoTableBodyRef)}>
								{/* SALAS */}
								<tr key={'linha-sala-cont'} style={styles.thead.tr}>
									{memorizedEstacaos.map((estacao, index) => {
										return <td key={'coluna-sala-cont-' + index} id={'coluna-sala-cont-' + estacao.id} align={'center'} style={styles.tr2} className='font-14px'>
											{estacao.sala.sala}
										</td>;
									})}
								</tr>
								{/* ESTAÇÕES */}
								<tr key={'linha-estacao-cont'} style={styles.thead.tr}>
									{memorizedEstacaos.map((estacao, index) => {
										return <td key={'coluna-estacao-cont-' + index} id={'coluna-estacao-cont-' + estacao.id} align={'center'} style={styles.tr2} className='font-14px pointer' onClick={() => {
											setEstacaoSelecionadaOpt([estacao.id]);
										}}>
											{estacao.estacao}</td>;
									})}
								</tr>
								{/* RESPONSÁVEIS */}
								<tr id={'linha-responsavel-cont'} key={'linha-responsavel-cont'} style={styles.thead.tr}>
									{memorizedEstacaos.map((estacao, index) => {
										return (estacao.sala.responsavel_salas.length > 0
											? <td key={'coluna-responsavel-cont-' + index} id={'coluna-responsavel-cont-' + estacao.id} align={'center'} style={{ ...styles.tr2, height: '55px' }} className='font-14px'>{estacao.sala.responsavel_salas.map(responsaveis => responsaveis.terapeutum.nome.substring(0, 15) + (responsaveis.terapeutum.nome.length > 15 ? '...' : '')).join(', ')}</td>
											: <td key={'coluna-responsavel-cont-' + index} id={'coluna-responsavel-cont-' + estacao.id} align={'center'} style={{ ...styles.tr2, height: '55px' }} className='font-14px'>Sem responsável</td>
										);
									})}
								</tr>
							</thead>
							{/* AGENDAMENTOS */}
							<TbodyAgendaSemanal
								id='tbody-agendamentos'
								ref={agendamentoTableBodyRef}
								onScrollCapture={() => { syncScroll(agendamentoTableBodyRef, horaTableBodyRef, estacaoTableHeadRef); }}
								memorizedHorarios={memorizedHorarios}
								memorizedEstacaos={memorizedEstacaos}
								newAgendamentos={newAgendamentos}
								styles={styles}
								getLegendaAgendamento={getLegendaAgendamento}
								setMostrandoReserva={setMostrandoReserva}
								agendamentos={agendamentos}
								styles_filtred={styles_filtred}
								styles_escuro={styles_escuro}
								filtered={filtered}
								permissoes={permissoes}
								setTerapeutaSelecionadoOpt={setTerapeutaSelecionadoOpt}
								aprovaReserva={aprovaReserva}
								excluirAgendamento={excluirAgendamento}
								modalEditar={modalEditar}
								modalDetalhesAgendamento={modalDetalhesAgendamento}
								esperaMode={esperaMode}
								usuario={usuario}
								setPacienteSelecionadoOpt={setPacienteSelecionadoOpt}
								handleOnMouseEnter={handleOnMouseEnter}
								handleOnMouseLeave={handleOnMouseLeave}
								handleOpenAgendamento={handleOpenAgendamento}
								endLoad={atualizaScrollTbody}
								showAgendamentos={(memorizedHorarios?.length > 0 && memorizedEstacaos?.length > 0 && agendamentos && newAgendamentos && !algoCarregando)}
								horariosEstacoesDisponiveis={horariosEstacoesDisponiveis}
							/>
							{/* CARREGANDO AGENDAMENTOS */}
						</table>
					</div>
				</div>
			</div>
			{/*#region MODAIS */}
			{isModalAdicionarAtendimentoVisible
				&& <AdicionarAgendamento
					/* LISTAS */
					tiposAtendimentoOptions={memoizedTiposAtendimentoOptions}
					modalidadesOptions={memoizedModalidadesOptions}
					/* DADOS da TELA */
					hour={hour}
					estacao={estacaoObj}
					reservaSelecionada={reservaSelecionada}
					tipoAtendimentoSelecionado={tipoAtendimentoSelecionado}
					agendamentoId={agendamentoId}
					idHorario={idHorario}
					esperaMode={esperaMode}

					date={dataSave.format('YYYY-MM-DD')}/* DATA PARA SALVAMENTO */
					agendamentos={agendamentos}/* LISTA DE AGENDAMENTOS PARA TESTES */
					/* FUNÇÕES PARA ATUALIZAÇÃO e FECHAMENTO */
					updateAgenda={() => { atualizaComponenteData(); }}
					onClose={(data) => {
						setIsModalAdicionarAtendimentoVisible(false);
						setAgendamentoId(null);
						setIdHorario(null);
						atualizaSocket(data);
					}}
				/>
			}

			{isModalLegendaVisible && <LegendaDefault onClose={() => handleChangeModalLegenda()} />}
			{isModalResponsavelVisible && <Responsavel onClose={() => setIsModalResponsavelVisible(false)} />}
			{isModalDetalhesDoAtendimentoVisible && <DetalhesDoAgendamento agendamentoId={agendamentoId} agendaButtons={true} temDetalhes={false} onClose={() => { setIsModalDetalhesDoAtendimentoVisible(false); setAgendamentoId(null); }} />}
			{/*#endregion */}
			<LiveBadge sincronizado={socketState} texto={false} />
			<TutorialBadge
				steps={
					[
						{ text: 'Bem vindo! Essa é a tela de Agendamento! (Aperte em próximo para prosseguir ou no \'X\' para sair' },
						{ selector: '#div-expand-filter', text: 'Esse é o filtro, basícamente todas as telas tem um, e você pode apertar para abrir as opções de filtros.' },
						{ selector: '#div-navegacao-dias', text: 'Aqui você pode navegar entre os dias da semana, para agendar os atendimentos:' },
						{ selector: ['#icone-navegacao-atras', '#icone-navegacao-frente'], text: 'Utilizando esses botões, você pode navegar um dia por vez, para acessar um dia da semana anterior, ou superior ao dia atual!' },
						{ selector: '#input-navegacao-livre', text: 'Utilizando esta caixa de seleção, você pode selecionar livremente o dia da semana.' },
						{ selector: '#caixa-de-ferramentas', text: 'Aqui você pode encontrar funcionalidade importantes da agenda, são elas:' },
						{ selector: '#switch-agenda-espera', text: 'Agenda de Espera: Aqui você mostra a Agenda de Espera. A unica agenda onde pode ser agendado sem terapeuta. Todas as funcionalidades dessa agenda são iguais as da outra.' },
						{ selector: '#botao-download', text: 'Download: Esse é o botão utilizado para fazer o download em imagem da agenda. Todos os filtros aplicados são utilizados mesmo na exportação.' },
						{ selector: '#botao-refresh', text: 'Atualizar: Esse é o botão utilizado para atualizar a agenda, sem recarregar toda a tela novamente!' },
						{ selector: '#botao-legenda', text: 'Legenda: Aqui você pode visualizar a legenda das cores e indicações da agenda.' },
						{ selector: '#botao-fullscreen', text: 'Fullscreen: Nesse botão, você pode habilitar o modo fullscreen da agenda, que deixa-rá na tela, apenas agenda, para facilitar a visualização.' },
						{ selector: '#div-agenda', text: 'Agora vamos falar da Agenda' },
						{ selector: '#tbody-horarios', text: 'Aqui podemos ver os horários. Cada linha é um horário.' },
						{ selector: '#thead-sala-estacao', text: 'Nesse local, temos as salas e estações. Cada coluna é uma estação.' },
						{ selector: '#linha-responsavel-cont', text: 'Aqui você pode ver, qual é o responsável da Sala.' },
						{ selector: '#tbody-grade-de-agendamentos', text: 'Essa é a região de cadastro, cada quadrado desse representa um horário e uma estação(Sala e Estação). Clicando em um quadrado desses, a inclusão de agendamento aparecerá para você.' },
						{ text: <span>É basicamente isso, se você ainda tiver dúvidas, pode apertar novamente no botão de tutorial no canto inferior da tela, ou se preferir, <a href={`dashboard/${user.tipo_user}/documentacao`}>acesse nossa documentação</a>!</span> },
					]
				} />
			<AgendaModalPaciente ref={agendaModalPacienteRef} paciente_id={pacienteSelecionadoOpt?.value} temDetalhes={false} />
		</Container>
	);
	//#endregion
}