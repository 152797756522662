import React, { useState, useEffect, useRef } from 'react';
import { Modal } from '../../../../../components/Modais/styles';
import { ModalContainer } from '../../../../../components/Modais/PacienteAltasModal/styles';
import { Form } from '@unform/web';
import ModalAnamnese from '../ModalAnamnese';
import ModalAgendaPaciente from '../ModalAgendaPaciente';
import { useNavigate } from 'react-router-dom';
import Documentacao from '../../../Dashboard/Modal/Documentacao';
import ModalInformacoesComplementares from '../../../../../components/Modais/InformacoesComplementares';
import { useAuth } from '../../../../../context/auth';
import api from '../../../../../services/api';
import ModalAvaliacoesPaciente from '../ModalAvaliacoes';
import { IoArrowUndo } from 'react-icons/io5';
import AgendaPacienteModal from '../../../../../components/Modais/Pacientes/AgendaModal';
import Button from '../../../../../components/Button';

function ModalInformacoesPaciente({ onClose, paciente, pacienteIdentList }) {
    const modalAgendaPacienteRef = useRef();

    const [agendaModal, setAgendaModal] = useState(false);
    const [anamneseModal, setAnamneseModal] = useState(false);
    const [avaliacoesModal, setAvaliacoesModal] = useState(false);
    const [especialidades, setEspecialidades] = useState([]);
    const [pacientes, setPacientes] = useState([]);
    const [terapias, setTerapias] = useState([]);
    const [docsModal, setDocsModal] = useState(false);

    const [infoComplModal, setInfoComplModal] = useState(false);
    const [infoComplementar, setInfoComplementar] = useState([]);

    const id = 'modal-terapeuta-informacoes-paciente';
    const user = useAuth();

    let navigate = useNavigate();

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    const getTerapiasEspecialidades = (terapeuta_id) => {
        try {
            api.get(`api/atendimento?group_by=paciente_id&with=paciente,terapium&filter[terapeuta_id]=${terapeuta_id}`).then(({ data }) => {
                let terapiaOptions = [];
                let pacienteOptions = [];
                let especialidadeOptions = [];
                data.forEach((atendimento) => {
                    pacienteOptions.push({
                        value: atendimento.paciente_id,
                        label: atendimento.paciente.nome,
                        plano: atendimento.paciente.id_plano_saude
                    });
                    terapiaOptions.push({
                        value: atendimento.terapia_id,
                        label: atendimento.terapium.terapia,
                    });
                    especialidadeOptions.push({
                        value: atendimento.especialidade_id,
                        label: atendimento.terapium.especialidade,
                    });
                });
                setPacientes(pacienteOptions);
                setTerapias(terapiaOptions);
                setEspecialidades(especialidadeOptions);
            });
        } catch (e) {
            console.error('Erro ao obter lista de terapias e especialidades - ', e);
        }
    };

    useEffect(() => {
        getTerapiasEspecialidades(user.user.terapeuta[0].id);
        setInfoComplementar(paciente.info_complementar);
    }, []);

    return (
        <>
            <Modal id={id} onClick={handleOutsideClick}>
                <ModalContainer style={{ height: 'auto', maxHeight: '70%', width: window.innerWidth > 600 ? '40%' : '100%' }}>
                    <div style={{ position: 'relative' }}>
                        <label style={{
                            opacity: 0.5, cursor: 'pointer', position: 'absolute',
                            right: '35px', fontSize: '25px', top: '-10px'
                        }} onClick={() => onClose()}>
                            x
                        </label>
                    </div>
                    <div className='d-flex align-items.center justify-content-center'>
                        <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}>Informações do Paciente</label>
                    </div>
                    <Form>
                        <div className='d-flex flex-column align-items.center justify-content-evenly ps-4 pe-4'>
                            <Button type={'button'} onClick={() => navigate('/dashboard/terapeuta/pacientes/perfil/' + paciente.id, { state: { pacienteIdentList } })} className='mb-2'>
                                <IoArrowUndo></IoArrowUndo> &nbsp; Cadastro
                            </Button>
                            <Button type={'button'} onClick={() => navigate('/dashboard/terapeuta/pacientes/prontuarios/' + paciente.id)} className='mb-2'>
                                <IoArrowUndo></IoArrowUndo> &nbsp; Prontuário
                            </Button>
                            <Button type={'button'} onClick={() => modalAgendaPacienteRef.current.openModal(paciente.id)} className='mb-2'>
                                Agenda
                            </Button>
                            {/* Botões com funcionalidades a terminal
                            <button onClick={() => setAvaliacoesModal(true)} className='mb-2' style={{ backgroundColor: '#7340BD', color: '#FFFFFF', border: "none", padding: "2%", borderRadius: "10px", }} >
                                Avaliação
                            </button>
                            <button onClick={() => setInfoComplModal(true)} className='mb-2' style={{ backgroundColor: '#7340BD', color: '#FFFFFF', border: "none", padding: "2%", borderRadius: "10px", }} >
                                Informações adicionais
                            </button>
                            <button onClick={() => setAnamneseModal(true)} className='mb-2' style={{ backgroundColor: '#7340BD', color: '#FFFFFF', border: "none", padding: "2%", borderRadius: "10px", }} >
                                Anamnese
                            </button>
                            <button onClick={() => setDocsModal(true)} className='mb-2' style={{ backgroundColor: '#7340BD', color: '#FFFFFF', border: "none", padding: "2%", borderRadius: "10px", }} >
                                Solicitação de documentação
                            </button>
                            */}
                        </div>
                    </Form>
                </ModalContainer>
            </Modal>
            {avaliacoesModal ? <ModalAvaliacoesPaciente onClose={() => setAvaliacoesModal(false)} paciente={paciente} /> : ''}
            {agendaModal ? <ModalAgendaPaciente onClose={() => setAgendaModal(false)} paciente={paciente} /> : ''}
            {anamneseModal ? <ModalAnamnese onClose={() => setAnamneseModal(false)} paciente={paciente} /> : ''}
            {docsModal ? <Documentacao onClose={() => setDocsModal(false)} paciente={paciente} especialidades={especialidades} terapias={terapias} pacientes={pacientes} /> : ''}
            {infoComplModal ? <ModalInformacoesComplementares onClose={() => setInfoComplModal(false)} paciente={paciente} infoComplementar={infoComplementar} setInfoComplementar={setInfoComplementar} /> : ''}
            <AgendaPacienteModal ref={modalAgendaPacienteRef} paciente_id={paciente.id}></AgendaPacienteModal>
        </>
    );
}

export default ModalInformacoesPaciente;