import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { BalaoDicaSeta, BalaoDicaStyle, BotaoFechar, ConteudoBalao, SvgSeta, TituloBalao } from './styles';
import { BsQuestionCircle } from 'react-icons/bs';

/**
 * Componente BalaoDica
 * 
 * @param {Object} props - Propriedades do componente
 * @param {HTMLElement|string|Object} props.elementoAncora - Elemento ao qual o balão será ancorado. Pode ser um HTMLElement, uma string com o ID do elemento, ou um objeto.
 * @param {boolean} [props.abertoPadrao=true] - Define se o balão estará aberto por padrão.
 * @param {string} [props.titulo='Dica'] - Título do balão.
 * @param {boolean} [props.apenasUmaVez=true] - Define se o balão será mostrado apenas uma vez.
 * @param {React.ReactNode} props.children - Conteúdo do balão.
 * @param {React.Ref} ref - Referência para o componente.
 * 
 * @returns {JSX.Element} O componente BalaoDica.
 */
const BalaoDica = forwardRef(({ elementoAncora, abertoPadrao = true, titulo = 'Dica', apenasUmaVez = true, children }, ref) => {
    //#region VARIAVEIS
    const balaoRef = useRef(null);
    const setaRef = useRef(null);
    const [jaAbriu, setJaAbriu] = useState(false);
    const [balaoMostrando, setBalaoMostrando] = useState(false);
    const [style, setStyle] = useState({
        opacity: 0,
        visibility: 'hidden',
        top: 0,
        left: 0,
    });
    const [styleSeta, setStyleSeta] = useState({
        opacity: 0,
        visibility: 'hidden',
        top: 0,
        left: 0,
    });
    const [domElementoAncora, setDomElementoAncora] = useState(null);
    //#endregion

    //#region FUNCOES
    const calculaPosicaoBalao = () => {
        if (!balaoRef.current || !domElementoAncora) return;
        if (!setaRef.current || !balaoRef.current) return;
        const plusTop = -balaoRef.current.clientHeight - 10;
        const plusLeft = -balaoRef.current.clientWidth + (balaoRef.current.clientWidth * 0.03);
        const rect = domElementoAncora.getBoundingClientRect();
        const balaoTop = rect.top + window.scrollY + plusTop;
        const balaoLeft = rect.left + window.scrollX + plusLeft;
        setStyle(prev => ({
            ...prev,
            top: balaoTop,
            left: balaoLeft,
        }));
        const rectPraSeta = balaoRef.current.getBoundingClientRect();
        const setaTop = balaoTop + rectPraSeta.height - 2;
        const setaLeft = balaoLeft + (rectPraSeta.width - (rectPraSeta.width * 0.06));
        setStyleSeta(prev => ({
            ...prev,
            top: setaTop,
            left: setaLeft,
        }));
    };
    //#endregion

    //#region HANDLES
    /**
     * Função para fechar o balão de dica.
     * Define o estado `balaoMostrando` como falso, ocultando o balão.
     */
    const handleFechar = () => {
        setBalaoMostrando(false);
    };
    /**
     * Função para abrir o balão de dica.
     * Define o estado `balaoMostrando` como verdadeiro.
     */
    const handleAbrir = () => {
        setBalaoMostrando(true);
    };
    useImperativeHandle(ref, () => ({
        open: () => handleAbrir(),
        close: () => handleFechar(),
    }));
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        setBalaoMostrando(abertoPadrao);
    }, [abertoPadrao]);
    useEffect(() => {
        if (balaoMostrando) {
            if (apenasUmaVez && jaAbriu) {
                setBalaoMostrando(false);
            } else {
                setStyle(prev => ({ ...prev, opacity: 1, visibility: 'visible' }));
                setStyleSeta(prev => ({ ...prev, opacity: 1, visibility: 'visible' }));
                setJaAbriu(true);
            }
        } else {
            setStyle(prev => ({ ...prev, opacity: 0, visibility: 'hidden' }));
            setStyleSeta(prev => ({ ...prev, opacity: 0, visibility: 'hidden' }));
        }
    }, [balaoMostrando]);
    useEffect(() => {
        if (elementoAncora) {
            if (elementoAncora instanceof HTMLElement) {
                setDomElementoAncora(elementoAncora);
            } else if (typeof elementoAncora === 'string') {
                const element = document.getElementById(elementoAncora);
                if (element) {
                    setDomElementoAncora(element);
                }
            } else if (typeof elementoAncora === 'object') {
                setDomElementoAncora(elementoAncora);
            }
        }
    }, [elementoAncora]);
    useEffect(() => {
        calculaPosicaoBalao();
    }, [domElementoAncora, balaoRef, setaRef, balaoMostrando]);
    //#endregion
    //#region HTML
    return (
        <React.Fragment>
            <BalaoDicaStyle ref={balaoRef} style={style} onClick={handleFechar}
                onMouseEnter={() => setStyleSeta(prev => ({ ...prev, zIndex: 9996 }))}
                onMouseLeave={() => setStyleSeta(prev => ({ ...prev, zIndex: 9999 }))}
            >
                <TituloBalao>
                    {titulo}
                    <BsQuestionCircle size={14} className='ms-2 mb-1' />
                </TituloBalao>
                <ConteudoBalao>
                    {children}
                </ConteudoBalao>
            </BalaoDicaStyle>
            <BalaoDicaSeta ref={setaRef} style={styleSeta}>
                <SvgSeta viewBox="0 0 16 30" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0 L8 30 L16 0 Z" fill="currentColor" stroke="none" />
                    <path d="M0 0 L8 30 L16 0" fill="none" stroke="currentStroke" strokeWidth="1" />
                </SvgSeta>
            </BalaoDicaSeta>
        </React.Fragment >
    );
    //#endregion
});

export default BalaoDica;