import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronUp } from 'react-icons/fa';
import useIcons from 'context/icons';

/**
 * Componente de SubMenu.
 * Detecta se o dispositivo é um iPhone e desativa a funcionalidade de colapsar se for.
 * 
 * @param {string} selectedItem - Item selecionado atualmente.
 * @param {Array} links - Links do submenu.
 * @param {boolean} defaultClose - Define se o submenu deve começar fechado.
 * @param {Function} onToggleMinimized - Função de callback para informar se o submenu está minimizado.
 * @returns {JSX.Element} O componente SubMenu.
 */
export default function SubMenu({ selectedItem, links, sideMode = false }) {
    const submenuRef = useRef(null);
    const { getIcon } = useIcons();

    const selected = {
        marginRight: 20,
        marginTop: 10,
        color: '#7340BD',
        fontWeight: 'bold'
    };

    const unSelected = {
        marginRight: 20,
        marginTop: 10,
        color: '#000',
        cursor: 'pointer'
    };

    return (
        <div className={`${sideMode ? 'side-' : 'div-'}submenu-container`}>
            {/* {!isIPhone && (
                <button onClick={handleToggle} className="toggle-button hl-laranja mt-1">
                    <FaChevronUp style={{
                        transform: isMinimized ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s'
                    }} />
                </button>
            )} */}
            <div ref={submenuRef} className={`${sideMode ? 'side-' : ''}submenu`}>
                {links?.map((link, index) => {
                    let array = link.url.split('/');
                    let item = array[array.length - 1];
                    return (
                        <Link className={`submenu-item ${(selectedItem === item) ? 'selected' : ''}`} key={index} to={link.url}>
                            <span>
                                {getIcon(link.icon, 20, true)}
                            </span>
                            <label>{link.label}</label>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}