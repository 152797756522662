import styled from 'styled-components';

export const Header = styled.div`
    grid-area: header;
    margin-bottom: 15px;
    margin-top: 20px;
    text-align: center;
`;

export const IconBotao = styled.button`
    height: 90px;
    background-color: #6A3BAF;
    cursor: pointer;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: white;
    margin: 5px;
    width: 120px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
`;
export const Botao = styled.button`
    background-color: #6A3BAF;
    cursor: pointer;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: white;
    margin: 5px;
    width: 120px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
`;

export const Card = styled.div`
    height: 300px;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    width: 50%;
    box-shadow: 0 0 10px 0px silver;
    background-color: white;
    border-radius: 20px;
    margin: 10px;
`;