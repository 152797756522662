import useIcons from 'context/icons';
import { useSnackbar } from 'context/snackbar';
import React, { useCallback } from 'react';

/**
 * Componente que permite copiar o conteúdo fornecido para a área de transferência.
 * 
 * @param {Object} props - Propriedades do componente.
 * @param {React.ReactNode} props.children - O conteúdo a ser copiado para a área de transferência.
 * 
 * @returns {JSX.Element} Um elemento de div que, ao ser clicado, copia o conteúdo para a área de transferência.
 */
export default function CopyToClipboard({ children }) {
    //#region VARIAVEIS
    const snackbar = useSnackbar();
    const { getIcon } = useIcons();
    //#endregion
    //#region FUNCOES
    const copyToClipboard = useCallback(() => {
        const el = document.createElement('textarea');
        el.value = children;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        snackbar.displayMessage('Copied to clipboard!');
    }, [children]);
    //#endregion
    //#region HTML
    return (
        <div className='d-flex pointer d-flex align-items-center' onClick={copyToClipboard}>
            {children}
            {React.cloneElement(getIcon('copiar'), { className: 'ms-2' })}
        </div>
    );
    //#endregion
}