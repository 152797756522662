import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';
import { usePageBase } from 'context/page-base';
import useIcons from 'context/icons';
import api from 'services/api';
import AtendimentoService from 'services/AtendimentoService';
import { ClipLoader } from 'react-spinners';
import { useSnackbar } from 'context/snackbar';
import { AlteracaoCard, CardList, LegendaList } from './styles';
import AtendimentoModal from 'pages/TerapeutaDashboard/StatusDiario/AtendimentoModal';
import AdicionarAgendamento from 'pages/TerapeutaDashboard/Dashboard/Modal/AdicionarAgendamento';
import { AtendimentoReposicaoProvider } from 'context/atendimentoReposicao';
import usePacienteData from 'context/paciente';

const AlteracoesPendentes = () => {
    usePageBase({
        title: 'Alterações Pendentes',
        description: 'Visualize os atendimentos pendentes para a data selecionada.',
        hasTitle: true,
        hasSubmenu: true,
        routes: [
            'dashboard/{route_tipo_user}/controle_diario/alteracoes-pendentes',
        ]
    });
    //#region VARIAVEIS
    // Hooks
    const { pacientes, pacienteOptions, fetchPacienteData } = usePacienteData(['nome', 'identificador', 'aviso', 'terapia_paciente']);
    const { iconArray } = useIcons();
    const snackbar = useSnackbar();

    // Carregando
    const [loading, setloading] = useState(false);

    // Data
    const { data } = useParams();
    const [dataMoment, setDataMoment] = useState(moment.utc());
    const dataFormatada = useMemo(() => dataMoment.format('YYYY-MM-DD'), [dataMoment]);

    // Socket
    const [socket, setSocket] = useState(null);
    const [socketState, setSocketState] = useState(null);
    const [quantidadeDeAtualizacoes, setQuantidadeDeAtualizacoes] = useState(0);
    const [alteracoesSendoVerificadasList, setAlteracoesSendoVerificadasList] = useState([]);

    // Modal
    const [isModalAtendimentoOpen, setIsModalAtendimentoOpen] = useState(false);
    const [isModalAdicionarAtendimentoVisible, setIsModalAdicionarAtendimentoVisible] = useState(false);
    const [atendimentoId, setAtendimentoId] = useState(null);
    const [solicitacaoId, setSolicitacaoId] = useState(null);
    const [tipoSolicitacao, setTipoSolicitacao] = useState(null);
    const [isEditavel, setIsEditavel] = useState(false);
    const [terapeutaDefaultSelecionado, setTerapeutaDefaultSelecionado] = useState(null);


    // Controle Diário
    const [controleDiarioId, setControleDiarioId] = useState(null);

    // Tabela
    const [atendimentos, setAtendimentos] = useState([]);

    //#endregion
    //#region FUNCOES
    /**
     * Busca o ID do Controle Diário para uma data específica.
     *
     * @param {string|null} data - A data para a qual o Controle Diário deve ser buscado. Se for null, será usada a data formatada padrão.
     * @returns {Promise<string|boolean>} - Retorna uma Promise que resolve para o ID do Controle Diário encontrado ou false se não for encontrado.
     */
    const getControleDiarioId = async (data = null) => {
        let dataTeste = data === null ? dataFormatada : data;
        return api.get(`/api/controle-diario?filter[dia]=${dataTeste}&with=solicitacao_de_alteracaos,solicitacao_de_alteracaos.horarios_atendimento`)
            .then(response => {
                let controleDiario = response.data;
                if (controleDiario?.length < 1) {
                    Swal.fire({
                        title: 'Sem Controle Diário!',
                        text: 'Não foi possível encontrar o controle diário para a data selecionada.',
                        icon: 'error',
                    });
                    return false;
                }
                controleDiario = controleDiario[controleDiario?.length - 1];
                return controleDiario?.id;
            })
            .catch(e => {
                console.error(e);
                snackbar.displayMessage('Erro ao buscar controle diário.', 'error');
                return false;
            });
    };
    /**
     * Função que envia um array de informações para atualizar todos os conectados no Controle Diário.
     * @param {Array} data Data para ser enviada ao socket, e atualizar todas as telas.
     */
    const atualizaSocket = (socketLocal = null, data) => {
        if (socketLocal && socketLocal.readyState === WebSocket.OPEN) {
            if (data) {
                socketLocal.send(JSON.stringify(data));
            }
        } else {
            console.error('Socket is not open or is null');
        }
    };
    const atualizaVerificandoList = (pessoa, setPacienteEmExecucaoList) => {
        setPacienteEmExecucaoList((oldList) => {
            let newList = [...oldList];
            if (pessoa.action === 'new_connection') {
                const index = newList.findIndex(p => p.id === pessoa.id);
                if (index !== -1) {
                    newList[index] = pessoa;
                } else {
                    newList.push(pessoa);
                }
            } else if (pessoa.action === 'update_connections_list') {
                newList = pessoa.connections ? pessoa.connections : [];
            } else if (pessoa.action === 'disconnect') {
                newList = newList.filter(filter => filter.id !== pessoa.id);
            }
            return newList; // Retorna a nova lista para atualizar o estado
        });
    };
    //#endregion
    //#region HANDLES
    const handlePesquisar = async () => {
        setIsModalAtendimentoOpen(false);
        setloading(true);
        const controleDiarioId = await getControleDiarioId(dataFormatada) || 9;
        setControleDiarioId(controleDiarioId);
        AtendimentoService.getAlteracoesByControleDiarioId(controleDiarioId, true).then(({ data }) => {
            setAtendimentos(data);
            setloading(false);
        }).catch(e => {
            console.error(e);
            setloading(false);
        });
    };
    const handleOpenSolicitacao = ({
        id,
        tipo_solicitacao = 'Alteração' || 'Novo Atendimento' || 'Solicitação' || 'Solicitação Novo Atendimento',
        atendimento_id = undefined,
        solicitacao_id = undefined
    }) => {
        setTipoSolicitacao(tipo_solicitacao);
        switch (tipo_solicitacao) {
            case 'Alteração':
                setIsEditavel(false);
                setAtendimentoId(atendimento_id);
                setIsModalAtendimentoOpen(true);
                break;
            case 'Novo Atendimento':
                setIsEditavel(true);
                setAtendimentoId(atendimento_id);
                setIsModalAtendimentoOpen(true);
                break;
            case 'Solicitação Novo Atendimento':
                setIsEditavel(true);
                setAtendimentoId(atendimento_id);
                setSolicitacaoId(solicitacao_id);
                setIsModalAdicionarAtendimentoVisible(true);
                break;
            case 'Solicitação':
                setIsEditavel(true);
                setSolicitacaoId(id);
                if (atendimento_id) {
                    setAtendimentoId(atendimento_id);
                    setIsModalAtendimentoOpen(true);
                } else {
                    setIsModalAdicionarAtendimentoVisible(true);
                }
                break;
            default:
                break;
        }
    }
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        /* Apenas vai iniciar a conexão com o socket, caso o controle diário seja não salvo e não tenha conexão ainda. */
        if (!socket) {
            if (false === true) {
                /* Criação da URL do WS */
                const params = `?controle_diario_id=${controleDiarioId}`;
                const isHost = window.location.hostname === 'localhost';
                const urlWS = isHost
                    ? `ws://localhost:6001/ws/controle_diario${params}`
                    : `wss://sistema.neurointensiva.com/ws/controle_diario${params}`;

                /* Cria conexão */
                const socketLocal = new WebSocket(urlWS);

                /* Define o listener open do socket */
                socketLocal.addEventListener('open', function () {
                    /* Define o estado global do socket como verdadeiro(Ou seja, conectado) */
                    setSocketState(true);
                });

                /* Define o listener close do socket */
                socketLocal.addEventListener('close', function () {
                    /* Define o estado global do socket como falso(Ou seja, desconectado) */
                    setSocketState(false);
                });

                /* Define o listener message do socket */
                socketLocal.addEventListener('message', function (event) {
                    try {
                        /* Verifica se a data veio corretamente */
                        if (event.data !== null && event.data !== undefined && event.data !== '') {
                            /* Transforma o texto JSON em um objeto */
                            const data = (JSON.parse(event.data));
                            /* Verifica se tem data */
                            if (data) {
                                /* Verifica se tem id do paciente */
                                if (data.action === 'contador') {
                                    /* Se não tiver id do paciente, significa que não veio do Controle
                                    de Execução, então deve apenas adicionar ao contador de atualizaç~eos. */
                                    const newQuantidade = Number(quantidadeDeAtualizacoes) + 1;
                                    setQuantidadeDeAtualizacoes(newQuantidade);
                                } else {
                                    /* Chama a função que atualiza a lista */
                                    atualizaVerificandoList(data, setAlteracoesSendoVerificadasList);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('Erro ao parsear a mensagem: ', error);
                    }
                });

                /* Define o listener error do socket */
                socketLocal.addEventListener('error', function (event) {
                    console.error('Erro: ', event);
                });

                /* Salva a variável para ser utilizada em outras partes do código */
                setSocket(socketLocal);
            }
        }
    }, [socket]);
    useEffect(() => {// ON LOAD
        fetchPacienteData();
        handlePesquisar();
    }, []);
    //#endregion
    //#region HTML
    return (
        <AtendimentoReposicaoProvider>
            <div className='row'>
                <div style={{ width: '20%' }}> </div>
                <div style={{ width: '60%' }}>
                    <CardList className={`${!(atendimentos && atendimentos.length > 0) && 'd-flex align-items-center justify-content-center'}`}>
                        <ClipLoader color={'#123abc'} loading={loading} size={150} />
                        {(atendimentos && atendimentos.length > 0)
                            ? atendimentos.map((alteracao, index) => {
                                let iconeSolicitante;
                                let corFundo;
                                let nomePaciente;
                                let nomeTerapeuta
                                switch (alteracao.grupo_solicitante_nome.toLowerCase()) {
                                    case 'terapeuta':
                                        iconeSolicitante = 'terapeuta';
                                        break;
                                    case 'agendamento':
                                        iconeSolicitante = 'funcionario';
                                        break;
                                    default:
                                        iconeSolicitante = 'usuario';
                                        break;
                                }
                                switch (alteracao.tipo_solicitacao) {
                                    case 'Alteração':
                                        nomePaciente = alteracao.paciente_original;
                                        nomeTerapeuta = alteracao.terapeuta_original;
                                        corFundo = 'bg-azul-claro-i';
                                        break;
                                    case 'Novo Atendimento':
                                        nomePaciente = alteracao.paciente_novo;
                                        nomeTerapeuta = alteracao.terapeuta_novo;
                                        corFundo = 'bg-roxo-claro-i';
                                        break;
                                    case 'Solicitação':
                                        nomePaciente = alteracao.paciente_original;
                                        nomeTerapeuta = alteracao.terapeuta_original;
                                        corFundo = 'bg-amarelo-claro-i';
                                        break;
                                    case 'Solicitação Novo Atendimento':
                                        nomePaciente = null;
                                        nomeTerapeuta = null;
                                        corFundo = 'bg-laranja-claro-i';
                                        break;
                                }
                                return (
                                    <AlteracaoCard key={index} className={`card ${corFundo}`}
                                        onClick={() => handleOpenSolicitacao(alteracao)}>
                                        <div className="card-body">
                                            <span className='col-3'>{moment(alteracao.horario_solicitacao).format('HH:mm')}</span>
                                            <span className='col-9'>
                                                <p>{React.cloneElement(iconArray[iconeSolicitante], { size: 18, style: { marginRight: 5, marginTop: -6 } })}{alteracao.usuario_solicitante_nome}</p>
                                                <span className='status'></span>
                                            </span>
                                            <span className='col-12'>{nomePaciente}</span>
                                            <span className='col-12'>{nomeTerapeuta}</span>
                                        </div>
                                    </AlteracaoCard>
                                )
                            })
                            : !loading && <span>Sem alterações pendêntes...</span>
                        }
                    </CardList>
                </div>
                <div style={{ width: '20%' }}>
                    <LegendaList>
                        <div className='legenda-title'>Legenda das Alterações</div>
                        <div className='legenda-item'>
                            <span className='bg-azul-claro-i'></span>
                            <label>Solicitação de Alteração Preenchida</label>
                        </div>
                        <div className='legenda-item'>
                            <span className='bg-amarelo-claro-i'></span>
                            <label>Solicitação de Alteração Não Preenchida</label>
                        </div>
                        <div className='legenda-item'>
                            <span className='bg-roxo-claro-i'></span>
                            <label>Solicitação de Novo Atendimento Preenchido</label>
                        </div>
                        <div className='legenda-item'>
                            <span className='bg-laranja-claro-i'></span>
                            <label>Solicitação de Novo Atendimento Não Preenchido</label>
                        </div>
                        <div className='legenda-item'>
                            {React.cloneElement(iconArray['funcionario'], { size: 30 })}
                            <label>Solicitação feita pelo Agendamento</label>
                        </div>
                        <div className='legenda-item'>
                            {React.cloneElement(iconArray['terapeuta'], { size: 30 })}
                            <label>Solicitação feita por um Terapeuta</label>
                        </div>
                        <div className='legenda-item'>
                            {React.cloneElement(iconArray['usuario'], { size: 30 })}
                            <label>Solicitação feita por um Administrador</label>
                        </div>
                    </LegendaList>
                </div>
            </div>
            {isModalAtendimentoOpen
                && <AtendimentoModal
                    atendimentoId={atendimentoId}
                    solicitacaoId={solicitacaoId}
                    controleDiarioId={controleDiarioId}
                    onClose={handlePesquisar}
                    isAtendimentoEditavel={isEditavel}
                    atualizaSocket={() => atualizaSocket(socket, { action: 'contador' })}
                    tipoSolicitacao={tipoSolicitacao}
                    snackbar={snackbar} />
            }
            {isModalAdicionarAtendimentoVisible
                && <AdicionarAgendamento
                    onClose={() => { setIsModalAdicionarAtendimentoVisible(false); }}
                    date={dataMoment.format('YYYY-MM-DD')}
                    updateAgenda={handlePesquisar}
                    controleDiarioId={controleDiarioId}
                    solicitacaoId={solicitacaoId}
                    atualizaSocket={() => atualizaSocket(socket, { action: 'contador' })}
                    incluirAtendimento={true}
                    terapeutaDefaultSelecionado={terapeutaDefaultSelecionado}
                />
            }
        </AtendimentoReposicaoProvider>
    );
    //#endregion
};

export default AlteracoesPendentes;