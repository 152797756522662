import styled from 'styled-components';

export const AlteracaoCard = styled.div`
    width: 20rem;
    border: 1px solid black;
    padding: 0px;
    transition: transform 0.2s ease-in-out;
    user-select: none;
    cursor: pointer;

    .card-body {
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
    }
    span {
        padding: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    span:first-child {
        border-right: 1px solid black;
        border-bottom: 1px solid black;
        text-align: center;
    }
    span:nth-child(2) {
        border-bottom: 1px solid black;
        padding-left: 5px;
        display: flex;
        justify-content: between;

        p{
            margin: 0;
            padding: 0;
            width: fit-content;
            height: fit-content;
        }

        .status{
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: green;
            position: absolute;
            right: -6px;
            top: -6px;
        }
    }

    &:hover {
        background-color: #f8f9fa;
        transform: scale(1.04);
    }
`;

export const CardList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    min-width: 700px;
    max-height: 600px;
    overflow-y: auto;
    gap: 10px;
    padding: 40px 20px;
    background-color: white;
`;

export const LegendaList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    width: 100%;
    height: auto;
    overflow-y: auto;
    gap: 10px;
    padding: 20px 20px 40px 20px;
    background-color: white;
    flex-direction: column;
    border: .5px solid black;

    .legenda-title {
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .legenda-item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        label {
            margin: 0;
            padding: 0;
        }
        span {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid black;
        }
    }
`;