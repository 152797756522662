import React from 'react';
import Carregamento from 'components/Carregamento';
import RelatorioEvolucaoPaciente from 'components/Modais/TerapeutaRelatorios/RelatorioEvolucaoPaciente';
import { useAuth } from 'context/auth';
import { usePageBase } from 'context/page-base';
import moment from 'moment';
import { useEffect, useRef, useState, } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { isNotEmpty } from 'services/Check';
import { Container, ContainerSub, FormField } from './styles';

/**
 * Componente EvolucaoDiaria.
 * 
 * Este componente é responsável por exibir a tela de evolução diária dos atendimentos.
 * 
 * @component
 * 
 * @returns {JSX.Element} Retorna o componente EvolucaoDiaria.
 * 
 * @example
 * <EvolucaoDiaria />
 * 
 * @description
 * O componente utiliza o hook `usePageBase` para definir o título, descrição e rotas da página.
 * Utiliza o hook `useAuth` para obter informações do usuário autenticado.
 * Utiliza o hook `useNavigate` para navegação entre páginas.
 * Utiliza o hook `useState` para gerenciar o estado de carregamento, dados do modal e lista de pacientes.
 * Utiliza o hook `useRef` para referenciar o modal de evolução.
 * Utiliza o hook `useEffect` para buscar a lista de pacientes ao montar o componente.
 * 
 * @function handleAbrir
 * @description Abre o modal de evolução e define os dados do paciente selecionado.
 * @param {Object} itemPaciente - Dados do paciente selecionado.
 * 
 * @function handleOpenModalEvolucoes
 * @description Abre o modal de evolução.
 * 
 * @function getPacientes
 * @description Busca a lista de pacientes com evoluções pendentes.
 * 
 * @returns {Promise<void>} Retorna uma Promise que resolve quando a lista de pacientes é obtida.
 * 
 * @throws {Error} Lança um erro caso ocorra algum problema ao obter a lista de pacientes.
 * 
 * @example
 * getPacientes();
 * 
 * @typedef {Object} Paciente
 * @property {number} id_atendimento - ID do atendimento.
 * @property {number} id_paciente - ID do paciente.
 * @property {string} nome - Nome do paciente.
 * @property {string} data_atendimento - Data do atendimento.
 * @property {string} date_final - Data final do atendimento.
 * @property {string} date_create - Data de criação do atendimento.
 * @property {Date} date_teste_filtro - Data de teste do filtro.
 * @property {string} status - Status do atendimento.
 * @property {number} estacao_id - ID da estação.
 * @property {string} estacao - Nome da estação.
 * @property {string} terapia - Nome da terapia.
 * @property {number} terapia_id - ID da terapia.
 * @property {string} diagnostico - Diagnóstico do paciente.
 * @property {string} especialidade - Especialidade do atendimento.
 * @property {number} especialidade_id - ID da especialidade.
 * @property {string} identificador - Identificador do atendimento.
 * @property {string} plano_saude - Plano de saúde do paciente.
 * @property {Array} documentos - Documentos do paciente.
 * @property {string} terapeuta - Nome do terapeuta.
 * @property {number} terapeuta_id - ID do terapeuta.
 * @property {number} atendimento_id - ID do atendimento.
 * @property {boolean} status_evolucao - Status da evolução.
 * @property {number} minutos - Minutos desde o atendimento.
 * @property {string} hora - Hora do atendimento.
 */
export default function EvolucaoDiaria() {
    const pageBase = usePageBase({
        titulo: 'Evolução Diária',
        descricao: 'Tela que cadastra evoluções diárias dos atendimentos.',
        rotas: ['dashboard/terapeuta/pendencias'],
    });
    //#region VARIÁVEIS
    /*UTILITARIOS*/
    const usuario = useAuth();
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    /*MODAL*/
    const [modalData, setModalData] = useState();
    const [pacientes, setPacientes] = useState([]);
    const modalEvolucaoRef = useRef();
    //#endregion
    //#region HANDLES
    function handleAbrir(itemPaciente) {
        handleOpenModalEvolucoes();
        setModalData(itemPaciente);
    };
    const handleOpenModalEvolucoes = () => {
        if (modalEvolucaoRef.current) {
            modalEvolucaoRef.current.openModal();
        }
    };
    //#endregion
    //#region FUNCOES
    const getPacientes = async () => {
        setloading(true);
        try {
            if (isNotEmpty(usuario) && isNotEmpty(usuario.user) && isNotEmpty(usuario.user.id)) {
                api.get('api/terapeuta/get/evolucoes/pendentes/' + usuario.user.terapeuta[0].id + '?tipo=dia').then(({ data }) => {
                    let pacienteOptions = [];
                    data.sort((a, b) => {
                        return new Date(b.data_atendimento_real) + new Date(a.data_atendimento_real);
                    });
                    data.forEach((atendimento) => {
                        /* VARIAVEIS */
                        let status_evolucao = moment().utc().format('DD/MM/YYYY') === moment(atendimento.date_create).utc().format('DD/MM/YYYY');
                        const hora = moment(atendimento.data_atendimento_real).utc();
                        const horaAtual = moment();
                        var duration = horaAtual.diff(hora, 'minutes');
                        var minutos = duration;
                        let teste_filtro = new Date(moment(atendimento.date_create).format('YYYY/MM/DD') + ' ' + moment(atendimento.data_atendimento_real).format('HH:mm'));
                        let passa;
                        if (moment().isAfter(hora)) {
                            passa = (minutos >= 10);
                        } else {
                            passa = true;
                        }
                        if (atendimento.sugestao_recepcao !== 1) {
                            passa = false;
                        }
                        if (passa) {
                            pacienteOptions.push({
                                id_atendimento: atendimento.id_atendimento,
                                id_paciente: atendimento.id_paciente,
                                nome: atendimento.nome,
                                data_atendimento: atendimento.data_atendimento_real,
                                date_final: atendimento.date_final,
                                date_create: atendimento.date_create,
                                date_teste_filtro: teste_filtro,
                                status: atendimento.status,
                                estacao_id: atendimento.estacao_id,
                                estacao: atendimento.estacao,
                                terapia: atendimento.terapia,
                                terapia_id: atendimento.terapia_id,
                                diagnostico: atendimento.diagnostico,
                                especialidade: atendimento.especialidade,
                                especialidade_id: atendimento.especialidade_id,
                                identificador: atendimento.identificador,
                                plano_saude: atendimento.plano_saude,
                                documentos: atendimento.documentos,
                                terapeuta: atendimento.terapeuta,
                                terapeuta_id: atendimento.terapeuta_id,
                                atendimento_id: atendimento.atendimento_id,
                                status_evolucao: status_evolucao,
                                minutos: minutos,
                                hora: hora.format('HH:mm')
                            });
                        }
                    });
                    setPacientes(pacienteOptions.sort((a, b) => a.date_teste_filtro - b.date_teste_filtro));
                });
            }
        } catch (e) {
            console.error('Erro ao obter lista de pacientes - ', e);
        }
        setloading(false);
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        getPacientes();
    }, []);
    //#endregion
    //#region html
    return (
        <React.Fragment>
            {loading && <Carregamento></Carregamento>}
            <Container>
                <div className='submenu'>
                    <label onClick={() => navigate('/dashboard/terapeuta/pendencias')} style={{ marginRight: 20, color: '#7340BD', fontWeight: 'bold', cursor: 'pointer' }}>Evolução Diária</label>
                    <label onClick={() => navigate('/dashboard/terapeuta/pendencias/pendencias-gerais')} style={{ marginRight: 20, cursor: 'pointer' }}>Pendências Gerais</label>
                    <label onClick={() => navigate('/dashboard/terapeuta/pendencias/pendencias-atrasadas')} style={{ cursor: 'pointer' }}>Pendências Atrasadas</label>
                </div>
                <ContainerSub>
                    <FormField>
                        <div className="row col-12">
                            <div className='col-lg-8 col-sm-12 m-auto'>
                                <table className='w-100per'>
                                    <thead>
                                        <tr>
                                            <th className='col-1 font-titulo'>id</th>
                                            <th className='col-3 font-titulo'>Paciente</th>
                                            <th className='col-3 font-titulo'>Data do Atendimento</th>
                                            <th className='col-3 font-titulo'>Limite</th>
                                            <th className='col-2 font-titulo'>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pacientes.map((item) => {
                                            return (
                                                <tr className={((!item.status_evolucao) ? ' destaque-vermelho' : '')}>
                                                    <td className='p-1'>{/* ID */}
                                                        <input defaultValue={item.id_atendimento} className='form-control' readOnly />
                                                    </td>
                                                    <td className='p-1'>{/* PACIENTE */}
                                                        <input defaultValue={item.nome} className='form-control' readOnly />
                                                    </td>
                                                    <td className='p-1'>{/* DATA DO ATENDIMENTO */}
                                                        <input defaultValue={moment(item.date_create).format('DD/MM/YYYY') + ' ' + item.hora} className='form-control' readOnly />
                                                    </td>
                                                    <td className='p-1'>{/* LIMITE */}
                                                        <input defaultValue={moment(item.date_create).endOf('day').format('DD/MM/YYYY') + ' às ' + moment(item.date_create).endOf('day').format('HH:mm')} className='form-control' readOnly />
                                                    </td>
                                                    <td className='p-1 align-center d-flex'>{/* AÇÕES */}
                                                        <button className='btn-padrao w-95per p-1' onClick={() => handleAbrir(item)} title='Criar evolução para esse atendimento, e para esse paciente.'> Evoluir </button>
                                                        <svg xlinkTitle='Evolução diária atrasada!' className={(item.status_evolucao == '-') ? 'bi bi-exclamation-lg' : 'none'} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                            <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0L7.005 3.1ZM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                                                        </svg>
                                                    </td>
                                                </tr>
                                            );

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </FormField>
                </ContainerSub>
            </Container>
            <RelatorioEvolucaoPaciente ref={modalEvolucaoRef} onClose={() => { getPacientes(); }} paciente={modalData} />
        </React.Fragment>
    );
    //#endregion
}