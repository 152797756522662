import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form } from '@unform/web';
import Button from 'components/Button';
import { useSnackbar } from 'context/snackbar';
import api from 'services/api';
import Modal from 'react-modal';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';
import { unstable_batchedUpdates } from 'react-dom';
import useTipoColuna from 'context/tipo-coluna';

const PerfilColuna = ({ colunaId, tabelaId, setColunaId, onClose = () => { } }) => {
	//#region VARIAVEIS
	// HOOKS
	const snackbar = useSnackbar();
	const { fetchTipoColuna, tiposColunaOptions } = useTipoColuna();
	// DADOS DA COLUNA
	const [nome, setNome] = useState('');
	const [tipo, setTipo] = useState('');
	const [posicao, setPosicao] = useState('');
	const [modalIsOpen, setModalIsOpen] = useState(false);
	// ESTILOS
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			transform: 'translate(-50%, -50%)',
			width: '50%',
			height: 'auto',
			padding: '0px',
		},
	};
	//#endregion
	//#region HANDLES
	const handleSubmit = async () => {
		const coluna = { nome, tipo, posicao, tabela_id: tabelaId };
		try {
			if (colunaId) {
				await api.put(`/api/colunas/${colunaId}`, coluna);
				snackbar.displayMessage('Coluna atualizada com sucesso!', 'success');
			} else {
				await api.post('/api/colunas', coluna);
				snackbar.displayMessage('Coluna cadastrada com sucesso!', 'success');
			}
			handleCloseModal();
		} catch (error) {
			console.error('Erro ao salvar coluna:', error);
			snackbar.displayMessage('Erro ao salvar coluna.', 'error');
		}
	};
	const handleOpenModalNewColuna = () => {
		unstable_batchedUpdates(() => {
			setColunaId(null);
			setModalIsOpen(true);
		});
	};
	const handleOpenModal = () => {
		setModalIsOpen(true);
	};
	const handleCloseModal = () => {
		onClose();
		setNome('');
		setTipo('');
		setPosicao('');
		setColunaId(null);
		setModalIsOpen(false);
	};
	//#endregion
	//#region USE EFFECTS
	useEffect(() => {
		if (colunaId) {
			api.get(`/api/colunas/${colunaId}`)
				.then(response => {
					const { nome, tipo, posicao, tabela_id } = response.data;
					setNome(nome);
					setTipo(tipo);
					setPosicao(posicao);
					handleOpenModal();
				})
				.catch(error => {
					console.error('Erro ao carregar coluna:', error);
					snackbar.displayMessage('Erro ao carregar coluna.', 'error');
				});
		}
	}, [colunaId]);
	useEffect(() => {
		fetchTipoColuna();
	}, []);
	//#endregion
	//#region HTML
	return (
		<>
			<Button className="btn btn-primary" onClick={handleOpenModalNewColuna}>
				Cadastrar Coluna
			</Button>
			<Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Coluna Modal" style={customStyles}>
				<nav className='navbar sticky-top bg-cinza-claro shadow m-0'>
					<div className="container-fluid d-flex align-items-center justify-content-between">
						<div className='w-200px'> </div>
						<ModalTitulo>{colunaId ? 'Editar' : 'Criar'} Coluna</ModalTitulo>
						<label className='btn-outline w-200px text-right' onClick={() => handleCloseModal()}>
							<CgClose size={22} color='red' />
						</label>
					</div>
				</nav>
				<br></br>
				<div className="container">
					<Form onSubmit={handleSubmit}>
						<div className="mb-3">
							<label className="form-label">Nome:</label>
							<input
								type="text"
								className="form-control"
								value={nome}
								onChange={(e) => setNome(e.target.value)}
								required
							/>
						</div>
						<div className="mb-3">
							<label className="form-label">Tipo:</label>
							<select
								className="form-control"
								value={tipo}
								onChange={(e) => setTipo(e.target.value)}
								required
							>
								<option value="">Selecione o tipo</option>
								{tiposColunaOptions.map((tipo, index) => (
									<option key={index} value={tipo.value}>{tipo.label}</option>
								))}
							</select>
						</div>
						<div className="mb-3">
							<label className="form-label">Posição:</label>
							<input
								type="text"
								className="form-control"
								value={posicao}
								onChange={(e) => setPosicao(e.target.value)}
								required
							/>
						</div>
						<Button type="submit">
							{colunaId ? 'Atualizar Coluna' : 'Cadastrar Coluna'}
						</Button>
					</Form>
				</div>
			</Modal>
		</>
	);
	//#endregion
};

export default PerfilColuna;
