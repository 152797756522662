import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form } from '@unform/web';
import Button from 'components/Button';
import { useSnackbar } from 'context/snackbar';
import api from 'services/api';
import Modal from 'react-modal';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';
import { unstable_batchedUpdates } from 'react-dom';
import PerfilColuna from '../colunas';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import moment from 'moment';

const PerfilTabela = ({ tabelaId, setTabelaId, onClose = () => { } }) => {
	//#region VARIAVEIS
	// HOOKS
	const snackbar = useSnackbar();
	// DADOS DA TABELA
	const [nome, setNome] = useState('');
	const [descricao, setDescricao] = useState('');
	const [etiquetas, setEtiquetas] = useState('');
	const [habilitado, setHabilitado] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [tabela, setTabela] = useState({});
	const [loading, setLoading] = useState(true);
	const [colunaId, setColunaId] = useState(null);
	// ESTILOS
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			transform: 'translate(-50%, -50%)',
			width: '80%',
			height: 'auto',
			padding: '0px',
		},
	};


	const columns = [
		{
			Header: 'ID',
			accessor: 'id',
		},
		{
			Header: 'Sub Coluna ID',
			accessor: 'sub_coluna_id',
		},
		{
			Header: 'Nome',
			accessor: 'nome',
		},
		{
			Header: 'Etiquetas',
			accessor: 'etiquetas',
		},
		{
			Header: 'Tipo',
			accessor: 'tipo_nome',
		},
		{
			Header: 'Tabela ID',
			accessor: 'tabela_id',
		},
		{
			Header: 'Posição',
			accessor: 'posicao',
		},
		{
			Header: 'Criado em',
			accessor: 'created_at',
			Cell: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm'),
		},
		{
			Header: 'Atualizado em',
			accessor: 'updated_at',
			Cell: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm'),
		},
	];
	//#endregion

	const fetchTabela = async () => {
		setLoading(true);
		api.get(`/api/tabelas/${tabelaId}?with=colunas`)
			.then(response => {
				const { nome, descricao, etiquetas, habilitado } = response.data;
				setNome(nome);
				setDescricao(descricao);
				setEtiquetas(etiquetas);
				setHabilitado(habilitado);
				setTabela(response.data);
				handleOpenModal();
			})
			.catch(error => {
				console.error('Erro ao carregar tabela:', error);
				snackbar.displayMessage('Erro ao carregar tabela.', 'error');
			})
			.finally(() => setLoading(false));
	};

	//#region HANDLES
	const handleSubmit = async () => {
		const tabela = { nome, descricao, etiquetas, habilitado };
		try {
			if (tabelaId) {
				await api.put(`/api/tabelas/${tabelaId}`, tabela);
				snackbar.displayMessage('Tabela atualizada com sucesso!', 'success');
			} else {
				await api.post('/api/tabelas', tabela);
				snackbar.displayMessage('Tabela cadastrada com sucesso!', 'success');
			}
			handleCloseModal();
		} catch (error) {
			console.error('Erro ao salvar tabela:', error);
			snackbar.displayMessage('Erro ao salvar tabela.', 'error');
		}
	};
	const handleOpenModalNewTabela = () => {
		unstable_batchedUpdates(() => {
			setTabelaId(null);
			setModalIsOpen(true);
		});
	};
	const handleOpenModal = () => {
		setModalIsOpen(true);
	};
	const handleCloseModal = () => {
		onClose();
		setTabelaId(null);
		setModalIsOpen(false);
	};
	//#endregion
	//#region USE EFFECTS
	useEffect(() => {
		if (tabelaId) {
			fetchTabela();
		}
	}, [tabelaId]);
	//#endregion
	//#region HTML
	return (
		<>
			<Button className="btn btn-primary" onClick={handleOpenModalNewTabela}>
				Cadastrar Tabela
			</Button>
			<Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Tabela Modal" style={customStyles}>
				<nav className='navbar sticky-top bg-cinza-claro shadow m-0'>
					<div className="container-fluid d-flex align-items-center justify-content-between">
						<div className='w-200px'> </div>
						<ModalTitulo>{tabelaId ? 'Editar' : 'Criar'} Tabela</ModalTitulo>
						<label className='btn-outline w-200px text-right' onClick={() => handleCloseModal()}>
							<CgClose size={22} color='red' />
						</label>
					</div>
				</nav>
				<br></br>
				<Form className='w-100per px-4' onSubmit={handleSubmit}>
					<div className="mb-3">
						<label className="form-label">Nome:</label>
						<input
							type="text"
							className="form-control"
							value={nome}
							onChange={(e) => setNome(e.target.value)}
							required
						/>
					</div>
					<div className="mb-3">
						<label className="form-label">Descrição:</label>
						<textarea
							className="form-control"
							value={descricao}
							onChange={(e) => setDescricao(e.target.value)}
							required
						/>
					</div>
					<div className="mb-3">
						<label className="form-label">Etiquetas:</label>
						<input
							type="text"
							className="form-control"
							value={etiquetas}
							onChange={(e) => setEtiquetas(e.target.value)}
							required
						/>
					</div>
					<div className="form-check mb-3">
						<input
							type="checkbox"
							className="form-check-input"
							checked={habilitado}
							onChange={(e) => setHabilitado(e.target.checked)}
						/>
						<label className="form-check-label">Habilitado</label>
					</div>
					<Button type="submit">
						{tabelaId ? 'Atualizar Tabela' : 'Cadastrar Tabela'}
					</Button>
				</Form>
				{tabelaId && (
					<div className='col-12'>
						<h4>Colunas</h4>
						<TableDefaultPagination source={tabela.colunas} columns={columns} createButton={
							<PerfilColuna colunaId={colunaId} tabelaId={tabelaId} setColunaId={setColunaId} onClose={fetchTabela} />
						} tableTitle={'Listagem de Tabelas'} searchBar={true} loading={loading} />
					</div>
				)}
			</Modal>
		</>
	);
	//#endregion
};

export default PerfilTabela;
