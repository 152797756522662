import React, { useMemo, useRef, useState } from 'react';
import RelatorioEvolucaoPaciente from '../../../../components/Modais/TerapeutaRelatorios/RelatorioEvolucaoPaciente';
import { BsDownload, BsEye } from 'react-icons/bs';
import A4Page from 'components/Folha/A4-Retrato';
import { useNavigate } from 'react-router-dom';
import { TableDefaultPagination } from 'components/TableDefaultPagination';

export function TableUltimosAtendimentos({ source }) {

    const data = React.useMemo(() => source, [source]);
    const relatorioEdicaoModalRef = useRef();

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [filename, setFilename] = useState('');
    const [isGeraDocumentoOpen, setIsGeraDocumentoOpen] = useState(false);
    const navigate = useNavigate();

    const columns = useMemo(
        () => [
            {
                Header: 'Data:',
                accessor: 'data_atendimento',
            },
            {
                Header: 'Status:',
                accessor: 'status',
                filterable: true,
            },
            {
                Header: 'Estação:',
                accessor: 'estacao',
                filterable: true,
                Cell: ({ cell }) => {
                    return (cell.row.original.estacao + ' - ' + cell.row.original.sala);
                }
            },
            {
                Header: 'Identificador:',
                accessor: 'identificador',
                filterable: true,
            },
            {
                Header: 'Plano de Saúde:',
                accessor: 'plano_saude',
                filterable: true,
            },
            {
                Header: 'Terapia:',
                accessor: 'terapia',
                filterable: true,
            },
            {
                Header: 'Especialidade:',
                accessor: 'especialidade',
                filterable: true,
            },
            {
                Header: 'Ações',
                accessor: 'evolucao_paciente_id',
                filterable: false,
                Cell: (({ cell }) => {
                    if (cell.value) {
                        return (
                            <div className="w-100per">
                                <button className="btn-icone" type="button" onClick={() => {
                                    relatorioEdicaoModalRef?.current.openModal(cell.value);
                                }}>
                                    <BsEye size={22}></BsEye>
                                </button>
                                <button className="btn-icone" type="button" onClick={() => navigate(`/dashboard/terapeuta/gerador-de-documento/${cell.value}`)}>
                                    <BsDownload size={22} />
                                </button>
                            </div>
                        );
                    } else {
                        return ('');
                    }
                })
            }
        ]
    );

    return (
        <>
            {isGeraDocumentoOpen
                && <A4Page
                    onCloseClick={setIsGeraDocumentoOpen(false)}
                    modalMode={true}
                    title={title}
                    content={content}
                    filename={filename}
                />}
            <TableDefaultPagination source={data} columns={columns} searchBar={true} />
            <RelatorioEvolucaoPaciente ref={relatorioEdicaoModalRef} tipo={'visualizacao'} />
        </>
    );
}