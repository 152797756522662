import React, { useState, useRef, useEffect, useCallback } from "react";

/**
 * VirtualizedTable component renders a table with virtualized rows for performance optimization.
 * 
 * @param {Object} props - The properties object.
 * @param {string} props.tableHeadClassName - CSS class name for the table head.
 * @param {string} props.tableFootClassName - CSS class name for the table foot.
 * @param {Array} props.rows - Array of row data to be rendered.
 * @param {Function} [props.rowMap=() => {}] - Function to map row data to table rows.
 * @param {number} [props.rowHeight=40] - Height of each row in pixels.
 * @param {number} [props.containerHeight=400] - Height of the container in pixels.
 * @param {Function|Array} props.tableHeader - Function or array to render the table header.
 * @param {Function|Array} props.tableFoot - Function or array to render the table footer.
 * 
 * @returns {JSX.Element} The rendered virtualized table component.
 * 
 * @example
 * // Example usage of VirtualizedTable component:
 * <VirtualizedTable
 *      tableHeadClassName="table-head"
 *      tableFootClassName="table-foot"
 *      rows={rows}
 *      rowMap={({ row, key, styles }) => (
 *          <tr key={key} style={styles}>
 *              <td>{row.name}</td>
 *              <td>{row.age}</td>
 *          </tr>
 *      )}
 *      rowHeight={40}
 *      containerHeight={400}
 *      tableHeader={['Name', 'Age']}
 *      tableFoot={['Total', rows.length]}
 * />
 */
const VirtualizedTable = ({
    tableHeadClassName,
    tableFootClassName,
    rows,
    rowMap = () => { },
    rowHeight = 40,
    containerHeight = 400,
    tableHeader,
    tableFoot
}) => {
    // Estados para posição de rolagem e linhas visíveis
    const [scrollTop, setScrollTop] = useState(0);
    const containerRef = useRef(null);

    const totalHeight = rows.length * rowHeight;
    const visibleRowCount = Math.ceil(containerHeight / rowHeight) + 1;

    // Calcule o índice das linhas visíveis baseado na rolagem atual
    const startIndex = Math.max(0, Math.floor(scrollTop / rowHeight));
    const endIndex = Math.min(rows.length - 1, startIndex + visibleRowCount);

    const handleScroll = useCallback(() => {
        setScrollTop(containerRef.current.scrollTop);
    }, []);

    // Adicione o event listener ao scroll
    useEffect(() => {
        const ref = containerRef.current;
        ref.addEventListener("scroll", handleScroll);
        return () => ref.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    //#region FUNCOES
    /**
     * Renderiza o cabeçalho da tabela.
     * 
     * Esta função usa um callback para renderizar o cabeçalho da tabela com base no tipo de prop `tableHeader`.
     * Se `tableHeader` for uma função, ela chama e retorna o resultado dessa função.
     * Se `tableHeader` for um array, ela mapeia o array e cria uma linha da tabela (`<tr>`) com células de cabeçalho (`<th>`).
     * Se `tableHeader` não for uma função nem um array, ela retorna null.
     * 
     * @returns {JSX.Element|null} O cabeçalho da tabela renderizado ou null se `tableHeader` não for uma função ou array.
     */
    const renderTableHeader = useCallback(() => {
        if (typeof tableHeader === 'function') {
            return tableHeader();
        } else if (Array.isArray(tableHeader)) {
            return (
                <tr>
                    {tableHeader.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
                </tr>
            );
        }
        return null;
    }, []);
    /**
     * Renderiza o rodapé da tabela.
     * 
     * Se `tableFoot` for uma função, ela chama a função e retorna seu resultado.
     * Se `tableFoot` for um array, ela mapeia o array e retorna uma linha da tabela (`<tr>`) 
     * com cada item do array renderizado como uma célula da tabela (`<td>`).
     * Se `tableFoot` não for uma função nem um array, ela retorna `null`.
     * 
     * @returns {JSX.Element|null} O rodapé da tabela renderizado ou null.
     */
    const renderTableFooter = () => {
        if (typeof tableFoot === 'function') {
            return tableFoot();
        } else if (Array.isArray(tableFoot)) {
            return (
                <tr>
                    {tableFoot.map((foot, index) => (
                        <td key={index}>{foot}</td>
                    ))}
                </tr>
            );
        }
        return null;
    };
    //#endregion
    //#region HTML
    return (
        <div className="d-grid w-100 overflow-hidden position-relative">
            <table className={`${tableHeadClassName} position-relative w-100 h-fit-content`}>
                <thead className="position-relative" style={{ top: 0, zIndex: 6 }}>
                    {renderTableHeader()}
                </thead>
            </table>
            <div ref={containerRef} style={{ position: "relative", overflowY: "auto", height: containerHeight, border: "1px solid #ccc", }} className="" >
                {/* Div de preenchimento para garantir o scroll */}
                <div className="" style={{ height: totalHeight, position: "relative" }}>
                    {/* Renderizar apenas as linhas visíveis */}
                    <table style={{ position: "absolute", top: startIndex * rowHeight, width: "100%", zIndex: 5, marginTop: 40 }} >
                        <tbody className={`${tableFootClassName}`}>
                            {rows.slice(startIndex, endIndex + 1).map((row, index) =>
                                rowMap({
                                    row,
                                    key: startIndex + index,
                                    styles: { height: rowHeight },
                                })
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <table className={`${tableFootClassName} position-relative w-100`}>
                <tfoot className="position-relative" style={{ bottom: 0, zIndex: 6 }}>
                    {renderTableFooter()}
                </tfoot>
            </table>
        </div>
    );
    //#endregion
};

export default VirtualizedTable;