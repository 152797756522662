import React, { forwardRef, useCallback, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import { useSnackbar } from '../../../context/snackbar';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import useIcons from 'context/icons';
import Button from 'components/Button';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		maxHeight: '70vh',
		width: '60vw',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#FFFFFF'
	},
};

const PermissaoModal = ({ modalIsOpen, setIsOpen, callback, modalData, permissionsList }, ref) => {
	//#region VARIAVEIS
	let subtitle;

	const [formData, setFormData] = useState({
		id: null,
		name: '',
		guard_name: '',
		description: '',
		deleted_at: '',
		created_at: '',
		updated_at: '',
		link: '',
		title: '',
		icon: '', // Adicione o estado para o ícone
		placeholder: '',
		linkable: '',
		sublinkable: '',
		has_submenu: '',
		submenu_position: '',
	});
	const snackbar = useSnackbar();
	const [has_submenu, setHasSubmenu] = useState();
	const [sublinkable, setSublinkable] = useState();
	const [linkable, setLinkable] = useState();
	const [sublinkIdOptions, setSublinkIdOptions] = useState([]);
	const [itensSubmenu, setItensSubmenu] = useState([]);
	const [sublinkId, setSublinkId] = useState();
	const { iconsList, getIcon } = useIcons(30);
	const [dropDownList, setDropDownList] = useState([]);
	//#endregion

	function afterOpenModal() {
		subtitle.style.color = '#000';
		if (modalData !== null) {
			setHasSubmenu(modalData.has_submenu);
			setLinkable(modalData.linkable);
			setSublinkable(modalData.sublinkable);
			setFormData(modalData);
			if (modalData.sublink_id) {
				const dropDownListLocal = permissionsList.filter(filter => filter.sublink_id === modalData.sublink_id);
				setDropDownList(dropDownListLocal.sort((a, b) => a.position - b.position));
			}
		}
	}

	const closeModal = useCallback(() => {
		setIsOpen(false);
	}, []);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData(prevData => ({
			...prevData,
			[name]: type === 'checkbox' ? checked : value
		}));
	};

	const handleIconChange = (selectedOption) => {
		// Atualize o formData e o ícone selecionado
		setFormData(prevData => ({
			...prevData,
			icon: selectedOption.value // Salva o valor do ícone no formData
		}));
	};

	async function handleSubmit(e) {
		let data = formData;
		try {
			data.guard_name = 'api';
			// Ajustes nos dados
			data.linkable = data.linkable === null || data.linkable === '' ? false : data.linkable;
			data.sublinkable = data.sublinkable === null || data.sublinkable === '' ? false : data.sublinkable;
			data.has_submenu = data.has_submenu === null || data.has_submenu === '' ? false : data.has_submenu;
			data.sublink_id = sublinkId?.value;
			if (data.id === null) {
				await api.post('api/permission', data).then(() => {
					snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
				}).catch((e) => {
					snackbar.displayMessage('Houve um erro ao cadastrar essa permissão!', 'error');
					console.error(e);
				}).finally(() => {
					setIsOpen(false);
					callback();
				});
			} else {
				let url = 'api/permission/' + data.id + '?id=' + data.id;
				await api.put(url, data).then(() => {
					snackbar.displayMessage('Cadastro atualizado com sucesso!', 'success');
				}).catch((e) => {
					snackbar.displayMessage('Houve um erro ao atualizar essa permissão!', 'error');
					console.error(e);
				}).finally(() => {
					setIsOpen(false);
					callback();
				});
			}
		} catch (error) {
			Swal.fire('Houve um problema ao cadastrar!', '', 'error');
			console.error(error);
		}
	}

	useEffect(() => {
		if (permissionsList) {
			const permissionMaped = permissionsList.map(map => ({
				value: map?.id,
				label: map?.name
			}));
			setSublinkIdOptions(permissionMaped.filter((filter) => filter.id !== modalData?.id));
		}
	}, [permissionsList]);

	useEffect(() => {
		if (sublinkIdOptions) {
			const sublinkidLocal = sublinkIdOptions.filter((filter) => filter.value === modalData.sublink_id);
			setSublinkId(sublinkidLocal ? sublinkidLocal[0] : null);
		}
	}, [modalData, sublinkIdOptions]);

	useEffect(() => {
		if (modalData) {
			setItensSubmenu(() => permissionsList.filter(filter => filter.sublink_id === modalData?.id));
		}
	}, [modalData]);

	return (
		<div>
			<Modal
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={customStyles}
				transparent>
				<div style={{ position: 'relative' }}>
					<label style={{
						opacity: 0.5, cursor: 'pointer', position: 'absolute',
						right: '10px', fontSize: '25px', top: '-10px'
					}} onClick={() => setIsOpen(false)}>
						x
					</label>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<h2 style={{ color: '#442953', fontWeight: 'bold', marginTop: 20 }} ref={(_subtitle) => (subtitle = _subtitle)}>
						{formData === null ? 'Nova Permissão' : 'Alterar Permissão'}
					</h2>
					<Form onSubmit={handleSubmit} className='row'>
						<div className='col-4'>
							<label htmlFor="name">Nome:</label>
							<input
								type="text"
								id="name"
								name="name"
								defaultValue={formData['name']}
								onChange={handleChange}
								className="form-control"
							/>
						</div>
						<div className='col-3'>
							<label htmlFor="link">Link:</label>
							<input
								type="text"
								id="link"
								name="link"
								defaultValue={formData['link']}
								onChange={handleChange}
								className="form-control"
							/>
						</div>
						<div className='col-3'>
							<label htmlFor="title">Titulo:</label>
							<input
								type="text"
								id="title"
								name="title"
								defaultValue={formData['title']}
								onChange={handleChange}
								className="form-control"
							/>
						</div>
						<div className='col-2'>
							<label htmlFor="icon">Icone:</label>
							<Select
								type="text"
								id="icon"
								name="icon"
								options={iconsList}
								value={iconsList.find(option => option.value === formData.icon)}
								onChange={handleIconChange}
							/>
						</div>
						<div className='col-12'>
							<label htmlFor="description">Descrição:</label>
							<TextArea
								type="text"
								id="description"
								name="description"
								defaultValue={formData['description']}
								onChange={handleChange}
								className="form-control"
							/>
						</div>
						<div className='col-12'>
							<label htmlFor="placeholder">Dica da Ferramenta:</label>
							<input
								type="text"
								id="placeholder"
								name="placeholder"
								defaultValue={formData['placeholder']}
								onChange={handleChange}
								className="form-control"
							/>
						</div>
						<hr className='col-12 my-4'></hr>
						<div className='row'>
							<div className='col-4 h-100px d-flex align-items-center'>
								<label htmlFor="linkable" className='m-0 p-0'>É um link:</label>
								<input
									type="checkbox"
									id="linkable"
									name="linkable"
									className='ml-10px'
									checked={linkable}
									onChange={(e) => { handleChange(e); setLinkable(e.target.checked); }}
								/>
							</div>
							<div className='col-4 h-100px d-flex align-items-center'>
								<label htmlFor="has_submenu" className='m-0 p-0'>Possui Submenu:</label>
								<input
									type="checkbox"
									id="has_submenu"
									name="has_submenu"
									className='ml-10px'
									checked={has_submenu}
									onChange={(e) => { handleChange(e); setHasSubmenu(e.target.checked); }}
								/>
							</div>
							<div className='col-4 h-100px d-flex align-items-center'>
								<label htmlFor="sublinkable" className='m-0 p-0'>É um sublink:</label>
								<input
									type="checkbox"
									id="sublinkable"
									name="sublinkable"
									className='ml-10px'
									checked={sublinkable}
									onChange={(e) => { handleChange(e); setSublinkable(e.target.checked); }}
								/>
							</div>
							<div className='col-12 mt-4'>
								<label htmlFor="sublink_id">Grupo de Submenu:</label>
								<Select
									id={'sublink_id'}
									name={'sublink_id'}
									options={sublinkIdOptions}
									value={sublinkId}
									onChange={setSublinkId}
								/>
							</div>
						</div>
						{linkable
							&&
							<>
								<div className='row'>
									<div className='col-12 mt-4'>
										<label htmlFor="submenu_position">Posição do Submenu:</label>
										<div className='col-12 d-flex'>
											<span className='w-10per'>Posição: {formData['submenu_position']}</span>
											<input type="range"
												className="ns-range w-90per"
												min={0} max={20} step={1}
												id="submenu_position"
												name="submenu_position"
												defaultValue={formData['submenu_position']}
												onMouseLeave={() => setDropDownList(prev => prev.map(map => map.id === modalData?.id ? { ...map, submenu_position: formData['submenu_position'] } : map).sort((a, b) => a.position - b.position))}
												onChange={(e) => { handleChange(e); }}
											/>
										</div>
									</div>
									<div className='col-12 mt-4'>
										<table className='col-12 table table-striped'>
											<thead>
												<tr>
													<th>Itens de Submenu</th>
												</tr>
											</thead>
											<tbody>
												{itensSubmenu && itensSubmenu.length > 0 && itensSubmenu.map((map) => {
													return <tr>
														<td>{map.name}</td>
													</tr>;
												})}
											</tbody>
										</table>
									</div>
								</div>
							</>
						}
						{dropDownList.length > 0
							? <div className='col-12'>
								<label>Submenu Preview</label>
								<ul className='w-fit-content-i bg-roxo-dark-i px-2 py-2 ns-lista-sem-estilo'>
									{dropDownList.map((item, index) => (
										<li>
											<span key={index} className={'li-padrao font-branca hl-white-bg-laranja justify-content-start'}>
												{item.icon &&
													<span className='me-2'>
														{getIcon(item.icon, undefined, true)}
													</span>}
												{item.title}
											</span>
										</li>
									))}
								</ul>
							</div>
							: <></>
						}
						<div className='col-12'>
							<Button type="submit" className="btn-padrao mt-4">
								{formData?.id === null ? 'Salvar' : 'Alterar'}
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
		</div>
	);
};

export default forwardRef(PermissaoModal);
