import React, { useMemo } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import Swal from 'sweetalert2';
import PlanoSaudeService from '../../../../services/PlanoSaudeService';
import { TableDefaultPagination } from '../../../../components/TableDefaultPagination';

export default function TablePlanoSaude({ source, callback, setIsOpen, setObj, loading, createButton }) {
    const columns = useMemo(
        () => [
            {
                Header: 'Plano de saúde:',
                accessor: 'plano',
                filterable: true
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                Cell: ({ cell }) => (
                    (<div className='d-flex all-center bg-transparent'>
                        <AiOutlineEdit style={{ cursor: 'pointer' }} color="#7340BD" onClick={() => {
                            setObj(cell.row.original);
                            setIsOpen(true);
                        }} size={22} />
                    </div>
                    )
                )
            }
        ],
        []
    );

    return (
        <TableDefaultPagination columns={columns} searchBar={true} source={source} createButton={createButton} loading={loading} />
    );
}
