//#region IMPORTAÇÕES
import { Card } from 'components/Card/styles';
import { useAuth } from 'context/auth';
import useControleDiario from 'context/controle-diario';
import useIcons from 'context/icons';
import { usePageBase } from 'context/page-base';
import moment from 'moment';
import AdicionarAgendamento from 'pages/TerapeutaDashboard/Dashboard/Modal/AdicionarAgendamento';
import React, { cloneElement, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
//#endregion
/**
 * Componente DashboardControleDiario
 * 
 * Este componente exibe um painel de controle diário onde os usuários podem visualizar os atendimentos pendentes para o dia selecionado.
 * 
 * @component
 * 
 * @returns {JSX.Element} Retorna o componente DashboardControleDiario.
 * 
 * @example
 * <DashboardControleDiario />
 * 
 * @description
 * O componente utiliza vários hooks, incluindo `usePageBase`, `useNavigate`, `useAuth` e `useIcons`. Ele também utiliza o hook `useEffect` para buscar permissões completas e armazená-las no estado local.
 * 
 * @see {@link usePageBase} para mais informações sobre o hook `usePageBase`.
 * @see {@link useNavigate} para mais informações sobre o hook `useNavigate`.
 * @see {@link useAuth} para mais informações sobre o hook `useAuth`.
 * @see {@link useIcons} para mais informações sobre o hook `useIcons`.
 */
const DashboardControleDiario = () => {
    //#region VARIAVEIS
    // Hooks
    usePageBase({
        title: 'Dashboard Controle Diário',
        description: 'Visualize os atendimentos pendentes para o dia selecionado.',
        hasTitle: true,
        hasSubMenu: true
    });
    const navigate = useNavigate();
    const { coletaPermissoesFull, coletaPermissoesPagina } = useAuth();
    const { user } = useAuth();
    const { getIcon } = useIcons();
    const { controleDiario, fetchControleDiario, carregaControleDiario, salvarControleDiario } = useControleDiario();

    // Data
    const [urlData, setUrlData] = useState();
    const dataHoje = moment.utc().format('YYYY-MM-DD');
    const dataMoment = useMemo(() => moment(urlData), [urlData]);
    const dataFormatada = useMemo(() => {
        const formattedDate = dataMoment.format('dddd DD/MM/YYYY');
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    }, [dataMoment]);
    const dataFormatadaTeste = useMemo(() => {
        const formattedDate = dataMoment.format('YYYY-MM-DD');
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    }, [dataMoment]);

    // Carregando
    const [carregandoControleDiario, setCarregandoControleDiario] = useState(true);
    const [temDiaCarregado, setTemDiaCarregado] = useState(false);
    const [loading, setLoading] = useState(false);
    const [testandoDia, setTestandoDia] = useState(false);

    // Atendimentos
    const [atendimentos, setAtendimentos] = useState(null);

    // Controle Diário
    const [controleDiarioId, setControleDiarioId] = useState(null);

    // Icone
    const size = 24;

    // Permissões
    const [permissoes, setPermissoes] = useState([]);
    const [permissoesPagina, setPermissoesPagina] = useState([]);

    // Modal
    const [isModalAdicionarAtendimentoVisible, setIsModalAdicionarAtendimentoVisible] = useState(false);

    // Aparência do botão de ação
    const aparenciaBotaoAcao = {
        nao_salvo: {
            backgroundColor: '#35CF58',
            cursor: 'pointer',
            color: 'white'
        },
        salvo: {
            habilitado: {
                backgroundColor: 'lightgrey',
                cursor: 'not-allowed',
                color: 'black'
            },
            nao_habilitado: {
                backgroundColor: 'red',
                cursor: 'context-menu',
                color: 'white'
            }
        }
    }
    //#endregion
    //#region HANDLES
    const handleCarregarControleDiario = async () => {
        setCarregandoControleDiario(true);
        await carregaControleDiario(dataFormatadaTeste, user.id).then(() => {
            setCarregandoControleDiario(false);
            fetchControleDiario(dataMoment, setControleDiarioId, setTemDiaCarregado, setCarregandoControleDiario);
        });
    }
    //#endregion
    //#region FUNCOES
    function adicionarParametroNaUrl(chave, valor) {
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);

        // Adiciona ou atualiza o parâmetro
        parametros.set(chave, valor);

        // Modifica a URL sem recarregar a página
        window.history.pushState({}, '', `${window.location.pathname}?${parametros}`);
    }
    function recuperarParametroDaUrl(chave) {
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);

        // Retorna o valor do parâmetro
        return parametros.get(chave);
    }
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        setCarregandoControleDiario(true);
        fetchControleDiario(dataMoment, setControleDiarioId, setTemDiaCarregado, setCarregandoControleDiario);
        setTestandoDia(false);
    }, [dataMoment]);
    useEffect(() => {
        console.log('controleDiario', controleDiario);
    }, [controleDiario]);
    useEffect(() => { //ON LOAD
        setTestandoDia(true);
        const permissoesDaPagina = coletaPermissoesPagina();
        setPermissoesPagina(permissoesDaPagina);
        const newPermissoes = coletaPermissoesFull();
        setPermissoes(newPermissoes);
        const urlDataRecuperada = recuperarParametroDaUrl('data');
        const data = urlDataRecuperada ? urlDataRecuperada : dataHoje;

        if (user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR') {
            adicionarParametroNaUrl('data', data);
        }

        setUrlData(data);
    }, []);
    //#endregion

    //#region HTML
    return (
        <>
            <div className='container'>
                <div className='w-100per d-flex flex-col'>
                    <div className='col-12 d-flex text-right justify-content-center align-center mt-2'>
                        <span className='texto-data-controle-diario me-2 ml-2'>Data: <b>{dataFormatada}</b></span>
                    </div>
                </div>
                <Card className='mx-auto'>
                    <div className='div-titulo-18'>
                        <label>Ações de Controle Diário</label>
                    </div>
                    <div className='d-flex align-items-center justify-content-between px-4 py-4 flex-wrap w-100per-i h-content-i'>
                        {(permissoes['criacao_edicao'] && temDiaCarregado === true)
                            ? <></>
                            : !testandoDia && !(controleDiario?.id) &&
                            <button className='btn-padrao w-200px h-38px m-2'
                                style={dataHoje === dataFormatadaTeste
                                    ? { backgroundColor: '#40C5ED' }
                                    : controleDiario?.salvo === null ? aparenciaBotaoAcao['nao_salvo'] : aparenciaBotaoAcao['salvo']}
                                onClick={() => { if (dataHoje === dataFormatadaTeste) handleCarregarControleDiario() }}>
                                {dataHoje === dataFormatadaTeste ? 'Carregar' : 'Não carregado'}
                            </button>
                        }
                        {carregandoControleDiario
                            ? <div className='mx-auto d-flex flex-col'>
                                <ClipLoader color='#000' />
                                <span>Carregando Controle Diário...</span>
                            </div>
                            : temDiaCarregado ?
                                <>
                                    {permissoesPagina && permissoesPagina['novo_atendimento'] &&
                                        <button className='btn-padrao turquesa w-200px h-38px m-2' type='button' onClick={() => setIsModalAdicionarAtendimentoVisible(true)}>
                                            {React.cloneElement(getIcon('adicionar'), { style: { marginRight: '10px' } })} Novo Atendimento
                                        </button>
                                    }
                                    {permissoesPagina && permissoesPagina['criacao_edicao'] &&
                                        <button className='btn-padrao w-200px h-38px m-2'
                                            style={controleDiario?.salvo === null
                                                ? aparenciaBotaoAcao['nao_salvo']
                                                : (user?.tipo_user?.toUpperCase() === 'ADMINISTRADOR' || user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR')
                                                    ? aparenciaBotaoAcao['salvo']['habilitado']
                                                    : aparenciaBotaoAcao['salvo']['nao_habilitado']
                                            }
                                            onClick={() => salvarControleDiario(false, null, controleDiarioId, user.id)}>
                                            {controleDiario?.salvo === null
                                                ? <> {React.cloneElement(getIcon('salvar'), { style: { marginRight: '10px' } })} Salvar </>
                                                : 'Salvo'
                                            }
                                        </button>
                                    }
                                </>
                                : <div className="justify-center w-100per align-center text-center p-4 font-vermelho">
                                    Não existe controle diário carregado.
                                </div>
                        }
                    </div>
                </Card>
                {/* 
                <Card className='w-100per-i h-content-i'>
                    <div className='div-titulo-18'>
                        <label>Acessos</label>
                    </div>
                    <div className='d-flex align-items-center justify-content-between px-4 py-4 flex-wrap w-100per-i h-content-i'>
                        {permissoes
                            ? permissoes.map((permissao) => (
                                <button key={permissao.id} className='btn-acesso-rapido' onClick={() => navigate(`/dashboard/${user.route_tipo_user}${permissao.link}`)}>
                                    {cloneElement(getIcon(permissao.icon), { size: size })}
                                    <span>{permissao.title}</span>
                                </button>
                            ))
                            : <ClipLoader color='#000' />
                        }
                    </div>
                </Card>
                */}
            </div>
            {isModalAdicionarAtendimentoVisible
                && <AdicionarAgendamento
                    onClose={() => { setIsModalAdicionarAtendimentoVisible(false); }}
                    date={dataFormatadaTeste}
                    agendamentos={atendimentos}
                    updateAgenda={() => { }}
                    controleDiarioId={controleDiarioId}
                    atualizaSocket={() => { }}
                    incluirAtendimento={true}
                />
            }
        </>
    );
    //#endregion
};

export default DashboardControleDiario;