import React from 'react';

const TituloPagina = ({ children, className, description = '', rest }) => {
  return (
    <div className={`div-page-titulo${className ? ` ${className}` : ''}`} {...rest} title={description}>
      <label>
        {children}
      </label>
    </div>
  );
};

export default TituloPagina;
