import Button from 'components/Button';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import ReactModal from 'react-modal';

const ConferirConflitos = forwardRef(({ salvarControleDiario }, ref) => {

    const [isOpen, setIsOpen] = useState(false);
    const [atendimentos, setAtendimentos] = useState([]);
    //===VARS STYLES===\\
    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: '60vh',
            width: '80vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#F5F7FA'
        },
    };

    const openModal = useCallback((dados) => {
        setAtendimentos(dados);
        setIsOpen(true);
    });

    const closeModal = useCallback((salvar) => {
        setAtendimentos([]);
        if (salvar) {
            salvarControleDiario();
        }
        setIsOpen(false);
    }, []);

    const afterOpenModal = () => {

    };

    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    return (
        <ReactModal onRequestClose={(e) => closeModal()} afterOpenModal={afterOpenModal} style={customStyles} isOpen={isOpen} appElement={document.getElementById('root')} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} transparent>
            <div className='div-titulo d-flex justify-content-between align-center w-100 flex-wrap'>
                <p></p>
                <h2 className="font-vermelho">Conflitos a serem conferidos</h2>
                <CgClose className='icone-padrao' color='red' size={22} onClick={() => closeModal()}></CgClose>
            </div>
            <p>Existem conflitos a serem conferidos nesse Controle Diário. Confira, e aperte em Salvar Controle Diário, se esta tudo certo, ou aperte em Revisar, para voltar ao Controle Diário.</p>
            <table className="table table-hover border">
                <thead>
                    <tr>
                        <th>Hora</th>
                        <th>Terapeuta</th>
                        <th>Terapia/Especialidade</th>
                        <th>Paciente</th>
                        <th>Sala/Estação</th>
                        <th>Tipo/Modalidade</th>
                    </tr>
                </thead>
                <tbody>
                    {atendimentos.map((a) => {
                        return a.atendimentos.filter((filter) => filter.conflitos.horario_terapeuta || filter.conflitos.horario_paciente).map((b, index) => {
                            return (
                                <tr key={`${a.hora}-${b.terapeuta_nome}-${index}`}>
                                    {index === 0 && <th className="align-middle" rowSpan={a.atendimentos.length}>{a?.hora}</th>}
                                    <td className="align-middle">{b?.terapeuta_nome}</td>
                                    <td className="align-middle align-center">{b?.terapia} <br></br> {b?.especialidade}</td>
                                    <td className="align-middle">{b?.paciente_identificador}</td>
                                    <td className="align-middle align-center">{b?.sala} <br></br> {b?.estacao}</td>
                                    <td className="align-middle align-center">{b?.tipo_atendimento} <br></br> {b?.modalidade}</td>
                                </tr>
                            );

                        });
                    })}

                </tbody>
            </table>
            <div className="w-100 d-flex justify-between">
                <Button className="w-300px" style={{ backgroundColor: '#bb2d3b' }} onClick={() => closeModal(false)}>Revisar</Button>
                <Button className="w-300px" onClick={() => closeModal(true)}>Salvar Controle Diário</Button>
            </div>
        </ReactModal>
    );
});
export default ConferirConflitos;