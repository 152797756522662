import React from 'react';
import Tabela from './Tabela';

const Lista = ({ array, setLista }) => {
    const handleClickEditar = () => {
        
    };
    const handleClickExcluir = (indice) => {
        const listaLocal = [...array];
        listaLocal.splice(indice, 1);
        setLista(listaLocal);
    };
    return (
        <Tabela array={array} handleClickEditar={handleClickEditar} handleClickExcluir={handleClickExcluir}></Tabela>
    );
};

export default Lista;