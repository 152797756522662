import styled from 'styled-components';

export const CardStyle = styled.div`
    background-color: #FFFFFF;
    padding: 10px 15px 15px 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0px silver;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: #D4D6D8 1px solid;
    min-height: 300px;
    height: fit-content;
`;

export const LinhaPadrao = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`;

export const LinhaSpaceBetween = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
`;

export const TituloTabelas = styled.h2`
    font-size: 24px;
    color: #6A3BAF;
`;