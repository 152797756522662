import React, { useEffect, useRef } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';

const Select = ({ name, defaultValue, containerStyle, containerClass = '', maxHeight = '150px', label = false, title = null, readOnly, required, noStyles = false, ...rest }) => {
    const selectRef = useRef(null);
    const { fieldName, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref) => {
                if (rest.isMulti) {
                    if (!ref.state.value) {
                        return [];
                    }
                    return ref.state.value.map((option) => option.value);
                }
                if (!ref.state.value) {
                    return '';
                }
                return ref.state.value.value;
            },
        });
    }, [fieldName, registerField, rest.isMulti]);

    const customStyles = noStyles ? {} : {
        control: (provided, state) => ({
            ...provided,
            border: '2px solid #ddd',
            borderRadius: '8px',
            height: '40px',
            margin: 0,
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 10,
        }),
        menuList: (provided, state) => ({
            ...provided,
            borderRadius: '8px',
            color: 'black',
            overflowY: 'auto',
            maxHeight: maxHeight
        }),
    };

    return (
        <div className={containerClass} style={noStyles ? {} : { width: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }} name='react_select__div'>
            {(label) || (title)
                ?
                <label style={{ marginTop: 10, fontSize: 13, }}><b style={{ color: 'orange' }}>{required ? '*' : ''}</b>{label || title}</label>
                :
                ''
            }
            <ReactSelect
                defaultValue={defaultValue}
                ref={selectRef}
                className='position-relative'
                classNamePrefix='react-select'
                style={{ borderWidth: '2px', }}
                required={required}
                styles={customStyles}
                autoComplete={`new-${fieldName}`}
                isDisabled={readOnly}
                {...rest}
            />
        </div>
    );
};
const SelectPlusButton = ({ name, defaultValue, containerStyle, containerClass = '', maxHeight = '150px', label = false, readOnly, button, ...rest }) => {
    const selectRef = useRef(null);
    const { fieldName, registerField } = useField(name);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: '2px solid #ddd',
            borderRadius: '8px',
            height: '40px',
            margin: 0,
            display: 'flex',
            alignItems: 'center',
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '40px',
            padding: '0 6px',
            display: 'flex',
            alignItems: 'center',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '40px',
            display: 'flex',
            alignItems: 'center',
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '16px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '16px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
        }),
        menuList: (provided) => ({
            ...provided,
            borderRadius: '8px',
            color: 'black',
            overflowY: 'auto',
            maxHeight: maxHeight,
        }),
    };

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref) => {
                if (rest.isMulti) {
                    if (!ref.state.value) {
                        return [];
                    }
                    return ref.state.value.map((option) => option.value);
                }
                if (!ref.state.value) {
                    return '';
                }
                return ref.state.value.value;
            },
        });
    }, [fieldName, registerField, rest.isMulti]);

    return (
        <div className={containerClass} style={{ width: '100%', display: 'flex', flexDirection: 'column' }} name='react_select__div'>
            {label === false
                ? ''
                : <label style={{ fontWeight: 'bold', marginTop: 10, fontSize: 13, }}>{label}</label>
            }
            <div className='input-group' style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                <ReactSelect
                    defaultValue={defaultValue}
                    ref={selectRef}
                    className='position-relative flex-1-1-auto w-1per min-w-0'
                    classNamePrefix='react-select'
                    styles={{
                        control: (provided, state) => ({
                            ...provided,
                            border: '2px solid #ddd',
                            borderRadius: '8px',
                            height: '40px',
                            borderTopRightRadius: '0px',
                            borderBottomRightRadius: '0px',
                            borderRight: 'none',
                            margin: 0,
                        }),
                        menuList: (provided, state) => ({
                            ...provided,
                            borderRadius: '8px',
                            color: 'black', overflowY: 'auto', maxHeight: maxHeight
                        }),
                    }}
                    autoComplete={`new-${fieldName}`}
                    isDisabled={readOnly}
                    {...rest}
                />
                <div className='input-group-text' style={{ margin: '0px', padding: '0px ', height: '40px', borderLeft: 'none' }}>
                    {button}
                </div>
            </div>
        </div>
    );
};
const SimpleSelect = ({ name, options, emptyOption = true, ...rest }) => {
    return (
        <select {...rest}>
            {emptyOption && <option key={0} value={null}></option>}
            {options.map(option => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};
export default Select;
export { SimpleSelect, SelectPlusButton };