import React, { useEffect, useState } from 'react';
import { Container, ContainerProntuarios } from './styles';
import PacienteService from 'services/PacienteService';
import ProntuarioCard from './ProntuarioCard';
import { useParams } from 'react-router-dom';
import { usePageBase } from 'context/page-base';

function Prontuarios() {
    const pageBase = usePageBase('Prontuários dos Pacientes', 'Listagem de todos os prontuários dos pacientes cadastrados no sistema.', true, true);
    //#region VARIÁVEIS==============*/
    /*===============================*/
    const [pacientesOriginal, setPacientesOriginal] = useState([]);
    const [pacientesFiltrados, setPacientesFiltrados] = useState([]);
    const [filtro, setFiltro] = useState(null);
    const { id } = useParams();     /* Parametros da URL */
    //#endregion
    /*===============================*/
    //#region USE EFFECTs============*/
    /*===============================*/
    useEffect(() => {
        let filtro;
        PacienteService.index('with=terapia_pacientes.especialidade,terapia_pacientes.terapium')
            .then(({ data }) => {
                let dadosPaciente = [];
                data.forEach((paciente) => {
                    dadosPaciente.push({
                        id_paciente: paciente.id,
                        nome: paciente.nome,
                        nascimento: paciente.nascimento,
                        documentos: paciente.documentos,
                        identificador: paciente.identificador,
                        plano_saude: paciente.plano_saude.plano,
                        responsavel: paciente.responsavel.nome,
                        status: paciente.status,
                    });
                    if (id && Number(paciente.id) === Number(id)) {
                        filtro = paciente.nome;
                    }
                });
                setPacientesOriginal(data);
                if (!id) {
                    setPacientesFiltrados(dadosPaciente);
                }
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                if (filtro) {
                    setFiltro(filtro);
                }
            });
    }, []);
    useEffect(() => {
        handleChangeFiltroPacienteNome(filtro);
    }, [filtro]);
    //#endregion
    /*===============================*/
    //#region HANDLEs================*/
    /*===============================*/
    let timer;
    const handleChangeFiltroPacienteNome = (filtro) => {
        timer = setTimeout(() => {
            filtraPacientePorNome(filtro);
        }, 500);
    };
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const filtraPacientePorNome = (filtro) => {
        if (filtro.length < 1) {
            setPacientesFiltrados(pacientesOriginal);
            return;
        }
        const pacientes = pacientesOriginal.filter(paciente => paciente.nome.toUpperCase().indexOf(filtro.toUpperCase()) >= 0);
        setPacientesFiltrados(pacientes);
    };
    //#endregion
    /*===============================*/
    //#region HTML===================*/
    /*===============================*/
    return (
        <Container>
            <div className='div-titulo'>
                <label>Filtro</label>
            </div>
            <div className='d-flex pb-40px justify-center align-content-center mt-10px mb-20px'>
                <input className='form-control' placeholder={'Pesquisar Paciente'} value={filtro} onChange={e => setFiltro(e.target.value)} />
            </div>
            {pacientesFiltrados.length < 1 ?
                <div className='text-center'>
                    Não foram encontrados pacientes.
                </div>
                :
                <ContainerProntuarios>
                    {pacientesFiltrados.map(paciente => <ProntuarioCard paciente={paciente} />)}
                </ContainerProntuarios>
            }
        </Container>
    );
    //#endregion
}

export default Prontuarios;