//#region IMPORTAÇÕES
import { usePageBase } from 'context/page-base';
import moment from 'moment';
import ControleDiario from 'pages/RecepcaoDashboard/Agenda/ControleDiario';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
//#endregion

const AtendimentosPendentes = () => {
    usePageBase({
        title: 'Atendimentos Pendentes',
        description: 'Visualize os atendimentos pendentes para o dia selecionado.',
        hasTitle: true,
        hasSubmenu: true,
        routes: [
            'dashboard/{route_tipo_user}/controlediario/atendimentos-pendentes',
        ]
    });
    //#region VARIAVEIS
    const { data } = useParams();
    const [dataMoment, setDataMoment] = useState(moment(data));
    //#endregion
    return (
        <div className='m-0 p-0' style={{ padding: '0 0 0 50' }}>
            <div className='container'>
                <div className='w-100per d-flex flex-col'>
                    <div className='col-12 d-flex text-right justify-content-center align-center mt-2'>
                        <span className='texto-data-controle-diario me-2 ml-2'>Data: <b>{dataMoment.format('dddd - DD/MM/YYYY')}</b></span>
                    </div>
                </div>
            </div>
            <ControleDiario
                modoIncorporado={true}
                modoDeConsulta='status-pendentes'
                funcoesHabilitadas={{
                    filtro: true,
                    navegacao: false,
                    salvar: false,
                    carregar: false,
                    novo_atendimento: false,
                    solicitacoes_alteracao: false,
                    filtro_solicitacoes_alteracao: false,
                    atualizacao: true,
                    baixar_excel: false,
                    legenda: true,
                    botao_alteracao: true,
                    controle_de_execucao: false,
                    altera_sugestao_recepcao: true,
                    altera_liberado: false,
                    coluna_p: true,
                    paciente_filtro_inline: true,
                }}
            />
        </div>
    );
};

export default AtendimentosPendentes;