import { useState } from 'react';
import api from 'services/api';

const useTipoColuna = () => {
    const [tiposColunaOptions, setTiposColunaOptions] = useState([]);

    const processaTipoColuna = (tipo) => {
        const lowerCaseTipo = String(tipo).toLowerCase();
        switch (lowerCaseTipo) {
            case 'texto':
            case 'string':
                return 'text';
            case 'numero':
            case 'number':
                return 'number';
            case 'data':
            case 'date':
                return 'date';
            default:
                return 'text';
        }
    }

    const fetchTipoColuna = async () => {
        try {
            const { data } = await api.get('api/colunas/tipo-coluna/list');
            const options = data;
            setTiposColunaOptions(options);
        } catch (error) {
            console.error('Erro ao carregar modalidades:', error);
        }
    };

    return { tiposColunaOptions, fetchTipoColuna, processaTipoColuna };
};

export default useTipoColuna;