//#region IMPORTAÇÕES
import { Form } from '@unform/web';
import Button from 'components/Button';
import Calendarario from 'components/Calendario';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import useIcons from 'context/icons';
import { usePageBase } from 'context/page-base';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineClose, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import AtendimentoService from 'services/AtendimentoService';
import Swal from 'sweetalert2';
//#endregion

const ConsultarAlteracoes = () => {
    usePageBase({
        title: 'Consultar Alterações',
        description: 'Visualize os atendimentos pendentes para o dia selecionado.',
        hasTitle: true,
        hasSubmenu: true,
        routes: [
            'dashboard/{route_tipo_user}/controlediario/consultar-alteracoes',
        ]
    });
    //#region VARIAVEIS
    // Carregando
    const [loading, setloading] = useState(false);

    // Hooks
    const { iconArray } = useIcons();

    // Data
    const { data } = useParams();
    const [dataMoment, setDataMoment] = useState(moment.utc());
    const dataFormatada = useMemo(() => dataMoment.format('YYYY-MM-DD'), [dataMoment]);
    const dataNome = useMemo(() => dataMoment.format('dddd - DD/MM/YYYY'), [dataMoment]);

    // Refereências
    const calendarioRef = useRef(null);

    // Controle Diário
    const [controleDiarioId, setControleDiarioId] = useState(null);

    // Tabela
    const [atendimentos, setAtendimentos] = useState([]);
    const columns = useMemo(() => [
        {
            Header: 'Nº',
            accessor: 'id',
        },
        {
            Header: 'Tipo',
            filterable: true,
            accessor: 'tipo_solicitacao',
        },
        {
            Header: 'Solicitante',
            filterable: true,
            accessor: 'usuario_solicitante_nome',
            Cell: (cell) => <span>{moment(cell.row.original.horario_solicitacao).isValid() ? moment(cell.row.original.horario_solicitacao).format('DD/MM/YYYY') : '-'} - {cell.value}</span>,
        },
        {
            Header: 'Horario Or.',
            filterable: true,
            accessor: 'horario_original',
            Cell: (cell) => <span>{moment(cell.value).isValid() ? moment(cell.value).format('DD/MM/YYYY') : '-'}</span>,
        },
        {
            Header: 'Paciente Or.',
            filterable: true,
            accessor: 'paciente_original',
        },
        {
            Header: 'Terapeuta Or.',
            filterable: true,
            accessor: 'terapeuta_original',
        },
        {
            Header: 'Horario Novo',
            filterable: true,
            accessor: 'horario_novo',
            Cell: (cell) => <span>{moment(cell.value).isValid() ? moment(cell.value).format('DD/MM/YYYY') : '-'}</span>,
        },
        {
            Header: 'Paciente Novo',
            filterable: true,
            accessor: 'paciente_novo',
        },
        {
            Header: 'Terapeuta Novo',
            filterable: true,
            accessor: 'terapeuta_novo',
        },
        {
            Header: 'Alteração',
            filterable: true,
            accessor: 'alteracao',
        },
        {
            Header: 'Status',
            filterable: true,
            accessor: 'status_solicitacao',
        },
        {
            Header: 'Final',
            filterable: true,
            accessor: 'final',
        },
    ], []);

    // Calendário
    const [isCalendarioVisible, setIsCalendarioVisible] = useState(false);

    //#endregion
    //#region FUNCOES
    function adicionarParametroNaUrl(chave, valor) {
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);
        // Adiciona ou atualiza o parâmetro
        parametros.set(chave, valor);
        // Modifica a URL sem recarregar a página
        window.history.pushState({}, '', `${window.location.pathname}?${parametros}`);
    }
    const getControleDiarioId = async (data = null) => {
        let dataTeste = data === null ? dataFormatada : data;
        return api.get(`/api/controle-diario?filter[dia]=${dataTeste}&with=solicitacao_de_alteracaos,solicitacao_de_alteracaos.horarios_atendimento`)
            .then(response => {
                let controleDiario = response.data;
                if (controleDiario?.length < 1) {
                    Swal.fire({
                        title: 'Sem Controle Diário!',
                        text: 'Não foi possível encontrar o controle diário para a data selecionada.',
                        icon: 'error',
                    });
                    return false;
                }
                controleDiario = controleDiario[controleDiario?.length - 1];
                setControleDiarioId(controleDiario?.id);
                return controleDiario?.id;
            })
            .catch(e => {
                console.error(e);
                return false;
            });
    };
    //#endregion
    //#region HANDLES
    /**
     * Função para pesquisar e definir o modo de consulta baseado nos IDs do paciente e terapeuta selecionados.
     * 
     * Esta função cria um array de condições de consulta (`novoModoDeConsulta`) com base nos valores selecionados
     * de paciente e terapeuta. Se um paciente ou terapeuta estiver selecionado, suas respectivas condições são
     * adicionadas ao array. Em seguida, o array é definido como o novo modo de consulta.
     * 
     * @function handlePesquisar
     */
    const handlePesquisar = async () => {
        const controleDiarioId = await getControleDiarioId(dataFormatada);
        AtendimentoService.getAlteracoesByControleDiarioId(controleDiarioId).then(({ data }) => {
            setAtendimentos(data);
        });
    };
    const handleToggleCalendario = () => {
        setIsCalendarioVisible(!isCalendarioVisible);
    };
    const handleClickNextDay = () => {
        let dataSaveLocal = dataMoment.add(1, 'days');
        setDataMoment(dataSaveLocal);
        adicionarParametroNaUrl('data', dataSaveLocal.format('YYYY-MM-DD'));
    };
    const handleClickPrevDay = () => {
        let dataSaveLocal = dataMoment.subtract(1, 'days');
        setDataMoment(dataSaveLocal);
        adicionarParametroNaUrl('data', dataSaveLocal.format('YYYY-MM-DD'));
    };
    const handleClickDay = (day, month, year) => {
        let dia = moment.utc(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD');
        setDataMoment(moment.utc(dia));
        adicionarParametroNaUrl('data', dia.format('YYYY-MM-DD'));
        setIsCalendarioVisible(false);
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {// ON LOAD

    }, []);
    //#endregion
    //#region HTML
    return (
        <div className='m-0' style={{ padding: '0 0 0 50' }}>
            <div className='container'>
                <Form className='d-flex justify-content-between'>
                    <div className="w-200px">
                    </div>
                    <div className='w-content d-flex text-right justify-content-center align-center mt-2'>
                        <button className='btn-icone'>
                            <AiOutlineLeft className='pointer' color='black' size={22} onClick={() => handleClickPrevDay()} />
                        </button>
                        <span className='texto-navegacao me-2 ml-2' onClick={(e) => handleToggleCalendario()}>{`${dataNome.charAt(0).toUpperCase() + dataNome.slice(1)}`}</span>
                        <button className='btn-icone'>
                            {moment.utc().format('YYYY-MM-DD') !== dataFormatada ?
                                <AiOutlineRight className='pointer' color='black' size={22} onClick={() => handleClickNextDay()} />
                                : <AiOutlineRight color='white' size={22} />
                            }
                        </button>
                        <div ref={calendarioRef} className={isCalendarioVisible ? 'div-expand-over calendar-div p-0 w-fit-content-i bg-white' : 'some'}>
                            <div className='w-100 d-flex justify-content-end'>
                                <button className='btn-icone' onClick={(e) => handleToggleCalendario()}>
                                    <AiOutlineClose color='red' size={22} />
                                </button>
                            </div>

                            <Calendarario dateLimit={true} style={{ borderTop: 'none' }} daysMark={[]} handleClickDay={handleClickDay} maxDate={moment.utc().format('YYYY-MM-DD')} selectedDate={dataFormatada} />
                        </div>
                    </div>
                    <Button className="w-200px-i" type={'button'} onClick={() => handlePesquisar()}>
                        {iconArray['pesquisar']} Pesquisar
                    </Button>
                </Form>
            </div>
            <TableDefaultPagination
                source={atendimentos}
                columns={columns}
                searchBar={true}
                loading={loading}
            />
        </div>
    );
    //#endregion
};

export default ConsultarAlteracoes;