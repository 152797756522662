import React, { useState, useEffect } from 'react';
import { Container, ContainerSub, FormField } from './styles';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import api from '../../../services/api';
import moment from 'moment';
import { TableUltimosAtendimentos } from './TableRelatorios';
import { useAuth } from '../../../context/auth';
import { usePageBase } from 'context/page-base';

function TerapeutaRelatorios() {
    //#region VARIAVEIS
    const pageBase = usePageBase('Ultimos Atendimentos', 'Relatório dos ultimos atendimentos do terapeuta.', true, false);
    const [dropDown, setDropDown] = useState(false);
    const [relatorios, setRelatorios] = useState([]);
    const [filterDate, setFilterDate] = useState(moment().subtract(15, 'days').format('YYYY-MM-DD'));
    const [statusAtendimento, setStatusAtendimento] = useState([]);

    const { user } = useAuth();
    //#endregion

    //#region FUNCOES
    /**
     * Função para buscar o status de atendimento da API e atualizar o estado com a lista de status formatada.
     * A função faz uma requisição GET para o endpoint '/api/status-atendimento' e mapeia a resposta para um formato específico.
     * Cada status é transformado em um objeto com as propriedades 'value' e 'label'.
     * 
     * @function
     * @returns {void}
     */
    const getStatusAtendimento = () => {
        api.get('/api/status-atendimento').then(response => {
            let statusList = response.data;
            statusList = statusList.map(status => {
                return {
                    value: status.id,
                    label: status.status,
                };
            });
            setStatusAtendimento(statusList);
        });
    };
    /**
     * Função assíncrona para obter relatórios filtrados de atendimentos.
     * 
     * Esta função faz uma requisição POST para a API com filtros específicos,
     * ordena os dados recebidos pela data de criação e mapeia os dados para um formato específico.
     * 
     * @async
     * @function getRelatorios
     * @returns {Promise<void>} - Retorna uma Promise que resolve quando os relatórios são obtidos e processados.
     * @throws {Error} - Lança um erro caso ocorra algum problema na requisição ou processamento dos dados.
     */
    const getRelatorios = async () => {
        try {
            const filters = {
                data_inicio: filterDate,
                data_fim: moment().format('YYYY-MM-DD'),
                terapeuta_id: user.terapeuta[0].id,
            };
            const { data } = await api.post('/api/atendimento/relatorio/filtrado', filters);
            data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setRelatorios(data.map((atendimento) => ({
                id_paciente: atendimento.paciente.id,
                data_atendimento: moment(atendimento.data_atendimento_inicial).format('DD/MM/YYYY') + ' - ' + moment(atendimento.data_atendimento_final.split('T')[1], 'HH:mm:ss').format('HH:mm'),
                estacao: atendimento.estacao.estacao,
                sala: atendimento.sala,
                nome: atendimento.paciente.nome,
                identificador: atendimento.paciente.identificador,
                plano_saude: atendimento.nome_reduzido,
                terapia: atendimento.terapia.terapia,
                especialidade: atendimento.especialidade.especialidade,
                status: atendimento.status_atendimento ? atendimento.status_atendimento.status : '',
                evolucao_paciente_id: atendimento.evolucao_paciente_id,
                status_evolucao: atendimento.status_evolucao,
            })));
        } catch (error) {
            console.error('Erro ao obter lista de pacientes - ', error);
        }
    };
    //#endregion

    //#region HANDLES
    function handleFilterChange(days) {
        setFilterDate(moment().subtract(days, 'days').format('YYYY-MM-DD'));
    }
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        getRelatorios();
    }, [filterDate]);

    useEffect(() => {
        getStatusAtendimento();
    }, []);
    //#endregion

    //#region HTML
    return (
        <>
            <React.Fragment>
                <Container>
                    <ContainerSub>
                        <FormField>
                            <div className='d-flex flex-column align-items-center justify-content-center w-100'>
                                <span style={{ color: '#4906AB', fontWeight: '600' }}>Filtro</span>
                                <div onClick={() => dropDown ? setDropDown(false) : setDropDown(true)}
                                    style={{
                                        fontSize: '15', backgroundColor: '#6A3BAF', color: '#FFFFFF',
                                        border: 'none', borderRadius: 8, padding: '3px 30px'
                                    }}><span className='ms-1' style={{ cursor: 'pointer' }}>Selecionar {dropDown ? <IoIosArrowDown className='ms-3' /> : <IoIosArrowUp className='ms-3' />}</span>
                                    {dropDown ?
                                        <div className='d-flex flex-column align-items-center justify-content-center'>
                                            <div onClick={() => handleFilterChange(15)} style={{ cursor: 'pointer' }} className='text-decoration-underline mb-1'><span>Últimos 15 dias</span></div>
                                            <div onClick={() => handleFilterChange(30)} style={{ cursor: 'pointer' }} className='text-decoration-underline mb-1'><span>Últimos 30 dias</span></div>
                                            <div onClick={() => handleFilterChange(60)} style={{ cursor: 'pointer' }} className='text-decoration-underline mb-1'><span>Últimos 60 dias</span></div>
                                            <div onClick={() => handleFilterChange(90)} style={{ cursor: 'pointer' }} className='text-decoration-underline mb-1'><span>Últimos 90 dias</span></div>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                            <div style={{ maxWidth: '100vw', marginBottom: 50, overflow: 'auto' }}>
                                <TableUltimosAtendimentos source={relatorios} statusAtendimento={statusAtendimento ? statusAtendimento : null} />
                            </div>
                        </FormField>
                    </ContainerSub>
                </Container>
            </React.Fragment>
        </>
    );
    //#endregion
}

export default TerapeutaRelatorios;