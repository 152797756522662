import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form } from '@unform/web';
import Button from 'components/Button';
import { useSnackbar } from 'context/snackbar';
import api from 'services/api';
import Modal from 'react-modal';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';
import { unstable_batchedUpdates } from 'react-dom';
import { ChangeCase } from 'services/ChangeCase';
import useTipoColuna from 'context/tipo-coluna';

const PerfilLinha = ({ linhaId, setLinhaId, tabela, onClose = () => { } }) => {
    //#region VARIAVEIS
    // HOOKS
    const snackbar = useSnackbar();
    const { processaTipoColuna } = useTipoColuna();
    // DADOS DA TABELA
    const [camposGerados, setCamposGerados] = useState([]);
    const [camposInseridos, setCamposInseridos] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [linha, setLinha] = useState({});
    const [loading, setLoading] = useState(true);
    // ESTILOS
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: 'auto',
            padding: '0px',
        },
    };
    //#endregion

    //#region FUNCOES
    const fetchLinha = async () => {
        setLoading(true);
        api.get(`/api/linhas/${linhaId}?with=conteudo_linhas,conteudo_linhas.colunas`)
            .then(response => {
                setLinha(response.data);
                handleOpenModal();
            })
            .catch(error => {
                console.error('Erro ao carregar tabela:', error);
                snackbar.displayMessage('Erro ao carregar tabela.', 'error');
            })
            .finally(() => setLoading(false));
    };
    //#endregion

    //#region HANDLES
    const handleSubmit = async () => {
        const conteudo_linhas = camposInseridos.map((campo, index) => ({
            coluna_id: campo.coluna_id,
            conteudo: campo.value
        }));
        const linha = {
            linha: {
                tabela_id: tabela.id,
            },
            conteudo_linhas: conteudo_linhas,
        };
        try {
            if (linhaId) {
                await api.put(`/api/linhas/${linhaId}`, linha);
                snackbar.displayMessage('Tabela atualizada com sucesso!', 'success');
            } else {
                await api.post('/api/linhas/', linha);
                snackbar.displayMessage('Tabela cadastrada com sucesso!', 'success');
            }
            handleCloseModal();
        } catch (error) {
            console.error('Erro ao salvar linha:', error);
            snackbar.displayMessage('Erro ao salvar linha.', 'error');
        }
    };
    const handleOpenModalNewTabela = () => {
        unstable_batchedUpdates(() => {
            setLinhaId(null);
            setModalIsOpen(true);
        });
    };
    const handleOpenModal = () => {
        setModalIsOpen(true);
    };
    const handleCloseModal = () => {
        onClose();
        setLinhaId(null);
        setModalIsOpen(false);
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        if (tabela) {
            if (linhaId) {
                fetchLinha();
            } else {
                if (tabela.colunas) {
                    const campos = tabela.colunas.map(coluna => ({
                        coluna_id: coluna.id,
                        nome: coluna.nome,
                        tipo: coluna.tipo,
                        value: ''
                    }));
                    setCamposGerados(campos);
                }
            }
        }
    }, [tabela, linhaId]);
    //#endregion
    //#region HTML
    return (
        <>
            <Button className="btn btn-primary" onClick={handleOpenModalNewTabela}>
                Cadastrar Nova Tarefa
            </Button>
            <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="" style={customStyles}>
                <nav className='navbar sticky-top bg-cinza-claro shadow m-0'>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div className='w-200px'> </div>
                        <ModalTitulo>{linhaId ? 'Editar' : 'Criar'} Linha</ModalTitulo>
                        <label className='btn-outline w-200px text-right' onClick={() => handleCloseModal()}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                <Form className='w-100per px-4' onSubmit={handleSubmit}>
                    {camposGerados && camposGerados.map((campo, index) => (
                        <div key={index} className="form-group">
                            <label htmlFor={ChangeCase.toSnakeCase(campo.nome)}>{campo.nome}</label>
                            <input
                                id={ChangeCase.toSnakeCase(campo.nome)}
                                name={campo.nome}
                                type={processaTipoColuna(campo.tipo)}
                                className="form-control"
                                onChange={(e) => {
                                    const coluna = campo.coluna_id;
                                    setCamposInseridos(prev => {
                                        const existingCampoIndex = prev.findIndex(campo => campo.coluna_id === coluna);
                                        if (existingCampoIndex !== -1) {
                                            const updatedCampos = [...prev];
                                            updatedCampos[existingCampoIndex].value = e.target.value;
                                            return updatedCampos;
                                        } else {
                                            return [
                                                ...prev,
                                                {
                                                    value: e.target.value,
                                                    coluna_id: coluna
                                                }
                                            ];
                                        }
                                    });
                                }}
                            />
                        </div>
                    ))}
                    <Button type="submit">
                        {linhaId ? 'Atualizar Tabela' : 'Cadastrar Tabela'}
                    </Button>
                </Form>
            </Modal>
        </>
    );
    //#endregion
};

export default PerfilLinha;
